import React from "react"
import "../Blacklists/main.css"
import ConfigList from "../Config/ConfigList";
import BiddingConfigModal from "./BiddingConfigModal";


function Bidding() {
  return <>
    <ConfigList
      configType="bidding"
      creationMode="clone"
      enableActivation={true}
      editComponent={BiddingConfigModal}
      caption="Bidding"
      defaultConfig={{bidding_inputs: {}, bidding_nodes: {}, bidding_schema: {type: "stack", children: []}}}
    />
  </>
}

export default Bidding;
