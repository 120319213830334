import {withStyles} from '@material-ui/core/styles';
import React from "react";
import {PrimaryButtonLarge, Card, ModalPaper} from "../../common/form_styles";
import {Button, Grid, Typography} from "@material-ui/core";
import {RemoveCircle} from "@material-ui/icons";
import styled from "styled-components";

export const ButtonContainer = styled.div`
  float: right;
  display: inline-block;
  padding: 20px;
`;

export const FullHeightGrid = withStyles(theme => ({
  root: {
    height: "100%",
    flexWrap: "nowrap",
  }
}))(Grid);

export const FullHeightFlexibleBlock = withStyles(theme => ({
  root: {
    flexWrap: "nowrap",
    '@media (max-width: 990px)': {
      flexDirection: "column",
      height: "fit-content",
    },
    '@media (min-width: 990px)': {
      flexDirection: "row"
    },
  }
}))(FullHeightGrid);

export const ContentGrid = withStyles(theme => ({
  root: {
    margin: 10,
    padding: 10,
    minWidth: 400,
    overflow: "scroll",
    flex: '2 2 15%',
    '@media (max-width: 990px)': {
      height: "fit-content",
      flex: '2 2 20%'
    },
  }
}))(FullHeightGrid);

export const ContentGridWithSeparator = withStyles(theme => ({
  root: {
    '@media (min-width: 990px)': {
      backgroundImage: 'linear-gradient(gray 66%, rgba(255, 255, 255, 0) 0%)',
      backgroundSize: '1px 20px',
      backgroundPosition: 'left',
      backgroundRepeat: 'repeat-y',
    },
    '@media (max-width: 990px)': {
      height: "fit-content"
    },
    padding: 20,
    overflow: "scroll",
    minWidth: 400,
    alignItems: 'center',
    display: 'flex',
    flexDirection: "column",
    flex: '4 6 40%',
    paddingLeft: 20
  }
}))(ContentGrid);

export const GrayCard = withStyles(theme => ({
  root: {
    backgroundColor: '#f3f3f3',
    padding: 20,
    borderColor: '#929292',
    borderStyle: 'solid',
    borderWidth: 1,
    minWidth: 300,
    textAlign: 'center',
    paddingBottom: 5
  }
}))(Card);

export const CardHeader = withStyles(theme => ({
  root: {
    fontSize: 14,
    padding: 0,
    margin: '-12px 0 5px -12px',
    textAlign: 'left'
  }
}))(Typography);

export const NodeCard = withStyles(theme => ({
  root: {
    backgroundColor: '#ffffff',
    padding: '10px 20px',
    margin: 5,
    cursor: 'grab',
    textAlign: 'center',
    marginBottom: 0,
    borderColor: '#929292',
    borderStyle: 'solid',
    borderWidth: 1,
  }
}))(Card);

export const NodeCardText = withStyles(theme => ({
  root: {
    color: '#117F9B',
    fontWeight: 'bold'
  }
}))(Typography);

export const RemoveControl = withStyles(theme => ({
  root: {
    right: -7,
    top: -5,
    fontSize: 'medium',
    position: 'absolute',
    color: '#b3b2b2',
    borderRadius: 20,
    backgroundColor: 'white',
    cursor: 'pointer',
    '&:hover': {
      color: '#bf7171',
    }
  },
}))(RemoveCircle);

export const AddButtonSmall = styled(PrimaryButtonLarge)`
  width: fit-content;
  padding-right: 10px;
  margin: 10px;
`;

export const ButtonsBox = styled.div`
  flex: 1 1 10%;
  padding-top: 20px;
  @media (max-width: 990px) {
    text-align: center;
  }
`;
