import React, {useState} from "react";
import {Modal, Grid, Card as MuiDefaultCard} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {useMutation} from "@apollo/client";
import {ADD_BLACKLIST_CONTENTS} from "../../graphql/queries/blacklist";
import {CardContent, CardHeader, NoButton, YesButton} from "../common/warning-modal";
import {withStyles} from "@material-ui/core/styles";
import {CampaignFormErrorText} from "../common/form_styles";

export const DefaultCard = withStyles(theme => ({
  root: {
    width: 722,
    paddingTop: 20,
  }
}))(MuiDefaultCard);

function AddBlacklistContentsModal({blacklist, showModal, onClose, refetch}){
  const [creationReason, setCreationReason] = useState("Manually created")
  const [abTestTier, setAbTestTier] = useState(null)
  const [item, setItem] = useState(new Map())
  const [error, setError] = useState(false)
  const [addBlacklistContents, addBlacklistContentsResponse] = useMutation(ADD_BLACKLIST_CONTENTS);

  const onCloseFunction = event => {
    onClose()
    setError(false)
    setItem(new Map())
    setAbTestTier(null)
  }

  function isNumeric(str) {
    if (typeof str != "string") return false
    return !isNaN(str) && !isNaN(parseInt(str))
  }

  const submitHandler = event => {
    let item_to_add = Object.fromEntries(item)
    if ('lead_provider' in item_to_add) {
      if (!isNumeric(item_to_add['lead_provider'])) {
        setError("Type of lead_provider must be number")
        return
      }
      item_to_add['lead_provider'] = parseInt(item_to_add['lead_provider'])
    }
    addBlacklistContents({
      variables: {
        blacklistId: parseInt(blacklist.id),
        item: JSON.stringify(item_to_add),
        reason: creationReason,
        abTestTier: abTestTier
      }
    }).then(r => {
      if (r.data.addBlacklistContents.ok === false)
        setError(r.data.addBlacklistContents.info)
      else {
        refetch()
        setError(false)
        onCloseFunction()
      }
    })
  }
  return (
    <>
      <Modal
        open={showModal}
        onEscapeKeyDown={onCloseFunction}
        onClose={onCloseFunction}
      >
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh' }}
          >
          <Grid item xs={6} md={3} style={{ maxWidth: '722px' }}>
            <DefaultCard>
              {
                error &&
                <CampaignFormErrorText>
                  {error}
                </CampaignFormErrorText>
              }
              <CardHeader
                title="Adding new item to blacklist_contents"
              />
              <CardContent>
                {
                  Object.keys(blacklist.config.field_to_variable_mapping).map(
                    (field) => (
                      <>
                        <label>{field}</label>
                        <TextField
                          value={item.get(field)}
                          onChange={(e) => {setItem(new Map(item.set(field, e.target.value)))}}
                        />
                      </>
                    )
                  )

                }<label>AB test tier</label>
                <TextField
                  value={abTestTier}
                  onChange={(event) => setAbTestTier(event.target.value)}
                />
                <label>Creation reason</label>
                <TextField
                  value={creationReason}
                  onChange={(event) => setCreationReason(event.target.value)}
                />
                <Grid container alignItems="center" justify="center" style={{ margin: '10px'}}>
                  <Grid item>
                    <NoButton onClick={onCloseFunction} />
                    <YesButton variant="contained" color="primary" onClick={submitHandler} />
                  </Grid>
                </Grid>
              </CardContent>
            </DefaultCard>
          </Grid>
        </Grid>
      </Modal>
    </>
  )
}

export default AddBlacklistContentsModal;