import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { Modal } from '@material-ui/core';
import {useMutation, useQuery} from '@apollo/client';
import CancelConfirmationModal from '../common/warning-modal';
import {GET_STATES, VALIDATE_CONFIG} from '../../graphql/queries/config';
import { CancelIcon, ModalPaper } from '../common/form_styles';
import ParametersForm from './ParametersForm';

function EditParameters(
  { baseConfig, initialConfig, additionalInfo, onClose, open, onSubmit, isFetching, actionCaption, validationErrors }
) {
  const [showCancelConfirmationModal, setShowCancelConfirmationModal] = useState(false);
  const [config, setConfig] = useState(initialConfig);
  useEffect(() => {
    setConfig(initialConfig);
  }, [initialConfig]);

  const [validateConfig, validationResponse] = useMutation(VALIDATE_CONFIG);
  const { loading, error, data} = useQuery(GET_STATES)

  if (loading) {
    return <>Loading</>
  }

  if (error) {
    console.log(error)
    return <>Can't load states, error</>
  }
  const states = JSON.parse(data.states)
  const isValidating = validationResponse.loading;
  const errors = (validationResponse.data && JSON.parse(validationResponse.data.validateConfig.errors)) || {};

  Object.keys(errors).forEach(
    (path) => {
      let field = path.split('.')[0];
      validationErrors[field] = validationErrors[field] || [];
      validationErrors[field] = validationErrors[field].concat(errors[path]);
    }
  )

  if (!config) return <>No config supplied</>;

  return (
    <>
      <CancelConfirmationModal
        headerText="Are you sure you want to cancel?"
        contentText="All progress will be lost"
        showModal={showCancelConfirmationModal}
        hideModalHandler={() => setShowCancelConfirmationModal(false)}
        cancelHandler={() => setShowCancelConfirmationModal(false)}
        submitHandler={() => {
          setShowCancelConfirmationModal(false);
          setConfig(cloneDeep(baseConfig));
          onClose();
        }}
      />

      <Modal
        open={open}
        onClose={(isEdited) => {
          if (isEdited) {
            setShowCancelConfirmationModal(true);
          } else {
            setConfig(cloneDeep(baseConfig))
            onClose();
          }
        }}
      >
        <ModalPaper style={{ height: '100%', overflow: 'scroll' }}>
          <CancelIcon onClick={() => {
            setShowCancelConfirmationModal(true);
          }}
          />
          {
            config && (
              <ParametersForm
                onSubmit={onSubmit}
                parameters={config}
                isFetching={isFetching}
                additionalInfo={additionalInfo}
                validationErrors={validationErrors}
                states={states}
              />
            )
          }
        </ModalPaper>
      </Modal>
    </>
  );
}

export default EditParameters;
