import React, { useState } from "react";
import { Paper } from "@material-ui/core";

import CampaignForm from "./CampaignForm";
import { CampaignFormErrorText } from "../common/form_styles";
import { useMutation } from "@apollo/client";
import {
  UPDATE_CAMPAIGNS,
} from "../../graphql/queries/campaigns";

function UpdateCampaignSection({
  campaignData, channels, tags, lead_providers, config_files, brands, refetch, closeUpdateSection
}) {
  const [error, setError] = useState(false)
  const [updateCampaigns] = useMutation(UPDATE_CAMPAIGNS);

  const submitHandler = (data) => {
    updateCampaigns({
      variables: {
        campaignsId: [parseInt(campaignData.id)],
        payload: JSON.stringify({
          [campaignData.id]: data
        }),
      },
    }).then(r => {
      if (r.data.updateCampaigns.ok === false)
        setError(r.data.updateCampaigns.info)
      else {
        refetch()
        setError(false)
        closeUpdateSection()
      }
    });
  };

  return (
    <>
      <Paper>
        {
          error &&
          <CampaignFormErrorText>
            {error}
          </CampaignFormErrorText>
        }
        <CampaignForm
          onSubmit={submitHandler}
          createMode={false}
          campaignData={campaignData}
          channels={channels}
          tags={tags}
          config_files={config_files}
          lead_providers={lead_providers}
          brands={brands}
        />
      </Paper>
    </>
  );
}

export default UpdateCampaignSection;
