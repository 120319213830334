import { withStyles } from '@material-ui/core/styles';
import {
  FormControl as MuiFormControl,
  TextField as MuiTextField,
} from '@material-ui/core';
import React from "react";
import CreatableSelect from "react-select/creatable";
import styled from "styled-components";

export const EditableCaption = withStyles(theme => ({
  root: {
    width: "fit-content",
    "& fieldset": {
      border: "none",
    },
    "& div.MuiInputBase-formControl": {
      fontSize: "0.875rem",
      fontWeight: "bold"
    },
    "& input.MuiInputBase-input": {
      padding: 0,
    }
  },
}))(MuiTextField);

export const TextField = withStyles(theme => ({
  root: {
    width: 100
  },
}))(MuiTextField);

export const FormControl = withStyles(theme => ({
  root: {
    minWidth: 200,
    marginRight: 20,
    '& input.MuiOutlinedInput-input': {
      paddingTop: 10,
      paddingBottom: 10,
      background: "white"
    },
    '& div.MuiInputBase-formControl': {
      background: "white"
    }
  },
}))(MuiFormControl);

export const ActionsBox = withStyles(theme => ({
  root: {
    marginLeft: 'auto',
    minWidth: 'fit-content',
    marginTop: 5,
    marginRight: 20
  },
}))(FormControl);

export const BlockWithLineOnLeft = ({ children }) => (
  <div style={{
    marginLeft: 20,
    // paddingLeft: 10,
    borderLeft: 'solid',
    borderLeftWidth: 1,
    borderColor: '#1976d2'
  }}>
    {children}
  </div>
);

export const DashSign = () => (
  <div style={{
    float: "left",
    color: '#1976d2',
    paddingTop: 8,
    verticalAlign: 'middle'
  }}>
    –
  </div>
);

export const OperatorBox = styled.div`
  margin-left: 8px;
  padding: 10px;
  background: #f3f3f3;
  border-color: #929292;
  border-style: solid;
  border-width: 1px;
  min-width: fit-content;
  display: flex;
`;
