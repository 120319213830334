import React from "react";
import "../Blacklists/main.css"
import BlacklistContentsModal from "./BlacklistContentsModal";
import AddBlacklistModal from "./AddBlacklistModal";
import ConfigList from "../Config/ConfigList";
import BlacklistForm from "./BlacklistForm";
import {UnControlled as CodeMirror} from "react-codemirror2";
import {JsonEditor as Editor} from "jsoneditor-react";
import TextField from "@material-ui/core/TextField";

export const defaultConfig = {
  name: "",
  field_to_variable_mapping: {"subid": {"var": "g_campaign.subid"}, "lead_provider":{"var":"g_campaign.lead_provider_id"}},
  creation_reason: 'item appeared in the blacklist query',
  mode: "add"
}


function Action({show, configInfo, onClose}) {
  if (configInfo)
    return <BlacklistContentsModal
      open={show}
      onClose={onClose}
      blacklist={configInfo}
    />
  return <></>
}

function ConfigView({config}) {
  return <>
    <p>Mapping of variables:</p>
    <div className="jsoneditor-small">
      <Editor
        value={config.field_to_variable_mapping}
        mode={'view'}
      />
    </div>
    <p>Creation reason:</p>
    <TextField
      fullWidth
      disabled
      value={config.creation_reason}
    />
    <p>Url:</p>
    <TextField
      fullWidth
      disabled
      value={config.url}
    />
    <p>Query:</p>
    <CodeMirror
      value={config.query}
      options={{
        mode: "text/x-pgsql",
        lineWrapping: true,
        smartIndent: true,
        lineNumbers: true,
        foldGutter: true,
        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
        autoCloseTags: true,
        keyMap: "sublime",
        matchBrackets: true,
        autoCloseBrackets: true,
        readOnly: true
      }}
    />
  </>
}

function Blacklists() {
  return <>
    <ConfigList
      configType="blacklist"
      creationMode="new"
      enableActivation={false}
      editComponent={AddBlacklistModal}
      createComponent={BlacklistForm}
      additionalActions={{"View Contents": Action}}
      configDisplayComponent={ConfigView}
      caption="Blacklists"
      defaultConfig={defaultConfig}
    />
  </>
}

export default Blacklists;

