import {TightBlock, WhiteCard} from "../Bidding/Nodes/component_styles";
import {CancelIcon} from "../common/form_styles";
import {TextField} from "@material-ui/core";
import CreatableMultiDropDown from "../FormControl/CreatableMultiDropDown";
import Switch from "@material-ui/core/Switch";
import React from "react";
import {actions, types} from "./ParametersForm";
import {JsonEditor as Editor} from "jsoneditor-react";
import ace from "brace";


function ParametersFormBody ({parameters, states, handleEditParameters}) {
  return (
    <>
      {
        Object.keys(parameters).map(
          (parameter) => {
            return (
              <WhiteCard className="parameter_card">
                <CancelIcon onClick={handleEditParameters(parameter, actions.DELETE_PARAMETER)}/>
                <p style={{fontWeight: "bold", fontSize: "30px"}}>
                  {parameter}
                </p>
                {
                  parameters[parameter] instanceof Object && !Array.isArray(parameters[parameter]) && (
                    <Editor
                      value={parameters[parameter]}
                      onChange={handleEditParameters(parameter, types.JSON)}
                      mode="code"
                      ace={ace}
                      theme="ace/theme/github"
                    />
                  )
                }
                {
                  Array.isArray(parameters[parameter]) && (
                    <CreatableMultiDropDown
                      values={parameters[parameter]}
                      onChange={handleEditParameters(parameter, types.LIST)}
                      options={states}
                    />
                  )
                }
                {
                  typeof parameters[parameter] === types.BOOLEAN && (
                    <Switch
                      checked={parameters[parameter]}
                      onChange={handleEditParameters(parameter, types.BOOLEAN)}
                      color="primary"
                    />
                  )
                }
                {
                  typeof parameters[parameter] === types.INT && (
                    <TextField
                      id={parameter}
                      value={parameters[parameter]}
                      onChange={handleEditParameters(parameter, types.INT)}
                      disabled={false}
                    />
                  )
                }
              </WhiteCard>
            )
          }
        )
      }
    </>
  )
}

export default ParametersFormBody;