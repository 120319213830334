import React from 'react';
import Chart from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';
import Button from '@material-ui/core/Button';
import { actions, OPTIONS_FOR_CHARTS } from './ChartOptions';
import { startFetch, fetchData } from './ChartFunctions';
import './Chart.css'
import 'chartjs-adapter-date-fns';

Chart.register(zoomPlugin);

export default class CustomChart extends React.PureComponent {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
  }

  componentDidMount() {
    this.createChart(this.props);
  }

  componentDidUpdate() {
    this.chart.destroy();
    this.createChart(this.props);
  }

  createChart(props) {
    this.chart = new Chart(this.chartRef.current, {
      type: props.type,
      data: {
        datasets: [
          {
            label: props.label,
            data: fetchData(
              props.startTime,
              props.endTime,
              props.dataset,
              props.granularity
            ),
            backgroundColor: props.lineColor,
            borderColor: props.lineColor,
            pointRadius: 2,
            fill: false,
          },
        ],
        line: {
          tension: 0.1,
        },
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: 'left',
          },
          zoom: {
            limits: {
              x: {
                min: 'original',
                max: 'original',
                minRange: OPTIONS_FOR_CHARTS[props.granularity]['zooming'],
              },
            },
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              drag: {
                enabled: true,
              },
              mode: 'x',
              onZoomComplete: startFetch,
            },
            pan: {
              enabled: true,
              mode: 'x',
              modifierKey: 'ctrl',
              onPanComplete: startFetch,
            },
          },
        },
        scales: {
          x: {
            min: props.startTime,
            max: props.endTime,
            type: 'time',
            ticks: {
              autoSkip: true,
              autoSkipPadding: 20,
            },
            time: {
              displayFormats: {
                second: 'HH:mm:ss',
                minute: 'MMM dd HH:mm',
                hour: 'MMM dd HH:mm',
                day: 'MMM dd yyyy HH:mm',
                month: 'MMM dd yyyy',
              },
            },
          },
          y: {
            suggestedMin: 0,
          },
        },
      },
    });
    this.chart.myValue = {
      dataset: props.dataset,
      granularity: props.granularity,
    };
  }

  render() {
    return (
      <>
        <canvas ref={this.chartRef} />
        {actions.map((action, i) => (
          <Button
            key={i}
            className="resetButton"
            variant="contained"
            onClick={() => {
              action.handler(this.chart);
            }}
          >
            {action.name}
          </Button>
        ))}
      </>
    );
  }
}
