import React from "react"

export default {
  R: { // this should be obsoleted 20190918
    outage: "/vendor_outages",
    lead_providers: "/lead_providers",
    bidding_configs: "/configs/bidding",
    configs: "/configs",
    bidding_parameters: "/configs/bidding/parameters",
    config_history: (id) => `/config_history/${id}`,
    validate_configs: "/configs/validate",
    active_configs: "/active_configs",
    blacklists: "/blacklists",
    graphql: "/internal",
  },
};
