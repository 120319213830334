export const actions = [
  {
    name: 'Reset zoom',
    handler(chart) {
      chart.resetZoom();
    },
  },
];

export const OPTIONS_FOR_CHARTS = {
  Month: { step: 604800000000, zooming: 12 * 31 * 24 * 60 * 60 * 1000 },
  Week: { step: 20160000000, zooming: 31 * 24 * 60 * 60 * 1000 },
  Day: { step: 10000000000, zooming: 10 * 24 * 60 * 60 * 1000 },
  Hour: { step: 240000000, zooming: 24 * 60 * 60 * 1000 },
  Min: { step: 10000000, zooming: 60 * 60 * 1000 },
};
