import gql from "graphql-tag";

const GET_METRICS_DATA = gql`
query getUnderwritingAttempts($createdAt: DateTime) {
  creditAppProcesses(filters: {createdAtGte: $createdAt}, sort:[CREATED_AT_ASC]) {
    edges {
      node {
        status
        createdAt
      }
    }
  }
}
`;


const GET_UNDERWRITING_DATA = gql`
query getUnderwritingData($labels: [String], $campaigns: [String], $channels: [String], $configNames: [String], $createdAfter: String, $createdBefore: String) {
  underwritingData(labels: $labels, campaigns: $campaigns, channels: $channels, configNames: $configNames, createdAfter: $createdAfter, createdBefore: $createdBefore)
}
`

const GET_UNDERWRITING_FILTERS = gql`
query getUnderwritingFilters{
  underwritingFilters
}
`

export {
  GET_METRICS_DATA,
  GET_UNDERWRITING_DATA,
  GET_UNDERWRITING_FILTERS
};
