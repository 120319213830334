import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import themeReducer from './themeReducers';
import { C } from '../constants';
import history from '../../history';
import outageReducers from "./outageReducers";


const authReducers = (state = {}, action) => {
  switch (action.type) {
  case C.LOGIN:
    localStorage.setItem('current_user', JSON.stringify(action.user));
    return action.user;
  case C.LOGOUT:
    localStorage.removeItem('current_user');
    window.location = '/users/login';
  case C.UNAUTHORIZED:
    localStorage.removeItem('current_user');
    window.location = '/users/login';
    return null;
  default:
    return JSON.parse(localStorage.getItem('current_user'));
  }
};

const leadProvidersReducer = (state = [], action) => {
  switch (action.type) {
  case C.LEAD_PROVIDERS:
    return action.lead_providers;
  default:
    return state;
  }
};

const snackbarReducer = (state = { isOpen: false, message: '<default message>' }, action) => {
  switch (action.type) {
  case C.SNACKBAR:
    return { isOpen: true, message: action.payload };
  case C.SNACKBAR_CLOSE:
    return { isOpen: false };
  default:
    return state;
  }
};


export default combineReducers({
  router: connectRouter(history),
  outages: outageReducers,
  current_user: authReducers,
  lead_providers: leadProvidersReducer,
  snackbar: snackbarReducer,
  themeReducer,
});
