import React, {Component, useRef} from 'react';
import {ReactSortable} from "react-sortablejs";
import Element from "./Element";
import {DragIndicator, RemoveCircle} from "@material-ui/icons";
import {CardHeader, GrayCard, RemoveControl} from "./component_styles";
import Xarrow from "react-xarrows";


class Sequence extends Component {
  constructor(props) {
    super(props);

    this.title = this.props.title || (
      <>
        <DragIndicator style={{fontSize: 'medium'}}/>
        In Sequence
      </>
    )
  }

  render = () => {
    return (
      <div style={{width: 'fit-content', position: 'relative'}}>
        {
          this.props.removeCurrent && (
            <RemoveControl
              onClick={this.props.removeCurrent}
            />
          )
        }
        <GrayCard id={this.props.name}>
          <CardHeader>{this.title}</CardHeader>
          <ReactSortable
            list={this.props._children}
            group="shared"
            animation={200}
            delayOnTouchStart={true}
            delay={2}
            setList={(newState) =>
              this.props.onChange({
                name: this.props.name,
                type: this.props.type,
                _children: newState
              })
            }
            style={{display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: 150}}
          >
            {
              this.props._children.map(
                (item, i) => (
                  <Element
                    key={item.name}
                    removeCurrent={this.props.enableGraphics && this.props.removeChild(i)}
                    onChange={this.props.updateChild(i)}
                    onRemove={this.props.updateChild(i, 'remove')}
                    enableGraphics={this.props.enableGraphics}
                    {...item}
                  />
                )
              )
            }
          </ReactSortable>

          {
            this.props.enableGraphics && this.props._children.slice(0, this.props._children.length - 1).map(
              (item, i) => (
                <Xarrow
                  key={this.props._children[i].name}
                  start={this.props._children[i].name}
                  startAnchor="bottom"
                  end={this.props._children[i + 1].name}
                  endAnchor="top"
                  color='#929292'
                  headSize={5}
                />
              )
            )
          }
          {
            this.props.enableGraphics && this.props._children.length > 0 && (
              <Xarrow
                key={this.props._children[this.props._children.length - 1].name}
                start={this.props._children[this.props._children.length - 1].name}
                startAnchor="bottom"
                end={this.props.name}
                endAnchor="bottom"
                color='#929292'
                headSize={5}
              />
            )
          }
        </GrayCard>
      </div>
    )
  }
}

export default Sequence;
