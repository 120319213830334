import React, {useEffect, useState} from 'react';
import {FormControl, TextField} from "../JsonLogicUI/component_styles";
import Select from "react-select";
import {InputAdornment} from "@material-ui/core";


const PercentageInput = React.memo(({onChange, value: initialValue, label="", format="decimal"}) => {
  const [value, setValue] = useState(initialValue);
  useEffect(() => {setValue(initialValue);}, [initialValue])

  return <>
    <FormControl>
      {label}
      <TextField
        variant="outlined"
        onChange={(event) => {
          let value = event.target.value ? parseFloat(event.target.value) : 0;
          if (format === "decimal")
            value /= 100.0;

          setValue(value);
        }}
        onBlur={() => onChange(value)}
        type="number"
        value={format === "decimal" ? Math.round(value * 100) : value}
        InputProps={{
          inputProps: { min: 0, max: 200 },
          endAdornment: <InputAdornment position='end'>%</InputAdornment>,
        }}
      />
    </FormControl>
  </>
})

export default PercentageInput;
