import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import { spacing } from '@material-ui/system';
import {
  CssBaseline,
  Paper as MuiPaper,
  withWidth
} from '@material-ui/core';

import Header from '../components/Header';
import Footer from '../components/Footer';

const drawerWidth = 260;

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${props => props.theme.body.background};
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${props => props.theme.breakpoints.up('md')} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${props => props.theme.body.background};
  padding-top: 63px;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

class Dashboard2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false
    };
  } 

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  render() {
    const { children, routes, width } = this.props;
    const { mobileOpen } = this.state;
    
    return (
      <Root>
        <CssBaseline />
        <GlobalStyle />
        <AppContent>
          <Header onDrawerToggle={this.handleDrawerToggle} />
          <MainContent>
            {children}
          </MainContent>
          <Footer />
        </AppContent>
      </Root>
    );
  }
}

export default withWidth()(Dashboard2);
