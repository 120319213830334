import React, {Component} from 'react';
import {ContentCentered, TightBlock} from "../Nodes/component_styles";
import {JsonEditor as Editor} from "jsoneditor-react";
import ace from "brace";
import {CampaignFormErrorText} from "../../common/form_styles";
import SwitchCaseInput from "./SwitchCaseInput";


class BiddingInputs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputs: props.inputs
    };
  }

  callOnChange = () => {
    if (this.props.onChange)
      return this.props.onChange(this.state.inputs);
  }

  changeJSON = (event) => {
    this.setState({inputs: {...event}}, this.callOnChange);
  }

  render = () => {
    return (
      <ContentCentered style={{width: "100%"}}>
        <div className="alignCenter">
          <h2>Please make sure that all calculated fields are ordered well. We can get null inputs if required input won't be calculated on time</h2>
        </div>

        <TightBlock style={{height: 500}}>
          <Editor
            value={this.state.inputs}
            onChange={this.changeJSON}
            mode="code"
            htmlElementProps={{style: {height: 500}}}
            ace={ace}
            theme="ace/theme/github"
          />
        </TightBlock>

        {
          this.props.validationErrors && Object.keys(this.props.validationErrors).length > 0 && (
            <CampaignFormErrorText>
              {
                Object.keys(this.props.validationErrors).map(
                  (key) => (
                    this.props.validationErrors[key].length > 0 && (
                      <div>
                        <p>There are some errors in {key}:</p>
                        <ul>
                          {
                            this.props.validationErrors[key].map(
                              (val) => (
                                <li>{val}</li>
                              )
                            )
                          }
                        </ul>
                      </div>
                    )
                  )
                )
              }
            </CampaignFormErrorText>
          )
        }
      </ContentCentered>
    )
  }
}

export default BiddingInputs;
