import React from 'react';
import {useCallback} from 'react';
import {Decoration} from 'react-diff-view';
import {ExpandLess, ExpandMore, ControlPoint} from "@material-ui/icons";

const ICON_TYPE_MAPPING = {
    up: <ExpandLess style={{fontSize: '0.875rem'}} />,
    down: <ExpandMore style={{fontSize: '0.875rem'}} />,
    none: <ControlPoint style={{fontSize: '0.875rem'}} />,
};

const Unfold = ({start, end, direction, onExpand, ...props}) => {
    const expand = useCallback(
        () => onExpand(start, end),
        [onExpand, start, end]
    );

    const icon = ICON_TYPE_MAPPING[direction];
    const lines = end - start;

    return (
        <Decoration {...props}>
            <div className="unfold" onClick={expand}>
                {icon}
                &nbsp;Expand hidden {lines} lines
            </div>
        </Decoration>
    );
};

export default Unfold;
