import React, {Component} from 'react';
import {COMPONENT_MAPPING} from "./const";


class JsonLogicExpression extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showJsonLogic: this.props.showJsonLogic,
      expression: props.expression,
      operation: this.getOperation(props.expression)
    };
  }

  getOperation = (expr) => {
    return (typeof expr === 'object') ? Object.keys(expr)[0] : 'const';
  }

  render = () => (
    <div style={{paddingTop: 5}}>
      <div>
        {
          this.state.operation in COMPONENT_MAPPING &&
          COMPONENT_MAPPING[this.state.operation]({
            expression: this.state.expression,
            onExpressionChange: this.onChange,
            fieldTypes: this.props.fieldTypes,
            removeCurrent: this.props.removeCurrent
          })
        }
      </div>
      {
        this.state.showJsonLogic && (
          <div className="query-builder-result">
            <div>JsonLogic:</div>
            <code>
              {JSON.stringify(this.state.expression)}
            </code>
          </div>
        )
      }
    </div>
  )

  callOnChange = () => {
    if (this.props.onExpressionChange)
      return this.props.onExpressionChange(this.state.expression);
  }

  onChange = (expr) => {
    this.setState(
      {
        expression: expr,
        operation: this.getOperation(expr)
      },
      () => {this.callOnChange();}
    );
  }
}

export default JsonLogicExpression;
