import React from 'react';
import {HorizontalGrid, MainContainer} from "../Bidding/Nodes/component_styles";
import {CircularProgress, Typography} from "@material-ui/core";
import {CampaignFormErrorText, SubmitButton} from "../common/form_styles";
import Button from "@material-ui/core/Button";
import {Plus} from "react-feather";
import AddParameter from "./AddParameter";
import ParametersFormBody from "./ParametersFormBody";
import ReviewParametersChanges from "./ReviewChanges";
import "./main.css"

export const types = {
  BOOLEAN: 'boolean',
  LIST: 'list',
  INT: 'number',
  JSON: 'json'
}

export const actions = {
  DELETE_PARAMETER: 'delete_parameter',
  ADD_PARAMETER: 'add_parameter',
  SET_VALUE: 'set_value',
  SET_NAME: 'set_name',
  CHANGE_TYPE: 'change_type',
}

const newParameterDefault = {
  open: false,
  type: types.LIST,
  key: '',
  value: [],
}

class ParametersForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newParameter: {...newParameterDefault},
      baseParametersConfig: props.additionalInfo.config,
      newParametersConfig: props.parameters,
      errors: '',
    }
    this.states = props.states.STATES
  }

  isInteger = (value) => {
    return /^\d+$/.test(value);
  }

  handleEditParameters = (field, option=null) => event => {
    const {newParameter} = this.state
    const parameters = {...this.state.newParametersConfig}
    this.setState({errors: ''})
    switch (option) {
      case types.LIST: {
        parameters[field] = event.map(item => this.isInteger(item) ? parseInt(item) : item)
        break
      }
      case types.BOOLEAN: {
        parameters[field] = event.target.checked
        break
      }
      case types.INT: {
        parameters[field] = isNaN(parseInt(event.target.value)) ? 0 : parseInt(event.target.value)
        break
      }
      case types.JSON: {
        parameters[field] = event
        break
      }
      case actions.ADD_PARAMETER: {
        if (Object.keys(parameters).indexOf(newParameter.key) !== -1){
          this.setState({errors: `There already exist ${newParameter.key} key`})
          this.setState({newParameter: {...newParameterDefault}})
          return
        }
        parameters[newParameter.key] = newParameter.value
        this.setState({newParameter: {...newParameterDefault}})
        break
      }
      case actions.DELETE_PARAMETER: {
        delete parameters[field]
        break
      }
    }
    this.setState({newParametersConfig: parameters})
  }

  handleEditNewParameter = (field, option=null, value=null) => event => {
    let newParameter = {...this.state.newParameter}
    switch (option) {
      case types.LIST: {
        newParameter[field] = event.map(item => this.isInteger(item) ? parseInt(item) : item)
        break
      }
      case types.BOOLEAN: {
        newParameter[field] = event.target.checked
        break
      }
      case types.INT: {
        newParameter[field] = isNaN(parseInt(event.target.value)) ? 0 : parseInt(event.target.value)
        break
      }
      case types.JSON: {
        newParameter[field] = event
        break
      }
      case actions.CHANGE_TYPE: {
        let newType = event.target.value
        newParameter.type = newType
        switch (newType) {
          case types.LIST: {
            newParameter.value = []
            break
          }
          case types.BOOLEAN: {
            newParameter.value = true
            break
          }
          case types.INT: {
            newParameter.value = 0
          }
        }
        break
      }
      case actions.SET_VALUE: {
        newParameter[field] = value
        break
      }
      case actions.SET_NAME: {
        newParameter[field] = event.target.value
      }
    }
    this.setState({newParameter: newParameter})
  }

  render () {
    const { isFetching, validationErrors } = this.props
    const { newParametersConfig, newParameter, errors} = this.state
    return (
      <>
        {
          validationErrors && Object.keys(validationErrors).length > 0 && (
            <CampaignFormErrorText>
              {
                Object.keys(validationErrors).map(
                  (key) => (
                    validationErrors[key].length > 0 && (
                      <div>
                        <p>There are some errors in {key}:</p>
                        <ul>
                          {
                            validationErrors[key].map(
                              (val) => (
                                <li>{val}</li>
                              )
                            )
                          }
                        </ul>
                      </div>
                    )
                  )
                )
              }
            </CampaignFormErrorText>
          )
        }
        {
          errors && (
            <CampaignFormErrorText>
              {errors}
            </CampaignFormErrorText>
          )
        }
        <AddParameter
          open={newParameter.open}
          newParameter={newParameter}
          onEdit={this.handleEditNewParameter}
          onSubmit={this.handleEditParameters}
          states={this.states}
        />

        <Typography variant="h3">Parameters</Typography>
          <Button
            className="large-button caution"
            variant="contained"
            color="primary"
            style={{position: 'absolute', top: 8, right: 50}}
            onClick={this.handleEditNewParameter('open', actions.SET_VALUE, true)}
          >
            <Plus/>
            &nbsp; Add new parameter
          </Button>
          <MainContainer>
            <HorizontalGrid>
              <ParametersFormBody
                parameters={newParametersConfig}
                states={this.states}
                handleEditParameters={this.handleEditParameters}
              />
            </HorizontalGrid>
          </MainContainer>
        {isFetching && <CircularProgress className="circular" /> }

        <ReviewParametersChanges
          baseConfig={this.state.baseParametersConfig}
          newConfig={this.state.newParametersConfig}
          isFetching={this.props.isFetching}
          additionalInfo={this.props.additionalInfo}
          submitHandler={this.props.onSubmit}
        />
      </>
    )
  }
}

export default ParametersForm;
