import React from 'react';
import JsonLogicExpression from "../../JsonLogicUI/JsonLogicExpression";
import {Button, ButtonGroup,} from "@material-ui/core";
import {TightBlock} from "./component_styles";
import {CampaignFormErrorText} from "../../common/form_styles";
import MultiDropDown from "../../FormControl/MultiDropDown";
import PercentageInput from "../../FormControl/PercentageInput";
import JSONInput from "../../FormControl/JSONInput";
import Action from "./Action";


const Node = React.memo(
  ({mode, config, whenTriggeredActions = [], fieldTypes, actions, errors = null, onChange}) => {
    return <>
      {
        mode === 'visual' && (
          <TightBlock>
            <TightBlock>
              <MultiDropDown
                label="When triggered:"
                values={config.when_triggered}
                onChange={(value) => onChange({...config, when_triggered: value})}
                options={whenTriggeredActions}
              />
              <PercentageInput
                label="Chance to ignore:"
                value={config.chance_to_ignore_node}
                onChange={(val) => onChange({...config, chance_to_ignore_node: val})}
                format="decimal"
              />
            </TightBlock>
            <ButtonGroup variant="contained" color="primary" style={{paddingTop: 5}}>
              <Button style={{height: "fit-content", marginTop: 10}}>IF</Button>
              <JsonLogicExpression
                expression={config.requirement}
                fieldTypes={fieldTypes}
                onExpressionChange={requirement => onChange({...config, requirement})}
              />
            </ButtonGroup>
            <Action
              config={config.action}
              fieldTypes={fieldTypes}
              actions={actions}
              onChange={action => onChange({...config, action})}
              label="DO"
            />
          </TightBlock>
        )
      }
      {
        mode === 'json' && (
          <TightBlock style={{height: 500}}>
            <JSONInput
              value={config}
              onChange={(value) => onChange(value)}
            />
          </TightBlock>
        )
      }
      {
        errors && (
          <CampaignFormErrorText>
            <p>There are some errors in this node's configuration:</p>
            <ul>
            {
              errors.map((error) => (
                <li>{error}</li>
              ))
            }
            </ul>
          </CampaignFormErrorText>
        )
      }
    </>
  }
);

export default Node;
