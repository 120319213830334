
export const C = {

  LEAD_PROVIDERS: 'abba-lead-providers',
  LOGIN: 'abba-login',
  LOGOUT: 'abba-logout',

  BLACKLISTS: 'abba-blacklists',
  OUTAGES: 'abba-outages',
  

  SNACKBAR: 'abba-snackbar-open',
  SNACKBAR_CLOSE: 'abba-snackbar-close',

  UNAUTHORIZED: 'abba-unauthorized',
};

export const SET_THEME = 'SET_THEME';
