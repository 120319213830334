import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import {
  Button,
  CardContent as MuiCardContent, 
  CardHeader as MuiCardHeader, 
  Card as MuiDefaultCard,
  Grid,
  Modal,
  Typography,

} from '@material-ui/core';

import * as Icon from '@material-ui/icons';


export const DefaultCard = withStyles(theme => ({
  root: {
    width: 722,
    // height: 298,
    paddingTop: 58,
  }
}))(MuiDefaultCard);

export const CardHeader = withStyles(theme => ({
  root: {
    padding: 0
  },
  title: {
    textAlign: 'center',
    fontFamily: 'Avenir Medium, Arial Medium, sans-serif',
    fontWeight: 500,
    fontSize: '2em',
    color: '#000000',
    padding: 0 
  }
}))(MuiCardHeader);

export const CardContent = withStyles(theme => ({
  root: {
    textAlign: 'center',
    padding: 0,
    '& p': {
      fontFamily: 'Avenir, Arial, sans-serif',
      fontWeight: 400,
      fontSize: '1.4em',
      color: '#000000',
      paddingBottom: 35,
    }
  }
}))(MuiCardContent);

export const YesButton = withStyles(theme => ({
  root: {
    backgroundColor: 'red',
    marginLeft: 15,
    width: 200,
    fontFamily: 'Avenir Heavy, Arial Heavy, sans-serif',
    fontWeight: 600,
    fontSize: '1.5em',
    float: 'left',
    letterSpacing: 1.25,
    padding: '6px 0px 6px 4px',
  },
  label: {
    justifyContent: 'center',
    '&::before': {
      content: '"Yes"',
    }
  }
}))(Button);
export const NoButton = withStyles(theme => ({
  root: {
    backgroundColor: 'white',
    border: 'solid 1px #979797',
    color: '#979797',
  },
  label: {
    '&::before': {
      content: '"No"',
    }
  }
}))(YesButton);

const WarningModal = ({
  showModal, hideModalHandler, submitHandler, cancelHandler, customCard, headerText, contentText 
}) => {
  const Card = customCard || DefaultCard;
  return (
    <Modal open={showModal} onEscapeKeyDown={cancelHandler} onClose={cancelHandler}>
      <Grid
        container 
        spacing={0} 
        direction="column" 
        alignItems="center"
        justify="center" 
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={6} md={3} style={{ maxWidth: '722px' }}>
          <Card>
            <CardHeader
              title={headerText}
            />
            <CardContent>
              { contentText && <Typography variant="body2" gutterBottom>{contentText}</Typography>}
              <Grid container alignItems="center" justify="center">
                <Grid item>
                  <NoButton onClick={cancelHandler} />
                  <YesButton variant="contained" color="primary" onClick={submitHandler} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default WarningModal;
