
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from '../reducers';
import effects from '../effects';
import history from '../../history';

const middleware = [
  thunk,
  routerMiddleware(history),
  ...effects.map(e => s => n => (a) => (n(a), e(s, a))),
];

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = typeof window === 'object' 
  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ 
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
  }) : compose;

const enhancer = composeEnhancers(
  applyMiddleware(...middleware),
  // other store enhancers if any
);

export default function configureStore(preloadedState) {
  const store = createStore(
    persistedReducer, 
    preloadedState,
    enhancer
  );
  const persistor = persistStore(store);
  return { store, persistor };
}
