
export const DEFAULT_VALUES = {
  'price': 0.01,
  'string': '',
  'percent': 0.5,
  'json': {},
  'ab_test': {
    "ab_test_name": "NewTest",
    "default": "control",
    "variants": [
      {
        "action": {
          "type": "price_set",
          "value": 0.01
        },
        "name": "control",
        "weight": 20
      },
    ]
  }
}