import React, {useState} from "react";
import {
  Paper,
  Modal,
  Checkbox, FormControl, TextField
} from "@material-ui/core";
import {Plus, Trash} from "react-feather";
import CancelIcon from "@material-ui/icons/Cancel";
import Button from "@material-ui/core/Button";
import WarningModal from "../common/warning-modal";
import DataTable from 'react-data-table-component';
import {
  GET_BLACKLIST_CONTENT,
  REMOVE_BLACKLIST_CONTENTS
} from "../../graphql/queries/blacklist";
import {useMutation, useQuery} from "@apollo/client";
import AddBlacklistContentsModal from "./AddBlacklistContentsModal";

function BlacklistContentsModal({open, blacklist, onClose}) {
  const [selectedItems, setSelectedItems] = useState({});
  const [toggledClearRows, setToggledClearRows] = useState(false);

  const [showConfirmationRemovalModal, setShowConfirmationRemovalModal] = useState(false);
  const [showAddBlacklistContentsModal, setShowAddBlacklistContentsModal] = useState(false);
  const [removalReason, setRemovalReason] = useState('Manually removed');
  const [removeBlacklistContents, removeBlacklistContentsResponse] = useMutation(REMOVE_BLACKLIST_CONTENTS);
  const { loading, error, data, refetch } = useQuery(GET_BLACKLIST_CONTENT, {
      variables: {configId: parseInt(blacklist.id), removedAt: null}
    });

  function removeSelectedItems() {
    let result = []
    selectedItems.forEach((item, i) => {
      result.push(item.data)
      if ('lead_provider' in item.data)
        result[i]['lead_provider'] = parseInt(result[i]['lead_provider'])
    });
    removeBlacklistContents({
      variables: {
        blacklistId: parseInt(blacklist.id),
        items: JSON.stringify(result),
        reason: removalReason,
      },
    }).then(r => {
      if (r.data && r.data.removeBlacklistContents && r.data.removeBlacklistContents.ok) {
        setShowConfirmationRemovalModal(false);
        refetch()
      }
      setToggledClearRows(!toggledClearRows)
    })
  }

  if (loading) {
    return <>Loading</>
  }

  if (error) {
    return <>Configs error</>
  }
  const contents = data.blacklistContents.edges.map((item) => ({...item.node, data: JSON.parse(item.node.data)}));
  return (
    <>
      <WarningModal
        headerText={`Are you sure you want to remove these items?`}
        showModal={showConfirmationRemovalModal}
        hideModalHandler={() => setShowConfirmationRemovalModal(false)}
        cancelHandler={() => setShowConfirmationRemovalModal(false)}
        submitHandler={() => removeSelectedItems()}
        contentText={
          <>
            <div>
              Make sure population query does not contain the values you remove in its result.
              Otherwise they will return in the next refreshing.
            </div>
            <FormControl fullWidth className="reason-textarea">
              <label>Enter a reason for removing these items</label>
              <TextField
                required
                id="description"
                value={removalReason}
                onChange={(event) => {
                  setRemovalReason(event.target.value)
                }}
              />
            </FormControl>
          </>
        }
      />
      <AddBlacklistContentsModal
        showModal={showAddBlacklistContentsModal}
        onClose={() => {setShowAddBlacklistContentsModal(false)}}
        blacklist={blacklist}
        refetch={refetch}
      />
      <Modal
        open={open}
        onClose={onClose}
        className="modal"
      >
        <Paper
          className="full-screen-paper"
        >
          <CancelIcon className="top-right-icon" onClick={onClose}/>
          {
            blacklist && (
              <DataTable
                title={"Items on the blacklist " + blacklist.name}
                columns={
                  Object.keys(blacklist.config.field_to_variable_mapping).map(
                    (field) => (
                      {
                        name: field,
                        selector: 'data.' + field,
                        sortable: true,
                      }
                    )
                  ).concat(
                    [
                      {
                        name: 'AB test tier',
                        selector: 'abTestTier',
                        sortable: true,
                      },
                      {
                        name: 'Added at',
                        selector: 'createdAt',
                        sortable: true,
                        format: row => row.createdAt && new Date(row.createdAt).toLocaleString(),
                      },
                      {
                        name: 'Added by',
                        selector: 'createdBy',
                        sortable: true,
                      },
                      {
                        name: 'Reason',
                        selector: 'creationReason',
                        sortable: true,
                      }
                    ]
                  )
                }
                data={contents}
                selectableRows
                onSelectedRowsChange={(state) => {
                  setSelectedItems(state.selectedRows)
                }}
                selectableRowsComponent={Checkbox}
                clearSelectedRows={toggledClearRows}
                contextActions={
                  <Button
                    className="large-button caution"
                    variant="contained"
                    color="primary"
                    onClick={() => {setShowConfirmationRemovalModal(true)}}
                  >
                    <Trash/>
                    &nbsp; Remove selected
                  </Button>
                }
                actions={
                  <Button
                    className="large-button caution"
                    variant="contained"
                    color="primary"
                    onClick={() => {setShowAddBlacklistContentsModal(true)}}
                  >
                    <Plus/>
                    &nbsp; Add new item
                  </Button>
                }
              />
            )
          }
        </Paper>
      </Modal>
    </>
  );
}

export default BlacklistContentsModal;
