import React, {useState, useRef} from "react";
import {useMutation, useQuery} from "@apollo/client";

import WarningModal from "../common/warning-modal";
import AddCampaignModal from "./AddCampaignModal";
import {
  Card,
  GreenSwitch,
} from "../common/form_styles";

import AddBox from "@material-ui/icons/AddBox";
import {CheckBox, CheckBoxOutlineBlank} from "@material-ui/icons";
import MaterialTable from "material-table";
import {
  GET_CAMPAIGNS,
  UPDATE_CAMPAIGNS,
  GET_LEAD_PROVIDERS,
  GET_BRANDS
} from "../../graphql/queries/campaigns";
import UpdateCampaignSection from "./UpdateCampaignSection";
import {customFilterWrapper, tableIcons} from "../Utilities";
import "./main.css"

function Campaigns() {
  const [thisCampaign, setThisCampaign] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showAddCampaignModal, setAddCampaignModal] = useState(false);
  const tableRef = useRef()

  const selectedCampaigns = useRef([]);
  const setSelectedCampaigns = rows => (
    selectedCampaigns.current = rows
  )

  const {
    loading: campaignsLoading,
    error: campaignsError,
    data: campaignsData,
    refetch: refetchCampaigns,
  } = useQuery(GET_CAMPAIGNS);

  const {
    loading: lpLoading,
    error: lpError,
    data: lpData,
  } = useQuery(GET_LEAD_PROVIDERS);

  const {
    loading: brandsLoading,
    error: brandsError,
    data: brandsData,
  } = useQuery(GET_BRANDS)

  const [updateCampaign] = useMutation(UPDATE_CAMPAIGNS);

  let all_campaigns = {};
  let config_files = {};
  let channels = {};
  let tags = [];
  let lead_providers = {};
  let dataForTable = [];
  let brands = {};

  if (campaignsData) {
    campaignsData.campaigns.edges.forEach((item, i) => {
      if(!Object.values(config_files).includes(item.node.configFile))
        config_files[item.node.id] = item.node.configFile;
      if(!Object.values(config_files).includes(item.node.shortFormConfigFile))
        config_files[item.node.id] = item.node.shortFormConfigFile;
      if(!Object.values(channels).includes(item.node.channel))
        channels[item.node.id] = item.node.channel;
      JSON.parse(item.node.tags).forEach(t => {
        if(!tags.includes(t))
         tags.push(t);
      });
      all_campaigns[item.node.id] = item.node;
    });
  }

  if (lpData) {
    lpData.leadProviders.edges.forEach((item, i) => {
      lead_providers[item.node.id] = item.node.name;
    });
  }

  if (brandsData) {
    brandsData.brands.edges.forEach((item, i) => {
      brands[item.node.id] = item.node.brandName;
    });
  }

  const toggleCampaign = () => {
    const campaign = all_campaigns[thisCampaign];
    updateCampaign({
      variables: {
        campaignsId: [parseInt(campaign.id)],
        payload: JSON.stringify({
          [campaign.id]: {
            is_active: !campaign.isActive
          }
        }),
      },
    }).then(r => refetchCampaigns())
    setShowModal(false);
  };

  const toggleSelectedCampaigns = (toggle) => {
    let campaignsId = []
    let payload = {}

    selectedCampaigns.current.map((campaign) => {
      campaignsId.push(campaign.id)
      payload[campaign.id] = { "is_active": toggle}
    })

    updateCampaign({
      variables: {
        campaignsId: campaignsId,
        payload: JSON.stringify(payload),
      },
    }).then(r => refetchCampaigns())
  }

  const hideModal = () => {
    setShowModal(false);
  };

  const showTheModal = (id, e) => {
    setThisCampaign(id);
    setShowModal(true);
  };

  campaignsData &&
    lead_providers &&
    Object.keys(campaignsData.campaigns.edges).map((key, i) => {
      dataForTable.push({
        toggleSwitch: campaignsData.campaigns.edges[key].node.isActive,
        campaign: campaignsData.campaigns.edges[key].node.name,
        tags: campaignsData.campaigns.edges[key].node.tags,
        id: campaignsData.campaigns.edges[key].node.id,
        topPricePoint: campaignsData.campaigns.edges[key].node.topPricePoint,
        cappedBidAmount: campaignsData.campaigns.edges[key].node.cappedBidAmount,
        campaignType: campaignsData.campaigns.edges[key].node.channel,
        leadProvider: campaignsData.campaigns.edges[key].node.leadProviderId && "#" + campaignsData.campaigns.edges[key].node.leadProviderId + ". " + lead_providers[campaignsData.campaigns.edges[key].node.leadProviderId],
        channel: campaignsData.campaigns.edges[key].node.channel,
        configFile: campaignsData.campaigns.edges[key].node.configFile,
        shortFormConfigFile: campaignsData.campaigns.edges[key].node.shortFormConfigFile,
        brandId: campaignsData.campaigns.edges[key].node.brandId && "#" + campaignsData.campaigns.edges[key].node.brandId + ". " + brands[campaignsData.campaigns.edges[key].node.brandId]
      });
    });

  return (
    <>
      <WarningModal
        customCard={Card}
        headerText={`Activate / Deactivate Campaign ${thisCampaign}?`}
        showModal={showModal}
        hideModalHandler={hideModal}
        cancelHandler={hideModal}
        submitHandler={toggleCampaign}
      />
      <AddCampaignModal
        open={showAddCampaignModal}
        setOpen={setAddCampaignModal}
        channels={channels}
        refetch={refetchCampaigns}
        tags={tags}
        lead_providers={lead_providers}
        config_files={config_files}
        brands={brands}
      />
      <div className="alignCenter">
        <h2>Please read through this confluence page (
          <a href="https://creditninja.atlassian.net/wiki/spaces/EN/pages/2194636802/Leads+campaigns+page+redesign"
             rel="noopener noreferrer"
             target="_blank">
            LeadsUI campaigns page
          </a>
          )  before you take any actions for the campaigns.
        </h2>
      </div>
      <MaterialTable
        tableRef={tableRef}
        title="Campaigns"
        icons={tableIcons}
        columns={[
          {
            title: "Is active",
            field: "toggleSwitch",
            render: rowData =>
              <GreenSwitch
                checked={rowData.toggleSwitch}
                onChange={() =>
                  showTheModal(rowData.id)
                }
                value="jason"
              />
            ,
            lookup: {
              true: "Activated",
              false: "Deactivated"
            }
          },
          { title: "Campaign", field: "campaign", customFilterAndSearch: customFilterWrapper("campaign") },
          { title: "Tags", field: "tags", customFilterAndSearch: customFilterWrapper("tags") },
          { title: "ID", field: "id", customFilterAndSearch: customFilterWrapper("id") },
          { title: "Top Price Point", field: "topPricePoint", customFilterAndSearch: customFilterWrapper("topPricePoint") },
          { title: "Capped Bid Amount", field: "cappedBidAmount", customFilterAndSearch: customFilterWrapper("cappedBidAmount") },
          { title: "Campaign Type", field: "campaignType", customFilterAndSearch: customFilterWrapper("campaignType") },
          { title: "Lead Provider", field: "leadProvider", customFilterAndSearch: customFilterWrapper("leadProvider") },
          { title: "Channel", field: "channel", customFilterAndSearch: customFilterWrapper("channel") },
          { title: "Config File", field: "configFile", customFilterAndSearch: customFilterWrapper("configFile") },
          { title: "Short Form Config File", field: "shortFormConfigFile", customFilterAndSearch: customFilterWrapper("shortFormConfigFile") },
          { title: "Brand", field: "brandId",  customFilterAndSearch: customFilterWrapper("brandId")}
        ]}
        data={dataForTable}
        options={{
          filtering: true,
          paging: false,
          selection: true,
        }}
        onSelectionChange={(data) => {
          setSelectedCampaigns([])
          data.map((campaign) => {
            selectedCampaigns.current.push(campaign)
          })
        }}
        detailPanel={ rowData => {
          return(
            <UpdateCampaignSection
              refetch={refetchCampaigns}
              campaignData={all_campaigns[rowData.id]}
              channels={channels}
              tags={tags}
              lead_providers={lead_providers}
              config_files={config_files}
              brands={brands}
              closeUpdateSection={() =>
                tableRef.current.onToggleDetailPanel(
                  [rowData.tableData.id],
                  tableRef.current.props.detailPanel
                )
              }
            />
          )
        }}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        actions={[
          {
            tooltip: 'Deactivate selected campaigns',
            icon: CheckBoxOutlineBlank,
            onClick: () => (
              toggleSelectedCampaigns(false)
            )
          },
          {
            tooltip: 'Activate selected campaigns',
            icon: CheckBox,
            onClick: () => (
              toggleSelectedCampaigns(true)
            )
          },
          {
            tooltip: 'Add a new campaign',
            icon: AddBox,
            isFreeAction: true,
            onClick: () => (
              setAddCampaignModal(true)
            )
          }
        ]}
      />
    </>
  );
}

export default Campaigns;
