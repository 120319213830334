
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Card as MuiCard,
  Table as MuiTable,
  Modal as MuiModal,
  TableCell as MuiTableCell,
  InputBase as MuiInputBase,
  Typography,
  Checkbox as MuiCheckbox,
  Switch as MuiSwitch,
  Paper as MuiPaper,
  CircularProgress as MuiCircularProgress,
  TextField as MuiTextField,
  FormControlLabel as MuiFormControlLabel,
  ButtonGroup,
} from '@material-ui/core';

import MuiSearchIcon from '@material-ui/icons/Search';
import MuiAddIcon from '@material-ui/icons/Add';
import MuiCancelIcon from '@material-ui/icons/Cancel';
import {ArrowLeft} from "@material-ui/icons";

export const Card = styled(MuiCard)`
  background: ${props => props.background};
  color: ${props => props.color};
  margin-bottom: ${props => props.theme.spacing(4)}px;
  width: 100%
`;

export const TableContainer = styled.div`
  width: 83%;
  margin: auto;
`;

export const Table = styled(MuiTable)`
  min-width: 650px;
`;
export const SearchContainer = styled(Card)`
  position: relative;
  border-radius: 4px;
  background-color: ${props => props.theme.palette.common.white};
  &:hover: {
    background-color: ${props => props.theme.palette.common.white};
  };
  margin-bottom: 23px;
  padding: 1px;
  padding-left: 10px;
  width: 620px;
  border: solid 1px #E4E7EB;
  display: inline-block;
  color: #9EA0A5;
`;

export const SearchIconContainer = styled.div`
  width: ${props => props.theme.spacing(7)};
  margin: auto;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonContainer = styled.div`
  float: right;
  display: inline-block;
`;

export const InputBase = styled(MuiInputBase)`
  color: inherit;
  padding: ${props => props.theme.spacing(1, 1, 1, 7)};
  transition: ${props => props.theme.transitions.create('width')};
  height: 2em;
  width: 600px;

  > .MuiInputBase-input {
    padding: 8px 7px 3px 4px;
    font-size: 14px;
    font-family: Avenir, Arial, sans-serif;
    color: black;
  }
`;

export const TableCell = styled(MuiTableCell)`
  font-size: 14px;
  font-family: Avenir, Arial, sans-serif;
  font-weight: 600;
  padding: 6px 5px 7px 5px;
  color: #3A3B3F;
`;

export const PrimaryButtonLarge = styled(Button)`
  background-color: #41A113;
  font-family: Avenir, Arial, sans-serif;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1.25px;
  padding: 6px 6px 6px 4px;
  justify-content: left;
`;

export const ActivateButton = styled(Button)`
  width: 90px;
  background-color: #41A113;
  font-family: Avenir, Arial, sans-serif;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1.25px;
  padding: 6px 0px 6px 4px;
  justify-content: center;
`;

/**
 * Conditional component rendering does not render styled-components css. So use MaterialUse css preprocessor
 */
export const EditCampaignsButton = withStyles(theme => ({
  root: {
    backgroundColor: '#585857',
    marginLeft: 15,
    width: 190,
    fontFamily: 'Avenir, Arial, sans-serif',
    fontWeight: 600,
    fontSize: 14,
    letterSpacing: 1.25,
    padding: '6px 0px 6px 4px',
    justifyContent: 'left'
  }
}))(Button);

export const AddCampaignModalStyle = styled(MuiModal)`
  width: 83%;
  margin: auto;
  overflow: scroll;
`;

export const BiddingModalStyle = styled(MuiModal)`
  width: 100%;
  margin: auto;
  overflow: scroll;
`;

export const CampaignFormErrorText = withStyles(theme => ({
  root: {
    fontFamily: 'Avenir, Arial, sans-serif',
    fontSize: 16,
    marginTop: 21,
    background: '#ff4949',
    borderRadius: 4,
    padding: '5px 13px',
    color: 'white',
    fontWeight: 600,
    backgroundImage: 'initial',
    
  }
}))(Typography);

export const GreenCheckbox = withStyles(theme => ({
  root: {
    color: '#B4BFC9',
  }
}))(MuiCheckbox);

export const GreenSwitch = withStyles(theme => ({
  root: {
    width: 67,
    height: 30,
    padding: 5,
    paddingLeft: 8
  },
  switchBase: {
    '&$checked': {
      transform: 'translateX(-100%)',
      color: 'white',
      left: '104%',
      bottom: 4,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
    top: 4,
    bottom: 5,
  },
  thumb: {
    width: 18,
    height: 18,
  },
  track: {
    border: '1px solid grey',
    backgroundColor: 'grey',
    opacity: 1,
    borderRadius: 9
  },
  checked: {},
  focusVisible: {},
}))(MuiSwitch);

export const SearchIcon = styled(MuiSearchIcon)`
  margin-top: 4px;
  margin-left: -1px;
`;

export const AddIcon = styled(MuiAddIcon)`
  color: white;
`;

export const AddCampaignFormPaper = withStyles(theme => ({
  root:{
    boxShadow: 'none',
    width: '90%',
    margin: '0 auto 0 auto'
  }
}))(MuiPaper);

export const CancelIcon = withStyles(theme => ({
  root: {
    position: 'absolute',
    fontSize: 36,
    cursor: 'pointer',
    top: 8,
    right: 8
  }
}))(MuiCancelIcon);

export const ModalPaper = withStyles(theme => ({
  root: {
    position: 'relative',
    margin: '0 auto 0 auto',
    padding: '20px 27px 20px 35px',
    boxShadow: '0 0 0 0 rgba(0,0,0, 0)',
  }
}))(MuiPaper);

export const ModalCirclularProgress = withStyles(themes => ({
  root: {
    width: '3em',
    height: '3em', 
    marginLeft: 30,
    position: 'relative',
    top: 30,
  }
}))(MuiCircularProgress);

export const TextField = withStyles(theme => ({
  root: {
    paddingTop: 40,
    width: 206,
    height: 46,
    boxSizing: 'content-box',
    '& .MuiInputBase-input': {
      fontFamily: 'Avenir, Arial, sans-serif',
      fontWeight: 500,
      fontSize: 16,
      backgroundColor: 'rgb(216, 216, 216, .3)',
      textAlign: 'center',
      height: 36,
    }
  },
}))(MuiTextField);


export const FormControlLabel = withStyles(theme => ({
  root: {
    float: 'right',
    marginRight: 42,
    marginTop: 40,
    '& span.MuiFormControlLabel-label': {
      paddingRight: 15
    }
  }
}))(MuiFormControlLabel);

export const SubmitButton = withStyles(theme => ({
  root: {
    backgroundColor: '#41A113',
    margin: 30,
    height: 46,
    fontFamily: 'Avenir Heavy, Arial Heavy, sans-serif',
    fontWeight: 600,
    fontSize: 16,
    letterSpacing: 1.43,
    padding: 10,
    justifyContent: 'center'
  }
}))(Button);

export const CancelButton = withStyles(theme => ({
  root: {
    backgroundColor: '#D80303',
    marginLeft: 46
  }
}))(SubmitButton);

export const ButtonBox = styled.div`
  min-width: 200px;
`;

export const PageButtonGroup = withStyles(theme => ({
  root: {
    paddingTop: 5,
    width: '100%',
    padding: '0 20px',
    justifyContent: 'space-between',
    boxShadow: "none",
    '& .MuiButtonGroup-groupedContainedPrimary': {
      border: 'none'
    }
  }
}))(ButtonGroup);

export const TransparentButton = withStyles(theme => ({
  root: {
    background: "none",
    color: "#117F9B",
    padding: 0,
    boxShadow: "none",
    fontSize: "1.2rem",
  }
}))(Button);

export const ArrowLeftCircled = withStyles(theme => ({
  root: {
    borderWidth: 1,
    borderColor: "#929292",
    borderStyle: "solid",
    borderRadius: "50%",
    background: "#f3f3f3",
    padding: 0,
    fontSize: "2rem",
    marginRight: 5
  }
}))(ArrowLeft);
