import React, {useState} from 'react';
import {IconButton,} from "@material-ui/core";
import {CardHeader, ContentCentered, EditableLabel, WhiteCard} from "./component_styles";
import {CodeOutlined, ExpandLess, ExpandMore, ViewQuilt} from "@material-ui/icons";
import styled from "styled-components";
import {RemoveControl} from "../Schema/component_styles";
import Node from "./Node";


export const Wrapper = styled.div`
  width: ${props => props.width};
  order: ${props => props.order};
  justify-content: center;
  display: flex;
`;


const NodeCard = ({config, name, errors, isUnChanged, removeCurrent, onChangeName, fieldTypes, actions, onChangeConfig, whenTriggeredActions, validateName}) => {
  const [nameError, setNameError] = useState(
    name !== 'new_node' ? false : 'Choose a different name for this node.'
  );
  const [mode, setMode] = useState('collapsed');

  return (
    <Wrapper width={mode === 'collapsed' ? 'fit-content' : '100%'}>
      <WhiteCard
        style={{width: "fit-content"}} error={errors}
        backgroundColor={isUnChanged ? '#ffffff' : '#ffffe0'}
      >
        {removeCurrent && <RemoveControl onClick={removeCurrent} />}
        <CardHeader
          action={
            <>
              {
                mode === 'json' && (
                  <IconButton aria-label="settings" onClick={() => setMode('visual')}>
                    <ViewQuilt />
                  </IconButton>
                )
              }
              {
                mode === 'visual' && (
                  <IconButton aria-label="settings" onClick={() => setMode('json')}>
                    <CodeOutlined />
                  </IconButton>
                )
              }
            </>
          }
          title={
            <>
              {
                mode === 'collapsed' && (
                  <IconButton aria-label="settings" onClick={() => setMode('visual')}>
                    <ExpandMore/>
                  </IconButton>
                )
              }
              {
                mode !== 'collapsed' && (
                  <IconButton aria-label="settings" onClick={() => setMode('collapsed')}>
                    <ExpandLess/>
                  </IconButton>
                )
              }
              <EditableLabel
                onChange={
                  (event) => {
                    let name = event.currentTarget.textContent;
                    if (name === 'new_node')
                      setNameError('Choose a different name for this node.');
                    else if (validateName(name)) {
                      setNameError(null);
                      onChangeName(name);
                    }
                    else
                      setNameError('Choose a unique name or edit already existing node.');
                  }
                }
                value={name}
                error={nameError}
              />
            </>
          }
        />
        <ContentCentered>
          <Node
            mode={mode}
            config={config}
            whenTriggeredActions={whenTriggeredActions}
            fieldTypes={fieldTypes}
            actions={actions}
            errors={errors}
            onChange={(config) => onChangeConfig(config)}
          />
        </ContentCentered>
      </WhiteCard>
    </Wrapper>
  )
}

export default NodeCard;
