import { Modal } from '@material-ui/core';
import {CancelIcon, FormControlLabel, ModalPaper, SubmitButton} from "../common/form_styles";
import {FormLabel, RadioGroup, TextField} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import CreatableMultiDropDown from "../FormControl/CreatableMultiDropDown";
import Switch from "@material-ui/core/Switch";
import React from "react";
import {actions, types} from "./ParametersForm";
import ace from "brace";
import {JsonEditor as Editor} from "jsoneditor-react";

function AddParameter({newParameter, onEdit, onSubmit, open, states}){

  return (
    <Modal
      open={open}
      className="add_parameter_modal"
    >
      <ModalPaper>

        <CancelIcon onClick={onEdit("open", actions.SET_VALUE, false)}/>
        <div>
          <FormLabel>Parameter type</FormLabel>
          <RadioGroup
            value={newParameter.type}
            onChange={onEdit('type', actions.CHANGE_TYPE)}
          >
            <FormControlLabel value={types.LIST} control={<Radio />} label="List" style={{margin: '10px'}}/>
            <FormControlLabel value={types.BOOLEAN} control={<Radio />} label="Boolean" style={{margin: '10px'}}/>
            <FormControlLabel value={types.INT} control={<Radio />} label="Integer" style={{margin: '10px'}}/>
            <FormControlLabel value={types.JSON} control={<Radio />} label="JSON" style={{margin: '10px'}}/>
          </RadioGroup>
        </div>
        <div className="key_value_div">
          <div>
            <FormLabel style={{margin: '5px'}}>Parameter's name</FormLabel>
            <TextField
              variant="outlined"
              value={newParameter.key}
              onChange={onEdit("key", actions.SET_NAME)}
            />
          </div>
          <div>
            <FormLabel style={{margin: '5px'}}>Parameter's value</FormLabel>
            {
              newParameter.type === types.LIST && (
                <CreatableMultiDropDown
                  values={newParameter.value}
                  onChange={onEdit("value", types.LIST)}
                  options={states}
                />
              )
            }
            {
              newParameter.type === types.BOOLEAN && (
                <Switch
                  checked={newParameter.value}
                  onChange={onEdit("value", types.BOOLEAN)}
                  color="primary"
                />
              )
            }
            {
              newParameter.type === types.INT && (
                <TextField
                  value={newParameter.value}
                  onChange={onEdit("value", types.INT)}
                  disabled={false}
                />
              )
            }
            {
              newParameter.type === types.JSON && (
                <Editor
                  value={newParameter.value}
                  onChange={onEdit("value", types.JSON)}
                  mode="code"
                  ace={ace}
                  theme="ace/theme/github"
                />
              )
            }
            <SubmitButton variant="contained" color="primary" onClick={onSubmit(null, actions.ADD_PARAMETER)}>Submit</SubmitButton>
          </div>
        </div>

      </ModalPaper>
    </Modal>
  )
}

export default AddParameter;