import 'react-app-polyfill/ie11';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import configureStore from './redux/store';

const { persistor, store } = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>, 
  document.getElementById('root')
);
