import * as React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import TextField from '@material-ui/core/TextField';
import { SubmitButton } from '../../common/form_styles';
import './FiltersContainer.css';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import {
  GET_UNDERWRITING_DATA,
  GET_UNDERWRITING_FILTERS,
} from '../../../graphql/queries/underwritingDashboard';
import { LinearProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 300,
    multiLine: true,
  },
}));

function FiltersContainer({
  setUnderwritingData,
  setStartDateTime,
  setEndDateTime,
  endDateTime,
  startDateTime,
}) {
  const classes = useStyles();
  const [firstLoading, setFirstLoading] = React.useState(true);

  const [pathName, setPathName] = React.useState([]);
  const [channelName, setChannelName] = React.useState([]);
  const [campaignName, setCampaignName] = React.useState([]);
  const [configName, setConfigName] = React.useState([]);

  const {
    loading: filtersLoading,
    error: filtersError,
    data: filtersData,
  } = useQuery(GET_UNDERWRITING_FILTERS);

  const [
    fetchUnderwritingData,
    {
      loading: uwLoading,
      error: uwError,
      data: uwData,
    },
  ] = useLazyQuery(GET_UNDERWRITING_DATA, {
    onCompleted: () => {
      setUnderwritingData(uwData);
    },
  });

  let parsedFiltersData = {}
  if (!filtersLoading)
    parsedFiltersData = JSON.parse(filtersData.underwritingFilters)

  const channelNames = [];
  var x = parsedFiltersData?.channels?.forEach((channel) => {
    if (!channelNames.includes(channel) && !['\xa0', "", " ", undefined, null].includes(channel))
      channelNames.push(channel);
  });

  const campaignNames = [];
  var x = parsedFiltersData?.campaigns?.forEach((campaign) => {
    if (!campaignNames.includes(campaign) && !['\xa0', "", " ", undefined, null].includes(campaign))
      campaignNames.push(campaign);
  });

  const pathNames = [];
  var y = parsedFiltersData?.paths?.forEach((path) => {
    if (!pathNames.includes(path) && !['\xa0', "", " ", undefined, null].includes(path))
     pathNames.push(path);
  });

  const configNames = [];
  var z = parsedFiltersData?.configs?.forEach((config) => {
    if (!configNames.includes(config) && !['\xa0', "", " ", undefined, null].includes(config))
      configNames.push(config);
  });

  const submitHandler = () => {
    fetchUnderwritingData({
      variables: {
        createdBefore: endDateTime,
        createdAfter: startDateTime,
        labels: pathName,
        campaigns: campaignName,
        channels: channelName,
        configNames: configName,
      }
    });
  };

  if (firstLoading) {
    submitHandler();
    setFirstLoading(false);
  }

  return (
    <>
      <h1>Filters</h1>

      <div>
        <h3>Path</h3>
        <FormControl className={classes.formControl}>
          <Select
            multiple
            value={pathName}
            onChange={(event) => {
              setPathName(event.target.value)
            }}
            input={<Input />}
          >
            {pathNames.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <h3>Channel</h3>
        <FormControl className={classes.formControl}>
          <Select
            multiple
            value={channelName}
            onChange={(event) => {
              setChannelName(event.target.value);
            }}
            input={<Input />}
          >
            {channelNames.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <h3>Campaign</h3>
        <FormControl className={classes.formControl}>
          <Select
            multiple
            value={campaignName}
            onChange={(event) => {
              setCampaignName(event.target.value);
            }}
            input={<Input />}
          >
            {campaignNames.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <h3>Config</h3>
        <FormControl className={classes.formControl}>
          <Select
            multiple
            value={configName}
            onChange={(event) => {
              setConfigName(event.target.value);
            }}
            input={<Input />}
          >
            {configNames.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div>
        <h3>Date & Time</h3>
          <div className="timePadding">
          <label>Start DateTime</label><TextField
            id="datetime-local"
            type="datetime-local"
            value={new Date(new Date(startDateTime).getTime() - new Date(startDateTime).getTimezoneOffset() * 60000).toISOString().slice(0, -1)}
            onChange={(newValue) => {
              setStartDateTime(new Date(newValue.target.value));
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <label>End DateTime</label>
          <TextField
            id="datetime-local"
            type="datetime-local"
            value={new Date(new Date(endDateTime).getTime() - new Date(endDateTime).getTimezoneOffset() * 60000).toISOString().slice(0, -1)}
            onChange={(newValue) => {
              setEndDateTime(new Date(newValue.target.value));
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        <SubmitButton
          className="longButton"
          variant="contained"
          color="primary"
          onClick={submitHandler}
        >
          Submit
        </SubmitButton>
        {uwLoading && <LinearProgress />}
      </div>
    </>
  );
}

export default FiltersContainer;
