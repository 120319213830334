import React from 'react';
import ConfigList from '../Config/ConfigList';
import EditParameters from "./EditParameters";

function Parameters() {
  return (
    <>
      <ConfigList
        configType="parameters"
        creationMode="clone"
        enableActivation={true}
        editComponent={EditParameters}
        caption="Parameters"
        defaultConfig={{
        }}
      />
    </>
  );
}

export default Parameters;
