import React, {Component, useState} from 'react';
import {HorizontalGrid, HorizontalSeparator, MainContainer} from "./component_styles";
import {Typography} from "@material-ui/core";
import {AddButtonSmall} from "../Schema/component_styles";
import {AddIcon} from "../../common/form_styles";
import {useQuery} from "@apollo/client";
import {GET_BIDDING_PARAMETERS, GET_CONFIG_DATA} from "../../../graphql/queries/config";
import NodeCard from "./NodeCard";


const BiddingNodes = React.memo(
  ({validationErrors, inputs, initialNodes, nodes: nodesProp, onChange}) => {
    const { loading, error, data } = useQuery(GET_BIDDING_PARAMETERS);
    const [nodes, setNodes] = useState(nodesProp);

    if (loading) {
      return <>Loading</>
    }

    if (error) {
      return <>Configs error</>
    }

    const biddingParameters = JSON.parse(data.biddingParameters);
    const whenTriggeredActions = biddingParameters.WHEN_TRIGGERED_ACTIONS;
    const actions = {
      price_cap: {valueType: "price"},
      price_set: {valueType: "price"},
      price_add: {valueType: "price"},
      price_percentage: {valueType: "percent"},
      price_bucketize: {valueType: "string", options: Object.keys(inputs)},
      execute_module: {valueType: "string", options: biddingParameters.EXECUTABLE_METHODS},
      ab_test_node: {valueType: "ab_test"},
    };
    let fieldTypes = {'$score': 'number'};
    Object.keys(inputs).forEach(
      (input_name) => {
        fieldTypes[input_name] = "string"
      }
    )

    const validateName = (refNodeName) => (newName) => {
      return (newName === refNodeName) || (!(newName in nodes))
    }

    const changeNodeConfig = (refNodeName) => (config) => {
      let newNodes = {};

      Object.keys(nodes).forEach(
        (nodeName) => {
          if (refNodeName === nodeName)
            newNodes[nodeName] = config;
          else newNodes[nodeName] = nodes[nodeName];
        }
      )

      setNodes(newNodes);
      onChange(newNodes, refNodeName, refNodeName);
    }

    const changeNodeName = (refNodeName) => (name) => {
      let newNodes = {};

      Object.keys(nodes).forEach(
        (nodeName) => {
          if (refNodeName === nodeName)
            newNodes[name] = nodes[nodeName];
          else newNodes[nodeName] = nodes[nodeName];
        }
      )

      setNodes(newNodes);
      onChange(newNodes, refNodeName, name);
    }

    const addNode = () => {
      const newNodes = {
        "new_node": {
          "when_triggered": [],
          "chance_to_ignore_node": 0,
          "action": {"type": "price_set", "value": 0.01},
          "requirement": {"==": [{"var": "$score"}, 0.01]},
        },
        ...nodes
      };
      setNodes(newNodes);
      onChange(nodes, null, null);
    }

    const removeNode = (name) => () => {
      let newNodes = {...nodes};
      delete newNodes[name];
      setNodes(newNodes)
      onChange(newNodes, name, null);
    }

    const remainsSame = (name) => {
      return JSON.stringify(nodes[name]) === JSON.stringify(initialNodes[name])
    }

    console.log(nodes);

    return (
      <>
        <Typography variant="h3">Bidding Nodes</Typography>
        <MainContainer>

          <AddButtonSmall
            variant="contained"
            color="primary"
            onClick={addNode}
            style={{marginBottom: 20}}
          >
            <AddIcon />
            &nbsp; Add New
          </AddButtonSmall>
          <HorizontalGrid>
            {
              Object.keys(nodes).map(
                (nodeName) => {
                  return <NodeCard
                    key={nodeName}
                    config={nodes[nodeName]}
                    fieldTypes={fieldTypes}
                    whenTriggeredActions={whenTriggeredActions}
                    actions={actions}
                    name={nodeName}
                    validateName={validateName(nodeName)}
                    onChangeName={changeNodeName(nodeName)}
                    onChangeConfig={changeNodeConfig(nodeName)}
                    errors={validationErrors && validationErrors[nodeName]}
                    removeCurrent={removeNode(nodeName)}
                    isUnChanged={remainsSame(nodeName)}
                  />
                }
              )
            }
          </HorizontalGrid>
        </MainContainer>
      </>
    )
  }
)

export default BiddingNodes;
