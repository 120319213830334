import React, {Component} from 'react';
import {AddIcon} from "../../common/form_styles";
import Element from "./Element";
import {
  AddButtonSmall,
  ContentGrid,
  FullHeightGrid,
  ContentGridWithSeparator, FullHeightFlexibleBlock, ButtonsBox
} from "./component_styles";


class BiddingSchema extends Component {
  constructor(props) {
    super(props);

    const convertedSchema = this.convertToInnerFormat(props.schema);
    this.state = {
      ...convertedSchema,
      unused: BiddingSchema.getUnused(convertedSchema, props.nodes),
      nodes: props.nodes
    };
  }

  static getUnused = (schema, nodes) => {
    const used = BiddingSchema.getUsed(schema);
    return Object.keys(nodes).filter(node => !used.includes(node)).map(item => ({name: item})).sort();
  }

  static getUsed = (schema) => {
    if (schema._children) {
      let unused = [];
      schema._children.forEach(
        (item) => {
          unused = unused.concat(this.getUsed(item));
        }
      )
      return unused;
    }
    return [schema.name]
  }

  addBlock = (type) => () => {
    let _children = [...this.state._children];
    _children.push({
      type: type,
      name: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10),
      _children: []
    });
    this.setState({
      _children: _children
    });
  }

  convertToInnerFormat = (config, id = "0") => {
    if (!config)
      return config;
    if (typeof config === 'string')
      return {
        name: config
      };
    else
      return {
        name: id,
        type: config.type,
        _children: config.children.map(
          (item, i) => this.convertToInnerFormat(item, id + "_" + i)
        )
      };
  }

  convertToSchema = (config) => {
    if (!config)
      return config;
    if (config._children)
      return {
        children: config._children.map(
          (item) => this.convertToSchema(item)
        ),
        type: config.type,
      };
    else
      return config.name;
  }

  callOnChange = () => {
    if (this.props.onChange)
      return this.props.onChange(this.convertToSchema(this.state));
  }

  updateDimensions = () => {
    this.setState({});
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render = () => (
    <>
      <FullHeightFlexibleBlock container spacing={3} style={{height: "96%"}}>
        <ContentGrid item>
          <Element
            type="stack"
            _children={this.state.unused}
            name="Nodes"
            title="Unused nodes"
            onChange={(value) => {
              this.setState({unused: value._children.sort()})
            }}
            enableGraphics={false}
          />
        </ContentGrid>
        <ContentGridWithSeparator item>
          <Element
            _children={this.state._children}
            type={this.state.type}
            name={this.state.name}
            title="Bidding Schema"
            enableGraphics={true}
            onChange={(value) => {
              this.setState(
                {...value},
                this.callOnChange
              )
            }}
            onRemove={(value) => {
              this.setState(
                {unused: BiddingSchema.getUnused(value, this.props.nodes)}
              )
            }}
          />
        </ContentGridWithSeparator>
        <ButtonsBox>
          <AddButtonSmall
            variant="contained"
            color="primary"
            onClick={this.addBlock('stack')}
          >
            <AddIcon />
            &nbsp; Add Sequence Block
          </AddButtonSmall>
          <AddButtonSmall
            variant="contained"
            color="primary"
            onClick={this.addBlock('group')}
          >
            <AddIcon />
            &nbsp; Add Parallel Block
          </AddButtonSmall>
        </ButtonsBox>
      </FullHeightFlexibleBlock>
    </>
  )
}

export default BiddingSchema;
