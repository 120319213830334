import React from 'react';
import StepZilla from "react-stepzilla";
import Step from "./Step";
import styled from "styled-components";


export const MultiPageWrapper = styled.div`
  height: 100%;
  & > .multi-step {
    display: flex;
    flex-direction: column; 
    height: 100%;
  }
`;


class MultiPage extends React.Component {
  render() {
    const { steps, isLoading, startAtStep } = this.props;
    const wrappedSteps = steps.map(
      (step, i) => ({
        name: step.name,
        component: (
          <Step
            index={i}
            steps={steps}
            onPageChange={step.onPageChange}
            isLoading={isLoading}
            bottomButton={step.bottomButton}
          >{step.component}</Step>
        )
      })
    )

    return (
      <MultiPageWrapper>
        <StepZilla
          showSteps={false}
          showNavigation={false}
          steps={wrappedSteps}
          startAtStep={startAtStep}
          preventEnterSubmission={true}
        />
      </MultiPageWrapper>
    );
  }
}

export default MultiPage;
