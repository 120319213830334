import gql from "graphql-tag";

const GET_PRODUCTION_TESTS = gql`
  query {
    productionTests(sort:[CREATED_AT_DESC]) {
      edges {
        node {
          id
          workersUsed
          testsInitiated
          testsExecuted
          status
          image
          configurationOverrides
          targetGroupSql
          resultsSummary
          createdAt
          createdBy
          updatedAt
        }
      }
    }
  }
`

const TRIGGER_BACKGROUND_JOB = gql`
mutation ($params: JSONString, $name: String) {
  triggerBackgroundJob(name: $name, params: $params) {
    ok
    info
  }
}
`;

const TRIGGER_TESTING = gql`
mutation ($configReplacement: JSONString, $ecrImageTag: String, $targetGroupSql: String) {
  triggerProductionTesting(targetGroupSql: $targetGroupSql, configReplacement: $configReplacement, ecrImageTag: $ecrImageTag) {
    ok
    info
    testId
    errors
  }
}
`;

const CANCEL_TESTING = gql`
mutation ($testId: Int) {
  cancelProductionTesting(testId: $testId) {
    ok
    info
  }
}
`;

export {GET_PRODUCTION_TESTS, TRIGGER_BACKGROUND_JOB, TRIGGER_TESTING, CANCEL_TESTING};