import React, {useState} from 'react';
import {useCallback} from 'react';
import {
  Diff,
  Hunk,
  useSourceExpansion,
  useMinCollapsedLines,
  useChangeSelect, getChangeKey,
} from 'react-diff-view';
import HunkInfo from './HunkInfo';
import UnfoldCollapsed from './UnfoldCollapsed';
import {
  Avatar, Button, FilledInput, FormControl, InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  TextField,
  Tooltip,
} from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";

const stopPropagation = e => e.stopPropagation();

const useEnhance = (hunks, oldSource) => {
  const [hunksWithSourceExpanded, expandRange] = useSourceExpansion(hunks, oldSource);
  const hunksWithMinLinesCollapsed = useMinCollapsedLines(5, hunksWithSourceExpanded, oldSource);
  const [selection, toggleSelection] = useChangeSelect(hunksWithMinLinesCollapsed, {multiple: true});

  return {
    expandRange,
    selection,
    toggleSelection,
    hunks: hunksWithMinLinesCollapsed,
  };
};

const CommentBlock = React.memo(props => {
  const {onClick, show} = props;
  const [value, setValue] = useState('');

  const handleChangeText = name => event => {
    setValue(event.target.value)
  };

  return (
    <>
      <FormControl fullWidth variant="filled">
        <InputLabel htmlFor="filled-adornment-amount">Type in your comment</InputLabel>
        <FilledInput
          value={value}
          onChange={handleChangeText('value')}
        />
        <Button onClick={() => {onClick(value); setValue('')}}>
          Submit
        </Button>
      </FormControl>
    </>
  );
});

const DiffView = React.memo(props => {
  const {oldSource, type, comments, addComment} = props;
  const [displayComments, setDisplayComments] = useState([]);

  const {
    expandRange,
    selection,
    toggleSelection,
    hunks
  } = useEnhance(props.hunks, oldSource);
  const viewType = "split";
  const showGutter = true;
  const renderGutter = useCallback(
    displayComments => ({change, side, inHoverState, renderDefault, wrapInAnchor}) => {
      const canComment = comments && inHoverState && (viewType === 'split' || side === 'new');
      const changeKey = getChangeKey(change);

      if (canComment)
        return (
          <Tooltip title={<>Comment on line {renderDefault()}</>}>
            <span
              className="comment-trigger"
              onClick={(event) => {
                stopPropagation(event);
                if (displayComments.includes(changeKey)) {
                  setDisplayComments(displayComments.filter(value => value !== changeKey));
                }
                else {
                  setDisplayComments([...displayComments, changeKey]);
                }
              }}>+</span>
          </Tooltip>
        );

      return wrapInAnchor(renderDefault());
    },
    [viewType]
  );
  const linesCount = oldSource ? oldSource.split('\n').length : 0;
  const renderHunk = (children, hunk, i, hunks) => {
    const previousElement = children[children.length - 1];
    const decorationElement = oldSource
      ? (
        <UnfoldCollapsed
          key={'decoration-' + hunk.content}
          previousHunk={previousElement && previousElement.props.hunk}
          currentHunk={hunk}
          linesCount={linesCount}
          onExpand={expandRange}
        />
      )
      : <HunkInfo key={'decoration-' + hunk.content} hunk={hunk}/>;
    children.push(decorationElement);
    const events = {
      onClick: toggleSelection,
    };

    const hunkElement = (
      <Hunk
        key={'hunk-' + hunk.content}
        hunk={hunk}
        codeEvents={events}
        gutterEvents={events}
      />
    );
    children.push(hunkElement);

    if (i === hunks.length - 1 && oldSource) {
      const unfoldTailElement = (
        <UnfoldCollapsed
          key="decoration-tail"
          previousHunk={hunk}
          linesCount={linesCount}
          onExpand={expandRange}
        />
      );
      children.push(unfoldTailElement);
    }

    return children;
  };

  const getWidgets = hunks => {
    const changes = hunks.reduce((result, {changes}) => [...result, ...changes], []);
    return changes.reduce(
      (widgets, change) => {
        if (!comments) return widgets;

        const changeKey = getChangeKey(change);
        const lineComments = comments[changeKey] || [];

        if (displayComments.includes(changeKey) || comments[changeKey])
          return {
            ...widgets,
            [changeKey]: <List>
              {
                lineComments.map(
                  ({author, photo, message, pending}) =>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar alt="Remy Sharp" src={photo} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={<>{author}{pending && " (Pending)"}</>}
                        secondary={
                          <React.Fragment>
                            {message}
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                )
              }
              <ListItem alignItems="flex-start">
                <CommentBlock onClick={addComment(changeKey)} />
              </ListItem>
            </List>
          };
        return widgets;
      },
      {}
    );
  };

  return (
    <Diff
      optimizeSelection
      viewType={viewType}
      diffType={type}
      hunks={hunks}
      oldSource={oldSource}
      gutterType={showGutter ? 'default' : 'none'}
      selectedChanges={selection}
      renderGutter={renderGutter(displayComments)}
      widgets={getWidgets(hunks)}
    >
      {hunks => hunks.reduce(renderHunk, [])}
    </Diff>
  );
});

export default DiffView;
