import React, { useState, useEffect } from 'react';
import AceDiff from "ace-diff";
import {CancelButton, SubmitButton, PrimaryButton} from "../common/form_styles";
import {Button} from '@material-ui/core';
import "./conflictResolver.css";
import ace from 'brace'
import {useMutation, useQuery} from "@apollo/client";
import { UPDATE_MERGE_REQUEST,ADD_MERGE_REQUEST_VERDICT, GET_CONFIG_HISTORY } from "../../graphql/queries/config";

function ConflictResolver({activeConfig, selectedConfig, showResolver, updateConfigReview}){
  const ace = require('brace');
  var acedif;

  const [updateMergeRequestVerdict, mergeRequestResponseVerdict] = useMutation(
    ADD_MERGE_REQUEST_VERDICT,
    {
        refetchQueries: [
        {query: GET_CONFIG_HISTORY, variables: {configId: activeConfig.id}},
      ]
    }
  );

  const [updateMergeRequest, updateMergeRequestResponse] = useMutation(
    UPDATE_MERGE_REQUEST,
    {
      refetchQueries: [
        {query: GET_CONFIG_HISTORY, variables: {configId: activeConfig.id, baseConfigId: activeConfig.configHistoryId}},
      ]
    }
  );

  let diffIndex = 0;

  const scrollToDiff = (delta) => {
    var diffs = acedif.diffs;

    diffIndex += delta;
    if (diffIndex < 0) {
      diffIndex = diffs.length - 1;
    } else if (diffIndex > diffs.length - 1) {
      diffIndex = 0;
    }

    var lrow = diffs[diffIndex].leftStartLine;
    var rrow = diffs[diffIndex].rightStartLine;

    if (lrow > 5) {
      lrow -= 5;
    }

    if (rrow > 5) {
      rrow -= 5;
    }

    acedif.getEditors().left.scrollToLine(lrow);
    acedif.getEditors().right.scrollToLine(rrow);
  }


  const onResolve = (additionalData, config) => {
    updateMergeRequest({
      variables: {id: additionalData.id, config: config, baseConfigId: activeConfig.configHistoryId}
    }).then(r => {
      if (r.data && r.data.updateMergeRequest && r.data.updateMergeRequest.ok) {
        updateConfigReview(additionalData)
        showResolver(false)
      }
    });

  }

  ace.Range = ace.acequire("ace/range").Range

  useEffect(() => {
    acedif = new AceDiff({
        ace,
        mode: "ace/mode/json",
        theme: null,
        element: ".custom",
        diffGranularity: 'broad',
        showDiffs: true,
        showConnectors: true,
        maxDiffs: 5000,
        left: {
          content: JSON.stringify(activeConfig?.config, null, 3),
          mode: "ace/mode/json",
          theme: null,
          editable: false,
          copyLinkEnabled: true
        },
        right: {
          content: JSON.stringify(JSON.parse(selectedConfig?.config), null, 3),
          mode: "ace/mode/json",
          theme: null,
          editable: true,
          copyLinkEnabled: false
        },
        classes: {
          diff: 'acediff__diffLine',
          connector: 'acediff__connector',
          newCodeConnectorLinkContent: '&#8594;',
          deletedCodeConnectorLinkContent: '&#8592;',
        },
      });

        var editor1 = acedif?.getEditors().left;
        var session1 = editor1?.session;

        var editor2 = acedif?.getEditors().right;
        var session2 = editor2?.session
        ;
        session1.on('changeScrollTop',
          function(scroll) {
            session2.setScrollTop(parseInt(scroll) || 0)
          }
        );
        session1.on('changeScrollLeft',
          function(scroll) {
            session2.setScrollLeft(parseInt(scroll) || 0)
          }
        );
        session2.on('changeScrollTop',
          function(scroll) {
            session1.setScrollTop(parseInt(scroll) || 0)
          }
        );
        session2.on('changeScrollLeft',
          function(scroll) {
            session1.setScrollLeft(parseInt(scroll) || 0)
          }
        );

      }, []);

  return (
    <>
    <div className="container">

      <span className="column">
        <h3>Active Config</h3>
        <h3>Selected Config to Resolve</h3>
      </span>

      <span className="column">
        <Button className="diff-nav-button" variant="contained" color="primary" onClick={()=>scrollToDiff(1)} className="next">Next Diff</Button>
        <Button className="diff-nav-button" variant="contained" color="primary" onClick={()=>scrollToDiff(-1)} className="prev">Previous Diff</Button>
      </span>

      <span className="column">
        <div className="custom" />
      </span>

      <span className="column">
        <SubmitButton variant="contained" color="primary" onClick={()=>{
          var diffEditors = acedif.getEditors();
          onResolve(selectedConfig, diffEditors?.right?.session?.doc?.$lines.join(""))
          }
        }>Update Selected Config</SubmitButton>
        <CancelButton variant="contained" color="primary" onClick={()=>showResolver(false)}>Discard Changes</CancelButton>
      </span>

    </div>
    </>
  )

}

export default ConflictResolver;
