import React, {Component} from 'react';
import {DragIndicator} from "@material-ui/icons";
import {NodeCard, NodeCardText, RemoveControl} from "./component_styles";
import DragIcon from "../../common/DragIcon";


class Node extends Component {
  constructor(props) {
    super(props);
  }

  render = () => (
    <div style={{width: 'fit-content', position: 'relative'}}>
      {
        this.props.removeCurrent && (
          <RemoveControl
            style={{right: -2}}
            onClick={this.props.removeCurrent}
          />
        )
      }
      <NodeCard color="textSecondary" style={{width: 200}} id={this.props.name}>
        <DragIcon/>
        <NodeCardText>{this.props.name.replaceAll('_', ' ')}</NodeCardText>
      </NodeCard>
    </div>
  )
}

export default Node;
