import React from "react"
import { connect } from "react-redux"
import styled from "styled-components"

import {
  Button,
  CardContent, CardHeader,
  FormControl,
  Grid,
  Input, InputLabel,
  Modal,
  Button as MuiButton,
  Card as MuiCard,
  Divider as MuiDivider,
  TextField, Typography as MuiTypography,
} from "@material-ui/core";
import * as Icon from "@material-ui/icons";
import { spacing } from "@material-ui/system";

import { C } from '../../redux/constants'
import A from '../../redux/actions'

const ButtonCreate = styled(MuiButton)`
  margin-top: ${props => props.theme.spacing(4)}px;
`
const Card = styled(MuiCard)`
  background: ${props => props.background};
  color: ${props => props.color};
  margin-bottom: ${props => props.theme.spacing(4)}px;
  width: 80%;
  margin: 10px auto 0 auto;
`
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);


class LeadProvidersIndex extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      lead_providers: props.lead_providers,
      current_user: props.current_user,
      isNewModalOpen: false,
      newLeadProviderName: '',
    }
    // console.log('+++ LeadProvidersIndex constructor:', props)
    // props.dispatch({ type: C.LEAD_PROVIDERS })
    A.getLeadProviders()(props.dispatch, ()=>this.state)
  }

  toggleNewModal = () => {
    this.setState({ isNewModalOpen: !this.state.isNewModalOpen })
    this.setState({ newLeadProviderName: ""})
  }

  createLeadProvider = () => {
    // console.log('+++ creating lead provider')
    A.createLeadProvider({ name: this.state.newLeadProviderName })(this.props.dispatch, ()=>this.state)
    this.toggleNewModal()
  }

  handleNewChange = (e) => {
    this.setState({ newLeadProviderName: e.target.value })
  }

  render () {
    // console.log('+++ LeadProvidersIndex render:', this.props, this.state, )

    return (
      <React.Fragment>

        { /* new lead provider modal */ }
        { /* <Modal open={true} > */ }
        <Modal open={this.state.isNewModalOpen} >
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: '100vh' }}
          >
            <Grid item xs={6} md={6} style={{ width: '50vw' }} >
              <Card>
                <CardHeader
                  action={<Button onClick={this.toggleNewModal} ><Icon.Close /></Button>}
                  title="New Lead Provider"
                />
                <CardContent>
                  <Typography variant="body2" gutterBottom>
                    Use this form to create a new LeadProvider.<br />
                    The token is automatically generated.
                  </Typography>
                  <FormControl fullWidth >
                    <TextField placeholder="name" value={this.state.newLeadProviderName} onChange={this.handleNewChange} />
                  </FormControl>
                  { /* <FormControl fullWidth>
                    <InputLabel htmlFor="endpoint_url">Endpoint Url</InputLabel>
                    <Input placeholder="https://leads2.creditninja.com/zero_parallels" />
                  </FormControl> */ }
                  <Grid container alignItems="center" justify="center"><Grid item>
                    <ButtonCreate variant="contained" color="primary"  onClick={this.createLeadProvider} >Create</ButtonCreate>
                  </Grid></Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Modal>

        <Grid justify="space-between" container spacing={6} style={{ width: '80%', margin: '0 auto 0 auto' }}>
          <Grid item>
            <Typography variant="h3" display="inline">
              Lead Providers
            </Typography>
          </Grid>
          <Grid item >
            <Button color="secondary" variant="contained" onClick={this.toggleNewModal} >New</Button>
          </Grid>
        </Grid>

        <Divider my={6} />

        <Grid>
          { Object.keys(this.props.lead_providers).map((key, idx) => {
            let lead = this.props.lead_providers[key]
            return (
              <Card key={idx} >
                { /* <CardHeader
                  action={<Button color="secondary" variant="contained" >Edit</Button>}
                  title={lead.name}
                /> */ }
                <CardHeader
                  title={lead.name}
                />
                <CardContent>
                  <ul>
                    <li>{lead.token}</li>
                  </ul>
                </CardContent>
              </Card>
          )})}
        </Grid>

      </React.Fragment>)
  }
}

const mapS = (state, ownProps) => {
  return {
    lead_providers: state.lead_providers,
    current_user: state.current_user,
  }
}

export default connect(mapS)(LeadProvidersIndex)
