import React from 'react';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import DateFnsUtils from '@date-io/date-fns';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { StylesProvider } from '@material-ui/styles';
import { ThemeProvider } from 'styled-components';

import history from './history';
import maTheme from './theme';
import Routes from './routes/Routes';
import client from "./graphql/client";
import Dashboard2Layout from "./pages/Dashboard2";
import {ApolloProvider} from "@apollo/client";

const App = ({ theme }) => (
  <ApolloProvider client={client}>
    <StylesProvider injectFirst>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <MuiThemeProvider theme={maTheme[theme.currentTheme]}>
          <ThemeProvider theme={maTheme[theme.currentTheme]}>
            <ConnectedRouter history={history}>
              <Routes />
            </ConnectedRouter>
          </ThemeProvider>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </StylesProvider>
  </ApolloProvider>
);


export default connect(store => ({ theme: store.themeReducer }))(App);
