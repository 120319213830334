import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { GoogleLogin, GoogleLogout } from 'react-google-login'

import { Paper } from "@material-ui/core";
import { spacing } from "@material-ui/system";

import { C }            from '../../redux/constants'

/**
 * From: https://www.npmjs.com/package/react-google-login
 */

const Wrapper = styled(Paper)`
  padding: ${props => props.theme.spacing(6)}px;

  ${props => props.theme.breakpoints.up("md")} {
    padding: ${props => props.theme.spacing(10)}px;
  }
`;

class Auth2Inner extends React.Component {

  logout = () => {
    localStorage.removeItem('current_user')
    console.log('+++ deleted current_user:', localStorage.getItem('current_user'))
  }

  responseGoogle = (response) => {
    // console.log("+++ google response:", response);
    if (google_auth_response) {
      var response = google_auth_response;
    }
    this.props.dispatch({ type: C.LOGIN, user: response })
    this.render()
  }

  isAuthenticated = () => {
    let a = localStorage.getItem('current_user')
    if (a) {
      a = JSON.parse(a)
      if (a.profileObj?.email?.indexOf('creditninja.com') > 0) {
        return true
      }
      return false
    }
    return false
  }

  render () {
    if (this.isAuthenticated()) {
      window.location = "/";
    }

    return (<Wrapper>
      <h1>Please login</h1>
      <GoogleLogin
        clientId="35855638317-v9avlfqfdmm6g987ms73l1fpmokj7ul4.apps.googleusercontent.com"
        buttonText="Login"
        onSuccess={this.responseGoogle}
        onFailure={this.responseGoogle}
        cookiePolicy={'single_host_origin'}
        isSignedIn={true}
      />
      { /* <GoogleLogout
      clientId="35855638317-v9avlfqfdmm6g987ms73l1fpmokj7ul4.apps.googleusercontent.com"
      buttonText="Logout"
      onLogoutSuccess={this.logout} ></GoogleLogout> */ }

      { /* <Link to='/' >Home</Link> */ }

    </Wrapper>)
  }
}
const mapS = (state, ownProps) => {
  return {
    current_user: state.current_user,
  }
}
const Auth2 = connect(mapS)(Auth2Inner)


class LogoutInner extends React.Component {
  render () {
    this.props.dispatch({ type: C.LOGOUT })
    return(<Redirect to="/" />)
  }
}


const Logout = connect()(LogoutInner)

export {
  Auth2,
  Logout
}
