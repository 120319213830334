import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { subDays } from 'date-fns';

import MetricsContainer from './Metrics/MetricsContainer';
import ChartsContainer from './Charts/ChartsContainer';
import OptionsContainer from './Options/OptionsContainer';
import FiltersContainer from './Filters/FiltersContainer';

import './index.css'

const UnderwritingDashboard = () => {
  const d = new Date();
  const [startDateTime, setStartDateTime] = useState(subDays(d, 7));
  const [endDateTime, setEndDateTime] = useState(d);
  const [granularity, setGranularity] = useState('Min');
  const [typeOfChart, setTypeOfChart] = useState('Line');
  const [underwritingData, setUnderwritingData] = useState(null);
  return (
    <>
      <Grid
        container
        spacing={3}
        className="dashboardContainer"
      >
        <Grid item xs={9}>
          <Grid container direction="column">
            <Grid item>
              <MetricsContainer />
            </Grid>
            <Grid item>
              <ChartsContainer
                typeOfChart={typeOfChart}
                granularity={granularity}
                underwritingData={underwritingData}
                startDateTime={startDateTime}
                endDateTime={endDateTime}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid container direction="column">
            <Grid item>
              <OptionsContainer
                typeOfChart={typeOfChart}
                setTypeOfChart={setTypeOfChart}
                granularity={granularity}
                setGranularity={setGranularity}
              />
            </Grid>
            <Grid item>
              <FiltersContainer
                setUnderwritingData={setUnderwritingData}
                startDateTime={startDateTime}
                endDateTime={endDateTime}
                setStartDateTime={setStartDateTime}
                setEndDateTime={setEndDateTime}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default UnderwritingDashboard;
