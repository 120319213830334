import React, {Component} from 'react';
import {Button, ButtonGroup} from "@material-ui/core";
import JsonLogicExpression from "./JsonLogicExpression";
import {BlockWithLineOnLeft, DashSign} from "./component_styles";
import {Add, AddCircle, AddCircleOutline, AddToPhotos, PostAdd, RemoveCircle} from "@material-ui/icons";
import CustomizedMenu from "./Menu";

class ConjunctionBlock extends Component {
  constructor(props) {
    super(props);

    let operation = Object.keys(props.expression)[0];
    this.possibleOperations = ['and', 'or'];
    this.state = {
      operation: operation.toLowerCase(),
      args: props.expression[operation],
      anchorEl: null
    };
  }

  convertToTrue = () => {
    return this.props.onExpressionChange(true);
  }

  addOperatorBlock = () => {
    let newArgs = this.state.args.slice();
    newArgs.push({'==': [{'var': Object.keys(this.props.fieldTypes)[0]}, '']});
    this.setState(
      {args: newArgs},
      () => {this.callOnChange();}
    );
  }

  addConjunctionBlock = () => {
    let newArgs = this.state.args.slice();
    newArgs.push({'and': [{'==': [{'var': Object.keys(this.props.fieldTypes)[0]}, '']}]});
    this.setState(
      {args: newArgs},
      () => {this.callOnChange();}
    );
  }

  addConstantBlock = () => {
    let newArgs = this.state.args.slice();
    newArgs.push(true);
    this.setState(
      {args: newArgs},
      () => {this.callOnChange();}
    );
  }

  removeArg = i => () => {
    let newArgs = this.state.args.slice();
    newArgs.splice(i, 1);
    this.setState(
      {args: newArgs},
      () => {this.callOnChange();}
    );
  }

  switchOperation = (op) => {
    return () => {
      this.setState(
        {operation: op},
        () => {this.callOnChange();}
        );
    }
  }

  updateArg = (arg_i) => {
    return (expr) => {
      let args = this.state.args;
      args[arg_i] = expr;
      this.setState(
        {args: args},
        () => {this.callOnChange();}
      )
    }
  }

  callOnChange = () => {
    if (this.props.onExpressionChange)
      return this.props.onExpressionChange(this.toJsonLogic());
  }

  toJsonLogic = () => {
    let data = {}
    data[this.state.operation] = this.state.args;
    return data
  }

  render = () => (
    <div>
      <ButtonGroup variant="contained" color="primary" style={{paddingTop: 5}}>
        <DashSign/>
        {
          this.possibleOperations.map(
            (op, i) => {
              if (op === this.state.operation)
                return <Button key={i} onClick={this.switchOperation(op)}>{op.toUpperCase()}</Button>;
              return <Button variant="outlined" key={i} onClick={this.switchOperation(op)}>{op.toUpperCase()}</Button>;
            }
          )
        }
        <div style={{paddingTop: 5, paddingLeft: 10}}>
          <CustomizedMenu
            options={[
              {title: 'Add Condition', action: this.addOperatorBlock},
              {title: 'Add Group', action: this.addConjunctionBlock},
              {title: 'Add Constant', action: this.addConstantBlock},
            ]}
            icon="add-circle"
          />
          <RemoveCircle
            color="primary"
            onClick={this.props.removeCurrent || this.convertToTrue}
          />
        </div>
      </ButtonGroup>
      <BlockWithLineOnLeft>
        {
          this.state.args.map(
            (arg, i) => (
              <>
                <JsonLogicExpression
                  showJsonLogic={false}
                  dashed={true}
                  expression={arg}
                  onExpressionChange={this.updateArg(i)}
                  fieldTypes={this.props.fieldTypes}
                  key={i.toString() + JSON.stringify(arg)}
                  removeCurrent={this.removeArg(i)}
                />
              </>
            )
          )
        }
      </BlockWithLineOnLeft>
    </div>
  )
}

export default ConjunctionBlock;
