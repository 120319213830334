import React from 'react';
import _ from 'lodash';
import ace from "brace";
import 'brace/mode/json';
import 'brace/theme/github';
import { Controlled as CodeMirror } from "react-codemirror2";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/sql/sql";
import "codemirror/addon/hint/show-hint";
import "codemirror/addon/hint/sql-hint";
import "codemirror/addon/hint/show-hint.css";
import "codemirror/keymap/sublime";
import "codemirror/addon/edit/closebrackets";
import "codemirror/addon/edit/closetag";
import "codemirror/addon/fold/foldcode";
import "codemirror/addon/fold/foldgutter";
import "codemirror/addon/fold/brace-fold";
import "codemirror/addon/fold/comment-fold";
import "codemirror/addon/fold/foldgutter.css";

import {JsonEditor as Editor} from "jsoneditor-react";
import {CampaignFormErrorText} from "../common/form_styles";
import {SubmitButton} from "../common/form_styles";
import {Paper, TextField, CircularProgress} from "@material-ui/core";
import Select from "react-select";
import {defaultConfig} from "./index";


class BlacklistForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...defaultConfig,
      ...props.blacklist,
      name: props.additionalInfo?.name
    };

    this.mode_text = {
      replace: 'Replace existing list with query results',
      add: 'Add new items from query results to existing list',
    }
  }

  handleChangeText = name => event => {
    this.setState({ [name]: event.target.type === 'number' ? parseInt(event.target.value) : event.target.value });
  };

  handleChangeJson = name => event => {
    this.setState({ [name]: event });
  };

  handleChangeSelectOption = name => (option, actionMeta) => {
    switch (actionMeta.action) {
      case 'select-option':
      case 'create-option': {
        this.setState({ [name]: option.value });
        return;
      }
      default:
        return;
    }
  };

  submitHandler = event => {
    let { name, field_to_variable_mapping, query, url, creation_reason, mode } = this.state;
    const { onSubmit, additionalInfo } = this.props;
    if (name !== ''){
      let first_param = additionalInfo == undefined ? name : additionalInfo;
      query = query === "" ? undefined : query;
      url = url === "" ? undefined : url;
      onSubmit(
        first_param,
        {
          field_to_variable_mapping,
          creation_reason,
          url,
          query,
          mode,
        },
        ""
      );
    }
    else
      this.setState({nameError: true})
  }

  render() {
    const { name, query, url, creation_reason, mode, field_to_variable_mapping, nameError } = this.state;
    const { isFetching, blacklist, validationErrors } = this.props;
    return (
      <>
      {
        validationErrors && Object.keys(validationErrors).length > 0 && (
          <CampaignFormErrorText>
            {
              Object.keys(validationErrors).map(
                (key) => (
                  validationErrors[key].length > 0 && (
                    <div>
                      <p>There are some errors in {key}:</p>
                      <ul>
                        {
                          validationErrors[key].map(
                            (val) => (
                              <li>{val}</li>
                            )
                          )
                        }
                      </ul>
                    </div>
                  )
                )
              )
            }
          </CampaignFormErrorText>
        )
      }
        <Paper mt={3} className="form-paper">
          <form noValidate autoComplete="off">
            <label>
              <div>Blacklist Name:</div>
              <TextField
                error={nameError}
                required
                id="name"
                m={2}
                value={name}
                onChange={this.handleChangeText('name')}
                disabled={!!blacklist}
              />
            </label>
            <label>
              Mode:
              <Select
                onChange={this.handleChangeSelectOption('mode')}
                onInputChange={this.handleChangeSelectOption('mode')}
                options={[
                  { value: 'replace', label: 'Replace existing list with query results' },
                  { value: 'add', label: 'Add new items from query results to existing list' }
                ]}
                value={{ value: mode, label: this.mode_text[mode] }}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              />
            </label>
            <label>
              Field to variable mapping:
              <Editor
                value={field_to_variable_mapping}
                mode={"code"}
                ace={ace}
                theme="ace/theme/github"
                onChange={this.handleChangeJson('field_to_variable_mapping')}
                htmlElementProps={{style: {height: 300}}}
              />
            </label>
            <label>
              Creation reason:
              <CodeMirror
                value={creation_reason}
                onBeforeChange={(editor, data, value) => {
                  this.setState({creation_reason: value});
                }}
                onChange={(editor, data, value) => {}}
              />
            </label>
            <label>
              Url:
              <CodeMirror
                value={url}
                onBeforeChange={(editor, data, value) => {
                  this.setState({url: value});
                }}
                onChange={(editor, data, value) => {}}
              />
            </label>
            <label>
              Query:
              <CodeMirror
                value={query}
                options={{
                  mode: "text/x-pgsql",
                  lineWrapping: true,
                  smartIndent: true,
                  lineNumbers: true,
                  foldGutter: true,
                  gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
                  autoCloseTags: true,
                  keyMap: "sublime",
                  matchBrackets: true,
                  autoCloseBrackets: true
                }}
                onBeforeChange={(editor, data, value) => {
                  this.setState({query: value});
                }}
                onChange={(editor, data, value) => {}}
              />
            </label>
          </form>
          <SubmitButton variant="contained" color="primary" onClick={this.submitHandler}>Submit</SubmitButton>
          {isFetching && <CircularProgress className="circular" /> }
        </Paper>
      </>
    );
  }
}

export default BlacklistForm;
