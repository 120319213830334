import gql from "graphql-tag";

const GET_CONFIG_DATA = gql`
query getConfigData($configType: String) {
  configs(filters: {configType: $configType}, sort:[ID_DESC]) {
    edges {
      node {
        id
        name
        config
        configType
        history(last:1) {
          edges {
            node {
              id
              mergeRequestObj {
                description
              }
            }
          }
        }
        createdAt
        isActiveFor {
          edges {
            node {
              campaignId
            }
          }
        }
      }
    }
  }
  campaignToConfig(filters:{campaignId: -1, configType: $configType}) {
    edges {
      node {
        campaignId,
        configObj {
          id
          name
          config
          configType
          history(last:1) {
            edges {
              node {
                id
                mergeRequestObj {
                  description
                }
              }
            }
          }
          createdAt
        }
      }
    }
  }
}
`;

const GET_CONFIG_HISTORY = gql`
query getConfigHistory($configId: Float) {
  configHistory (filters: {configId: $configId}, sort:CREATED_AT_DESC) {
    edges {
      node {
        id
        createdAt
        createdBy
        config
        configType
        mergeRequestObj {
          id
          description
        }
      }
    }
  }
  mergeRequests(filters: {configId: $configId, closed: false, merged: false}, sort:CREATED_AT_DESC) {
    edges {
      node {
        id
        description
        baseConfigHistoryId
        baseConfigHistoryObj {
          id
          config
        }
        name
        configType
        config
        reviews
        comments
        merged
        closed
        createdBy
        createdAt
        updatedAt
      }
    }
  }
}
`;

const GET_BIDDING_PARAMETERS = gql`
query getBiddingParameters {
  biddingParameters
}
`;

const GET_STATES = gql`
query getStates{
  states
}
`;

const COPY_CONFIG = gql`
mutation ($configType: String, $name: String, $configId: ID) {
  copyConfig(configType: $configType, name: $name, configId: $configId) {
    ok
    info
    errors
    config {
      id
      name
      config
      configType
    }
  }
}
`;

const CREATE_CONFIG = gql`
mutation ($configType: String, $name: String, $configJSON: JSONString) {
  createConfig(configType: $configType, name: $name, configJSON: $configJSON) {
    ok
    info
    errors
    config {
      id
      name
      config
      configType
    }
  }
}
`;

const UPDATE_CONFIG = gql`
mutation ($mergeRequestId: ID, $id: ID) {
  updateConfig(mergeRequestId: $mergeRequestId, id: $id) {
    ok
    errors
    info
    config {
      id
      name
      config
      configType
    }
  }
}
`;

const CREATE_MERGE_REQUEST = gql`
mutation ($config: JSONString, $configId: ID, $baseConfigId: ID, $description: String) {
  createMergeRequest(config: $config, configId: $configId, baseConfigId: $baseConfigId, description: $description) {
    ok
    errors
    info
    mergeRequest {
      id
      name
      description
      config
      configType
    }
  }
}
`;

const UPDATE_MERGE_REQUEST = gql`
mutation ($config: JSONString, $id: ID, $baseConfigId: ID) {
  updateMergeRequest(config: $config, id: $id, baseConfigId: $baseConfigId) {
    ok
    errors
    mergeRequest {
      id
      name
      config
      configType
    }
  }
}
`;

const ADD_MERGE_REQUEST_VERDICT = gql`
mutation ($comments: JSONString, $id: ID, $verdict: String) {
  addMergeRequestVerdict(comments: $comments, id: $id, verdict: $verdict) {
    ok
    info
    mergeRequest {
      id
      name
      config
      description
      merged
      closed
      configType
      reviews
      comments
    }
  }
}
`;

const VALIDATE_CONFIG = gql`
mutation ($config: JSONString, $configType: String, $part: String = "all") {
  validateConfig(config: $config, configType: $configType, part: $part) {
    ok
    info
    errors
  }
}
`;

const ACTIVATE_CONFIG = gql`
mutation ($configId: ID) {
  activateConfig(configId: $configId) {
    ok
    info
    errors
  }
}
`;

export {
  GET_CONFIG_DATA,
  COPY_CONFIG,
  CREATE_CONFIG,
  UPDATE_CONFIG,
  CREATE_MERGE_REQUEST,
  UPDATE_MERGE_REQUEST,
  ADD_MERGE_REQUEST_VERDICT,
  VALIDATE_CONFIG,
  ACTIVATE_CONFIG,
  GET_BIDDING_PARAMETERS,
  GET_CONFIG_HISTORY,
  GET_STATES,
};
