import React from 'react';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  Error,
  ThumbUp,
  Refresh,
  TrendingDown,
  TrendingUp,
  TrendingFlat,
} from '@material-ui/icons';
import { useQuery } from '@apollo/client';

// CSS
import './MetricsContainer.css';

// Queries
import { GET_METRICS_DATA } from '../../../graphql/queries/underwritingDashboard';

// Custom Functions
import {
  getRecentDates,
  parseCAPDataToAttemptData,
  splitArrayByDate,
  parseApprovalData,
  parseCAPDataToErrorData,
} from './metricsFunctions';

// Would use CSS file, but I want these colors to update depending on how the data is trending.
const useStyles = makeStyles({
  trendDataColor: {
    color: (props) => props.color,
  },
});

const MetricCard = ({ title, icon, data, reverseColor }) => {
  // Reverse color means that if trending downwards, it would be green, etc. upward will be red instead.
  const { currentData, trendData } = data;

  // Default, neutral.
  let trendIcon = <TrendingFlat className="flatTrendIcon" />;
  let trendColor = '#1976d2';

  if (trendData < 0) {
    // So data is going down...
    trendColor = reverseColor ? 'green' : 'red';
    trendIcon = <TrendingDown fontSize="small" style={{ color: trendColor }} />;
  } else if (trendData > 0) {
    // Going up...
    trendColor = reverseColor ? 'red' : 'green';
    trendIcon = <TrendingUp fontSize="small" style={{ color: trendColor }} />;
  }

  // This will set the color of the trendData text to reflect the color of the icon, must use class bc inline styles don't work with variables.
  const { trendDataColor } = useStyles({ color: trendColor });

  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item>
            <Typography variant="overline" className="metricCardTitle">
              {title}
            </Typography>
            <Typography variant="h3">{currentData}</Typography>
          </Grid>
          <Grid item className="metricCardIcon">
            {icon}
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item>{trendIcon}</Grid>
          <Grid item>
            <Typography variant="caption" className={trendDataColor}>
              {trendData}%
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption" className="metricCardTrendText">
              Since yesterday
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

// Only want it to run once so have it on the outside.
const [todayString, yesterdayString] = getRecentDates(false);

const MetricsContainer = () => {
  const { loading, error, data } = useQuery(GET_METRICS_DATA, {
    variables: { createdAt: yesterdayString },
  });

  const loadingData = {
    currentData: 'Loading...',
    trendData: 0,
  };

  if (loading)
    return (
      <MetricCard
        title="Loading..."
        icon={<Error className="errorIcon" />}
        data={loadingData}
      />
    );
  if (error) return `Error! ${error.message}`;

  // Calling it CAP array, will find error data from CAP.status
  const [todayCAPArray, yesterdayCAPArray] = splitArrayByDate(
    data.creditAppProcesses.edges,
    todayString
  );

  const errorData = parseCAPDataToErrorData(todayCAPArray, yesterdayCAPArray);
  const approvalData = parseApprovalData(todayCAPArray, yesterdayCAPArray);
  const attemptData = parseCAPDataToAttemptData(
    todayCAPArray,
    yesterdayCAPArray
  );

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <MetricCard
            title="Error Rate"
            icon={<Error className="errorIcon" />}
            data={errorData}
            reverseColor={true}
          />
        </Grid>
        <Grid item xs={4}>
          <MetricCard
            title="Approval Rate"
            icon={<ThumbUp className="approvalIcon" />}
            data={approvalData}
            reverseColor={false}
          />
        </Grid>
        <Grid item xs={4}>
          <MetricCard
            title="Number of Attempts Today"
            icon={<Refresh className="attemptIcon" />}
            data={attemptData}
            reverseColor={false}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default MetricsContainer;
