import React from 'react';
import {StepButton, Stepper, Step as MuiStep, CircularProgress} from "@material-ui/core";
import {
  PrimaryButtonLarge,
  PageButtonGroup,
  ButtonBox, TransparentButton, ArrowLeftCircled, SubmitButton
} from "../common/form_styles";
import { NavigateNext} from "@material-ui/icons";


class Step extends React.Component {
  render() {
    const { index, steps, onPageChange, isLoading } = this.props;

    const changePage = (page) => () => {
      if (onPageChange)
        onPageChange(() => {this.props.jumpToStep(page)})
      else
        this.props.jumpToStep(page);
    }

    return (
      <>

        <PageButtonGroup variant="contained" color="primary">
          <ButtonBox>
            {
              index !== 0 && (
                <TransparentButton
                  variant="contained"
                  onClick={changePage(index - 1)}
                  disabled={!!isLoading}
                >
                  <ArrowLeftCircled/>
                  Back
                </TransparentButton>
              )
            }
          </ButtonBox>
          <Stepper nonLinear activeStep={index} style={{width: '45%', padding: 0}}>
            {steps.map((step, i) => (
              <MuiStep key={step.name}>
                <StepButton
                  onClick={changePage(i)}
                  completed={index > i}
                  disabled={!!isLoading || (i - index) > 1}
                >
                  {step.name}
                </StepButton>
              </MuiStep>
            ))}
          </Stepper>
          <ButtonBox>
            {
              index !== steps.length - 1 && (
                <PrimaryButtonLarge
                  variant="contained"
                  color="primary"
                  onClick={changePage(index + 1)}
                  disabled={!!isLoading}
                  style={{width: 220, padding: '6px 10px'}}
                >
                  <NavigateNext/>
                  Validate and Continue
                </PrimaryButtonLarge>
              )
            }
          </ButtonBox>
        </PageButtonGroup>
        <div style={{minHeight: '1.5em', textAlign: 'center'}}>
          {isLoading && <><CircularProgress size="1em" /> Validation is in progress...</> }
        </div>
        {this.props.children}
        {
          this.props.bottomButton && (
            <div style={{textAlign: 'center'}}>
              <SubmitButton
                variant="contained"
                color="primary"
                onClick={changePage(index + 1)}
                disabled={!!isLoading}
              >Validate and Continue</SubmitButton>
              <div style={{marginTop: 10}}>
                {isLoading && <><CircularProgress size="1em" /> Validation is in progress...</> }
              </div>
            </div>
          )
        }
      </>
    );
  }
}

export default Step;
