import React from "react"
import {
  CANCEL_TESTING,
  GET_PRODUCTION_TESTS,
  GET_PRODUCTION_TESTS_ENQUEUED,
  TRIGGER_TESTING
} from "../../graphql/queries/productionTesting";
import {useMutation, useQuery} from "@apollo/client";
import {
  ButtonContainer,
  InputBase, PrimaryButtonLarge,
  SearchContainer,
  SearchIcon,
  SearchIconContainer,
  TableContainer
} from "../common/form_styles";
import AddIcon from "@material-ui/icons/Add";
import {Card, Grid, Typography} from "@material-ui/core";
import {UnControlled as CodeMirror} from "react-codemirror2";
import DataTable from "react-data-table-component";
import {cloneDeep} from "lodash";
import {Eye} from "react-feather";
import {Cancel} from "@material-ui/icons";


function ProductionTesting() {
  const { loading, error, data } = useQuery(GET_PRODUCTION_TESTS, {pollInterval: 5000,});
  const [cancelProductionTest, cancellationResult] = useMutation(
    CANCEL_TESTING,
    {
      refetchQueries: [
        { query: GET_PRODUCTION_TESTS }
      ]
    }
  );

  if (loading) {
    return <>Loading</>
  }

  if (error) {
    return <>Tests error</>
  }

  return (
    <>
      <Grid justify="space-between" container spacing={6} style={{ width: '80%', margin: '0 auto 0 auto' }}>
        <Grid item>
          <Typography variant="h3" display="inline">
            Production Testing
          </Typography>
        </Grid>
      </Grid>

      <TableContainer>
        <div style={{ height: '60px' }}>
          <SearchContainer>
            <SearchIconContainer>
              <SearchIcon />
            </SearchIconContainer>
            <InputBase
              placeholder="Search users by name, id"
              inputProps={{ 'aria-label': 'search' }}
            />
          </SearchContainer>
        </div>
        <Card>
          <DataTable
            columns={
              [
                {
                  name: 'ID',
                  selector: 'node.id',
                  sortable: true,
                  grow: 1
                },
                {
                  name: 'Info',
                  selector: 'node.configurationOverrides',
                  sortable: true,
                  grow: 3,
                  cell: (row, index, column, id) => {
                    const overrides = JSON.parse(row.node.configurationOverrides);
                    return <>
                      {
                        overrides && Object.keys(overrides).map(
                          (key) => <>Review #{overrides[key]} is used as {key} config<br /></>
                        )
                      }
                      {row.node.image && <>Code change tested</>}
                      {!row.node.image && Object.keys(overrides).length === 0 && <>Current code and configs tested</>}
                    </>
                  },
                },
                {
                  name: 'Status',
                  selector: 'node.status',
                  sortable: true,
                  grow: 1
                },
                {
                  name: 'Tests',
                  selector: 'node.testsInitiated',
                  sortable: true,
                  grow: 1
                },
                {
                  name: 'Tests Executed',
                  selector: 'node.testsExecuted',
                  sortable: true,
                  grow: 1
                },
                {
                  name: 'Workers',
                  selector: 'node.workersUsed',
                  sortable: true,
                  grow: 1
                },
                {
                  name: 'Summary',
                  selector: 'node.resultsSummary',
                  sortable: false,
                  grow: 4,
                  cell: (row, index, column, id) => {
                    const summary = JSON.parse(row.node.resultsSummary);
                    return <>
                      {
                        summary && row.node.testsExecuted && <>
                          {summary.tests_accepted} tests covered accepted customers<br />
                          {summary.tests_executed - summary.tests_accepted} tests covered denied customers<br />
                          {row.node.testsInitiated - summary.tests_executed} tests were skipped (LROs were not found, redis based deny or failure)<br />
                          {summary.tests_executed - summary.price_matched_tests} tests had different result price<br />
                          {summary.tests_executed - summary.filters_matched_tests} tests had different filter decisions<br />
                        </>
                      }
                      {
                        summary && summary.error
                      }
                    </>
                  },
                },
                {
                  name: 'Created',
                  selector: 'node.createdAt',
                  sortable: true,
                  grow: 2,
                  cell: (row, index, column, id) => {
                    return <>
                      {new Date(Date.parse(row.node.createdAt)).toLocaleString()}
                    </>
                  },
                },
                {
                  name: 'Last Updated',
                  selector: 'node.updatedAt',
                  sortable: true,
                  grow: 2,
                  cell: (row, index, column, id) => {
                    return <>
                      {new Date(Date.parse(row.node.updatedAt)).toLocaleString()}
                    </>
                  },
                },
                {
                  name: 'Created by',
                  selector: 'node.createdBy',
                  sortable: true,
                  grow: 2
                },
                {
                  name: 'Actions',
                  sortable: false,
                  grow: 2,
                  cell: (row, index, column, id) => {
                    return <>
                      {
                        ["running", "generated", "generating tests", "pending"].includes(row.node.status) && <PrimaryButtonLarge
                          variant="contained"
                          color="primary"
                          disabled={!!cancellationResult.loading}
                          onClick={() => {
                            cancelProductionTest({variables: {testId: row.node.id}})
                          }}
                          style={{width: "fit-content", padding: 5, margin: 5}}
                        >
                          <Cancel/>
                          Cancel Test
                        </PrimaryButtonLarge>
                      }
                    </>
                  },
                },
              ]
            }
            data={data.productionTests.edges}
          />
        </Card>
      </TableContainer>
    </>
  );

}

export default ProductionTesting;
