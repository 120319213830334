import React, {useState} from "react"
import styled from "styled-components"

import {
  CircularProgress,
  Grid, TableBody, TableHead, TableRow, TextField, Typography as MuiTypography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import "../Blacklists/main.css"

import {
  ActivateButton,
  PrimaryButtonLarge, AddIcon, ButtonContainer,
  Card, InputBase, SearchContainer, SearchIcon, SearchIconContainer,
  Table, TableCell,
  TableContainer, SubmitButton
} from "../common/form_styles";
import {JsonEditor as Editor} from "jsoneditor-react";
import ConfirmationModal from "../common/warning-modal";
import {Edit} from "@material-ui/icons";
import {cloneDeep} from "lodash";
import {useMutation, useQuery} from "@apollo/client";
import {
  ACTIVATE_CONFIG, COPY_CONFIG, CREATE_MERGE_REQUEST,
  GET_CONFIG_DATA, GET_CONFIG_HISTORY, UPDATE_MERGE_REQUEST, CREATE_CONFIG
} from "../../graphql/queries/config";
import ConfigHistory from "../Config/ConfigHistory";
import PaperModal from "../common/PaperModal";
import {Eye} from "react-feather";
import {CampaignFormErrorText} from "../common/form_styles";
const Typography = styled(MuiTypography)(spacing);


function DefaultConfigView({config}) {
  return <Editor
    value={config}
    mode={'view'}
  />
}


function ConfigList({configType, enableActivation, editComponent: EditConfig, createComponent: CreationForm, caption,
                      defaultConfig, additionalActions={}, creationMode, configDisplayComponent: ConfigView = DefaultConfigView}) {
  const [currentConfigId, setCurrentConfigId] = useState(null);
  const [showAddConfigModal, setShowAddConfigModal] = useState(false);
  const [showActivateConfirmationModal, setShowActivateConfirmationModal] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [configErrors, setConfigErrors] = useState(false);
  const actionStates = {};
  Object.keys(additionalActions).forEach((val) => {
    actionStates[val] = false;
  })
  const [showAction, setShowAction] = useState(actionStates);
  const [showCreateConfigModal, setShowCreateConfigModal] = useState(false);
  const [configName, setConfigName] = useState('');
  const [configFormSetup, setConfigFormSetup] = useState({
    id: null,
    name: '',
    config: defaultConfig,
    newConfig: defaultConfig,
    configHistoryId: null,
    description: ''
  });
  const [configHistorySetup, setConfigHistorySetup] = useState(null);
  const [createMergeRequest, mergeRequestResponse] = useMutation(CREATE_MERGE_REQUEST);
  const [createConfig, configResponse] = useMutation(COPY_CONFIG);
  const [createNewConfig, newConfigResponse] = useMutation(CREATE_CONFIG);
  const [updateMergeRequest, updateMergeRequestResponse] = useMutation(
    UPDATE_MERGE_REQUEST,
    {
      refetchQueries: [
        {query: GET_CONFIG_HISTORY, variables: {configId: currentConfigId}},
      ]
    }
  );
  const showConfigForm = (configObj, config=null, onSubmitType="create", additionalData={}) => {
    if (!config)
      config = cloneDeep(configObj.config);
    let onSubmit = (baseConfigObj, config, description) => {
      if (baseConfigObj.id)
        createMergeRequest({
          variables: {
            configId: baseConfigObj.id,
            config: JSON.stringify(config),
            baseConfigId: baseConfigObj.configHistoryId,
            description
          },
          refetchQueries: [
            {query: GET_CONFIG_HISTORY, variables: {configId: baseConfigObj.id}},
          ]
        }).then(r => {
          setConfigErrors(r);
          if (r.data && r.data.createMergeRequest && r.data.createMergeRequest.ok) {
            setShowAddConfigModal(false);
          }
        });
      else {

      }
    };
    if (onSubmitType === "update") {
      onSubmit = (baseConfigObj, config) => {
        return updateMergeRequest({
          variables: {
            id: additionalData.id,
            config: JSON.stringify(config),
            baseConfigId: additionalData.baseConfigHistoryId
          }
        }).then(r => {
          setConfigErrors(r);
          if (r.data && r.data.updateMergeRequest && r.data.updateMergeRequest.ok) {
            setShowAddConfigModal(false);
          }
        });
      }
    }

    setConfigFormSetup({
      id: configObj.id,
      name: configObj.name,
      config: configObj.config,
      newConfig: config,
      configHistoryId: configObj.configHistoryId,
      onSubmit: onSubmit,
      actionCaption: onSubmitType === "create" ? "Create merge request" : "Update merge request",
      description: additionalData.description
    });
    setShowAddConfigModal(true);
  }

  const { loading, error, data } = useQuery(GET_CONFIG_DATA, {variables: { configType }});

  const [activateConfig] = useMutation(
    ACTIVATE_CONFIG,
    {
      variables: {configId: currentConfigId},
      refetchQueries: [
        { query: GET_CONFIG_DATA, variables: { configType } }
      ]
    }
  );

  if (loading) {
    return <>Loading</>
  }

  if (error) {
    return <>Configs error</>
  }

  const activeConfigs = data.campaignToConfig.edges.map((item) => ({
    ...item.node.configObj,
    config: JSON.parse(item.node.configObj.config),
    isActive: true,
  }));

  const configs = activeConfigs.concat(
    data.configs.edges.map((item) => ({
      ...item.node,
      config: JSON.parse(item.node.config),
      isActive: false,
    })).filter((item) => item.isActiveFor.edges.length === 0)
  );

  const activeConfig = activeConfigs && activeConfigs[0];
  return (
    <>
      <ConfirmationModal
        headerText="Are you sure you want to activate this config?"
        contentText="This will affect production immediately"
        showModal={showActivateConfirmationModal}
        hideModalHandler={() => setShowActivateConfirmationModal(false)}
        cancelHandler={() => setShowActivateConfirmationModal(false)}
        submitHandler={() => {
          setShowActivateConfirmationModal(false);
          activateConfig();
        }}
      />

      <EditConfig
        open={showAddConfigModal}
        onClose={() => setShowAddConfigModal(false)}
        configType={configType}
        baseConfig={configFormSetup.config}
        initialConfig={configFormSetup.newConfig || configFormSetup.config}
        additionalInfo={configFormSetup}
        onSubmit={configFormSetup.onSubmit}
        isFetching={mergeRequestResponse.loading || updateMergeRequestResponse.loading}
        actionCaption={configFormSetup.actionCaption}
        validationErrors={JSON.parse(configErrors?.data?.createMergeRequest?.errors || configErrors?.data?.updateMergeRequest?.errors || "{}")}
      />

      {
        Object.keys(additionalActions).map(
          (name) => {
            let Component = additionalActions[name];
            return <Component
              show={showAction[name]}
              configInfo={configHistorySetup}
              onClose={() => setShowAction({...showAction, [name]: false})}
            />
          }
        )
      }

      <PaperModal open={showHistory} onClose={() => {setShowHistory(false)}}>
        <ConfigHistory
          config={configHistorySetup}
          openEditingForm={showConfigForm}
          onClose={() => {setShowHistory(false)}}
        />
      </PaperModal>

      <PaperModal blacklist={null} open={showCreateConfigModal} onClose={() => {setShowCreateConfigModal(false)}}>
        {
          creationMode === "new" && <CreationForm
            onSubmit={(name,config_json) => {
              createNewConfig({
                variables: {
                  configType: "blacklist",
                  name: name,
                  configJSON: JSON.stringify(config_json)
                },
              }).then(r => {
                if (r.data && r.data.createConfig && r.data.createConfig.ok) {
                  setShowCreateConfigModal(false);
                }
              })
            }
          }
            isFetching={newConfigResponse.loading}
            validationErrors={JSON.parse(newConfigResponse?.data?.createConfig?.errors || "{}")}
          />
        }
        {
          creationMode === "clone" && <>
            <Typography variant="h1" style={{textAlign: 'center', margin: '2rem 0'}}>Create empty
              configuration</Typography>
            <div style={{marginTop: '2rem', textAlign: 'center'}}>
              <div>Give your config a name:</div>
              <TextField
                error={configName === '' && "Name is required"}
                label="Config Name"
                required
                id="name"
                m={2}
                value={configName}
                onChange={event => {
                  setConfigName(event.target.value)
                }}
              />
              <div style={{textAlign: 'center'}}>
                <SubmitButton
                  variant="contained"
                  color="primary"
                  disabled={!!configResponse.loading || configName === ''}
                >Create config (won't affect production)</SubmitButton>
                <div style={{marginTop: 10}}>
                  {configResponse.loading && <><CircularProgress size="1em"/> Saving...</>}
                </div>
              </div>
            </div>
          </>
        }
      </PaperModal>

      <Grid justify="space-between" container spacing={6} style={{ width: '80%', margin: '0 auto 0 auto' }}>
        <Grid item>
          <Typography variant="h3" display="inline">
            {caption}
          </Typography>
        </Grid>
      </Grid>

      <TableContainer>
        <div style={{ height: '60px' }}>
          <SearchContainer>
            <SearchIconContainer>
              <SearchIcon />
            </SearchIconContainer>
            <InputBase
              placeholder="Search users by name, id"
              inputProps={{ 'aria-label': 'search' }}
            />
          </SearchContainer>
          <ButtonContainer>
            <PrimaryButtonLarge
              variant="contained"
              color="primary"
              onClick={() => {setShowCreateConfigModal(true)}}
            >
              <AddIcon />
              &nbsp; New configuration
            </PrimaryButtonLarge>
          </ButtonContainer>
        </div>
        <Card>
          <Table aria-label="simple table">
            <colgroup width="pixels">
              <col style={{ width: '100px' }} />
              <col style={{ width: '100px' }} />
              <col style={{ width: '100px' }} />
              <col style={{ width: '500px' }} />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell align="left">ID</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Created</TableCell>
                <TableCell align="left">Config</TableCell>
                <TableCell align="left">Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                configs.map((config) => (
                  <TableRow
                    hover
                    key={config.id + config.isActive}
                    style={{
                      backgroundColor: enableActivation && config.isActive ? 'lightgreen' : null,
                    }}
                  >
                    <TableCell align="left">{config.id}/{config.history.edges[0]?.node?.id}</TableCell>
                    <TableCell align="left">
                      <p>{config.name}</p>
                      {
                        config.history.edges[0]?.node?.mergeRequestObj?.description &&
                        <p><i>{config.history.edges[0]?.node?.mergeRequestObj?.description}</i></p>
                      }
                      {
                        enableActivation && (
                          !config.isActive ?
                          <ActivateButton
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setCurrentConfigId(config.id);
                              setShowActivateConfirmationModal(true);
                            }}
                          >
                            Activate
                          </ActivateButton> :
                          <i>This configuration is currently being used in production</i>
                        )
                      }
                    </TableCell>
                    <TableCell align="left">
                      {new Date(Date.parse(config.createdAt)).toLocaleString()}
                    </TableCell>
                    <TableCell align="left">
                      <ConfigView
                        config={config.config}
                      />
                    </TableCell>
                    <TableCell align="left" style={{width: 40}}>
                      <PrimaryButtonLarge
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          showConfigForm({
                            id: config.id,
                            name: config.name,
                            config: config.config,
                            configHistoryId: config.history.edges[0]?.node?.id
                          });
                        }}
                        style={{width: "fit-content", padding: 5, margin: 5}}
                      >
                        <Edit/>
                        Edit
                      </PrimaryButtonLarge>
                      <PrimaryButtonLarge
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setConfigHistorySetup({
                            ...config,
                            config: cloneDeep(config.config),
                            configHistoryId: config.history.edges[0]?.node.id
                          });
                          setShowHistory(true)
                        }}
                        style={{width: "fit-content", padding: 5, margin: 5}}
                      >
                        <Eye/>
                        History and Merge Requests
                      </PrimaryButtonLarge>
                      {
                        Object.keys(additionalActions).map(
                          (name) => {
                            return <PrimaryButtonLarge
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setConfigHistorySetup({
                                  ...config,
                                  config: cloneDeep(config.config),
                                  configHistoryId: config.history.edges[0]?.node.id
                                });
                                setShowAction({...showAction, [name]: true})
                              }}
                              style={{width: "fit-content", padding: 5, margin: 5}}
                            >
                              <Eye/> {name}
                            </PrimaryButtonLarge>
                          }
                        )
                      }
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </Card>
      </TableContainer>
    </>
  );

}

export default ConfigList;
