import React, {useEffect, useState} from 'react';
import {FormControl, TextField} from "../JsonLogicUI/component_styles";
import {InputAdornment} from "@material-ui/core";


const PriceInput = React.memo(({onChange, value: initialValue, label=""}) => {
  const [value, setValue] = useState(initialValue);
  useEffect(() => {setValue(initialValue);}, [initialValue])

  return <>
    <FormControl>
      {label}
      <TextField
        variant="outlined"
        onChange={(event) => {
          setValue(event.target.value ? parseFloat(event.target.value) : 0);
        }}
        onBlur={() => onChange(value)}
        type="number"
        value={value}
        InputProps={{
          endAdornment: <InputAdornment position='end'>$</InputAdornment>,
          inputProps: { min: 0 },
        }}
      />
    </FormControl>
  </>
})

export default PriceInput;
