import React, {useEffect, useState} from 'react';

import CancelConfirmationModal from '../common/warning-modal';
import {
  BiddingModalStyle as Modal,
  CancelIcon,
  ModalPaper
} from '../common/form_styles';
import BiddingSchema from "./Schema/BiddingSchema";
import BiddingNodes from "./Nodes/BiddingNodes";
import MultiPage from "../MultiPage/MultiPage";
import BiddingInputs from "./Inputs/BiddingInputs";
import ReviewChanges from "./ReviewChanges";
import {cloneDeep} from "lodash";
import {
  VALIDATE_CONFIG
} from "../../graphql/queries/config";
import {useMutation} from "@apollo/client";
import SwitchCaseInput from "./Inputs/SwitchCaseInput";


function renameNodeInSchema(schema, rename_from, rename_to) {
  if (typeof schema === 'object')
    return {
      type: schema.type,
      children: schema.children.map(
        (child) => renameNodeInSchema(child, rename_from, rename_to)
      ).filter((child) => child)
    };
  if (schema === rename_from)
    return rename_to;
  return schema;
}


const BiddingConfigModal = React.memo(
  ({configType, baseConfig, initialConfig, additionalInfo, onClose, open, onSubmit, isFetching, actionCaption}) => {
    if (!baseConfig)
      baseConfig = {bidding_inputs: {}, bidding_nodes: {}, bidding_schema: {type: 'stack', children: []}};

    const [showCancelConfirmationModal, setShowCancelConfirmationModal] = useState(false);
    const [config, setConfig] = useState(initialConfig);
    useEffect(() => {
      setConfig(initialConfig);
    }, [initialConfig]);

    const [validateConfig, validationResponse] = useMutation(VALIDATE_CONFIG);

    const isValidating = validationResponse.loading
    const errors = (validationResponse.data && JSON.parse(validationResponse.data.validateConfig.errors)) || {};

    let validationErrors = {};
    Object.keys(errors).forEach(
      (path) => {
        let field = path.split('.')[0];
        validationErrors[field] = validationErrors[field] || [];
        validationErrors[field] = validationErrors[field].concat(errors[path]);
      }
    )

    if (!config)
      return <>No config supplied</>

    const schema = config.bidding_schema;
    const nodes = config.bidding_nodes;
    const inputs = config.bidding_inputs;
    const initialNodes = baseConfig && baseConfig.bidding_nodes;

    const steps = [
      {
        name: 'Bidding Inputs',
        component: <BiddingInputs
          inputs={inputs}
          onChange={(inputs) => {
            setConfig({
              ...config,
              bidding_inputs: inputs
            });
          }}
          validationErrors={validationErrors}
        />,
        bottomButton: true,
        onPageChange: (action) => {
          validateConfig(
            {variables: {config: JSON.stringify(config), configType, part: "bidding_inputs"}}
          )
            .then(r => {
              if (r.data.validateConfig.ok) action();
            });
        }
      },
      {
        name: 'Bidding Tiers',
        component:
          <SwitchCaseInput
            inputs={inputs}
            setup={inputs.bidding_tier?.value}
            changeSetup={(setup) => {
              setConfig({
                ...config,
                bidding_inputs: {
                  ...inputs,
                  bidding_tier: {
                    ...inputs.bidding_tier,
                    value: setup
                  }
                }
              });
            }}
          />,
        bottomButton: true,
        onPageChange: (action) => {
          validateConfig(
            {variables: {config: JSON.stringify(config), configType, part: "bidding_inputs"}}
          )
            .then(r => {
              if (r.data.validateConfig.ok) action();
            });
        }
      },
      {
        name: 'Bidding Nodes',
        component: <BiddingNodes
          nodes={nodes}
          inputs={inputs}
          onChange={(nodes, rename_from, rename_to) => {
            const schema = rename_from ? renameNodeInSchema(
              config.bidding_schema,
              rename_from,
              rename_to
            ) : config.bidding_schema;

            setConfig({
              ...config,
              bidding_nodes: nodes,
              bidding_schema: schema
            });
          }}
          validationErrors={validationErrors}
          initialNodes={initialNodes}
        />,
        onPageChange: (action) => {
          validateConfig(
            {variables: {config: JSON.stringify(config), configType, part: "bidding_nodes"}}
          )
            .then(r => {
              if (r.data.validateConfig.ok) action();
            });
        }
      },
      {
        name: 'Bidding Schema',
        component: <BiddingSchema
          schema={schema}
          nodes={nodes}
          onChange={(schema) => {
            setConfig({
              ...config,
              bidding_schema: schema
            })
          }}
          validationErrors={validationErrors}
        />,
      },
      {
        name: 'Review Changes',
        component: (
          <ReviewChanges
            baseConfig={baseConfig}
            isFetching={isFetching}
            additionalInfo={additionalInfo}
            config={config}
            submitHandler={onSubmit}
            validationErrors={validationErrors}
            submitCaption={actionCaption}
          />
        )
      },
    ];

    return (
      <>
        <CancelConfirmationModal
          headerText="Are you sure you want to cancel?"
          contentText="All progress will be lost"
          showModal={showCancelConfirmationModal}
          hideModalHandler={() => setShowCancelConfirmationModal(false)}
          cancelHandler={() => setShowCancelConfirmationModal(false)}
          submitHandler={() => {
            setShowCancelConfirmationModal(false);
            setConfig(cloneDeep(baseConfig));
            onClose();
          }}
        />

        <Modal
          open={open}
          onClose={(isEdited) => {
              setShowCancelConfirmationModal(true);
          }}
        >
          <ModalPaper style={{height: '100%', overflow: 'scroll'}}>
            <CancelIcon onClick={() => {
                setShowCancelConfirmationModal(true)
            }}
            />
            {
              config && (
                <MultiPage
                  steps={steps}
                  hocValidationAppliedTo={[1, 2]}
                  isLoading={isValidating}
                  startAtStep={1}
                />
              )
            }
          </ModalPaper>
        </Modal>
      </>
    );
  }
);


export default BiddingConfigModal;
