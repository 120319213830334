import React, {useState} from 'react';

import {
  Paper,
  Modal,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";


function PaperModal ({open, onClose, children}) {
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        className="modal"
      >
        <Paper
          className="full-screen-paper"
        >
          <CancelIcon className="top-right-icon" onClick={onClose} />
          {children}
        </Paper>
      </Modal>
    </>
  );
}

export default PaperModal;
