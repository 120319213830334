import {ApolloClient, ApolloLink, InMemoryCache, concat, HttpLink} from "@apollo/client";
import R_ from "../routes";
import {onError} from "@apollo/client/link/error";
const { R } = R_;

const httpLink = new HttpLink({ uri: `${api_base}${R.graphql}` });

const authMiddleware = new ApolloLink(
  (operation, forward) => {
    operation.setContext({
      headers: {
        "Auth-Token": JSON.parse(localStorage.getItem('current_user')).tokenId,
      }
    });

    return forward(operation);
  }
)

const logoutLink = onError((error) => {
  if (error.networkError && error.networkError.statusCode === 401)
    window.location = '/users/login';
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(concat(logoutLink, authMiddleware), httpLink),
  fetchOptions: {
    mode: 'cors',
  },
});

export default client;