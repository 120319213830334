import React from 'react';
import { Card, Grid, Typography } from '@material-ui/core';
import { getSplittedDates, calculateCounts } from './ChartFunctions';
import CustomChart from './Chart';
import './ChartsContainer.css'

const ChartsContainer = ({
  typeOfChart,
  granularity,
  underwritingData,
  endDateTime,
  startDateTime,
}) => {
  if (!underwritingData) {
    // TODO implement better way for check
    return <>Be sure you have chosen date. Loading...</>;
  }
  const allApprovals = JSON.parse(underwritingData.underwritingData)?.approved_data;
  const allErrors = JSON.parse(underwritingData.underwritingData)?.errored_data;

  const dates = getSplittedDates(granularity, startDateTime, endDateTime);
  const datasetApproval = calculateCounts(dates, allApprovals);
  const datasetError = calculateCounts(dates, allErrors);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs>
          <Card>
            <Typography
              variant="h2"
              className="headerText"
            >
              Approvals over time
            </Typography>
            <div className="customChartContainer">
              <CustomChart
                label="Approvals"
                dataset={datasetApproval}
                startTime={startDateTime?.getTime()}
                endTime={endDateTime?.getTime()}
                granularity={granularity}
                lineColor="#D46261"
                type={typeOfChart.toLowerCase()}
              />
            </div>
            <Typography
              variant="h2"
              className="headerText"
            >
              Errors over time
            </Typography>
            <div className="customChartContainer">
              <CustomChart
                label="Errors"
                dataset={datasetError}
                startTime={startDateTime?.getTime()}
                endTime={endDateTime?.getTime()}
                granularity={granularity}
                lineColor="#000FF1"
                type={typeOfChart.toLowerCase()}
              />
            </div>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default ChartsContainer;
