export function getRecentDates(use_minutes) {

  // This a function to prepare any date into readable format for the DB. Also sets the hours, mins, seconds to 0, thus at 12:00am each day.
  function prepareString(date, use_minutes) {
    // Takes in the number and adds the 0 if it needs it.
    // Returns the number converted into a string.
    const pad = (number) => {
      if (number < 10) {
        return `0${number.toString()}`;
      } else {
        return number.toString();
      }
    };

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    if (use_minutes) {
      const hour = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();
      return `${year}-${month}-${day}T${hour}:${minutes}:${seconds}`;
    }
    return `${year}-${month}-${day}T00:00:00`;
  }

  // This will be in user timezone.
  const userToday = new Date();

  const userYesterday = new Date();
  // Subtract 24 hours to make it yesterday.
  userYesterday.setHours(userYesterday.getHours() - 24);

  return [
    prepareString(userToday, use_minutes),
    prepareString(userYesterday, use_minutes),
  ];
}

export function splitArrayByDate(allDataArray, dateString) {
  /**
   * Splits the attempt array into two seperate arrays by the date
   * This is used in container to get Today at 12am cst and Yesterday at 12am cst but can be used generally anywhere.
   */
  const todayAttemptIndex = allDataArray.findIndex(
    (attempt) => attempt.node.createdAt > dateString,
    dateString
  );
  // Get all attempts that happened today and stick into own separate array.
  const yesterdayAttemptsArray = allDataArray.slice(0, todayAttemptIndex);
  // Same thing but for tmr.
  const todayAttemptsArray = allDataArray.slice(todayAttemptIndex);

  return [todayAttemptsArray, yesterdayAttemptsArray];
}

export function parseCAPDataToErrorData(todayCAPArray, yesterdayCAPArray) {

  // Figure out today error amount.
  let todayErrorCount = 0;
  let yesterdayErrorCount = 0;

  todayCAPArray.forEach((record) => {
    if (record.node.status === 'error') {
      todayErrorCount++;
    }
  });

  yesterdayCAPArray.forEach((record) => {
    if (record.node.status === 'error') {
      yesterdayErrorCount++;
    }
  });

  // Figure out rate of change.
  const todayErrorRate = Math.round(
    (todayErrorCount / todayCAPArray.length) * 100
  );
  const yesterdayErrorRate = Math.round(
    (yesterdayErrorCount / yesterdayCAPArray.length) * 100
  );

  const changeTrend = todayErrorRate - yesterdayErrorRate;

  return {
    currentData: todayErrorRate.toString() + '%',
    trendData: changeTrend,
  };
}

export function parseCAPDataToAttemptData(todayCAPArray, yesterdayCAPArray) {

  // Calculate the change by ((Today-Yestday)/Today)*100
  const changeTrend =
    ((todayCAPArray.length - yesterdayCAPArray.length) / todayCAPArray.length) *
    100;

  return {
    currentData: todayCAPArray.length.toString() + ' attempts',
    trendData: Math.round(changeTrend),
  };
}

export function parseApprovalData(todayCAPArray, yesterdayCAPArray) {

  // Figure out today error amount.
  let todayApprovalCount = 0;
  let yesterdayApprovalCount = 0;

  todayCAPArray.forEach((record) => {
    if (record.node.status === 'complete_with_product') {
      todayApprovalCount++;
    }
  });

  yesterdayCAPArray.forEach((record) => {
    if (record.node.status === 'complete_with_product') {
      yesterdayApprovalCount++;
    }
  });

  // Figure out rate of change.
  const todayApprovalRate = Math.round(
    (todayApprovalCount / todayCAPArray.length) * 100
  );
  const yesterdayApprovalRate = Math.round(
    (yesterdayApprovalCount / yesterdayCAPArray.length) * 100
  );

  const changeTrend = todayApprovalRate - yesterdayApprovalRate;

  return {
    currentData: todayApprovalRate.toString() + '%',
    trendData: changeTrend,
  };
}
