import React, {Component} from 'react';
import {Typography} from "@material-ui/core";
import {ReactSortable} from "react-sortablejs";
import {Card} from "../../common/form_styles";
import {CardContent} from "../../common/warning-modal";
import Sequence from "./Sequence";
import Parallel from "./Parallel";
import {DragIndicator} from "@material-ui/icons";
import Node from "./Node";


class Element extends Component {
  constructor(props) {
    super(props);
    
    this.type = props.type || 'node';

    this.typeToComponent = {
      stack: (props) => <Sequence {...props} />,
      group: (props) => <Parallel {...props} />,
      node: (props) => <Node {...props} />
    };
  }

  removeChild = (i) => () => {
    let _children = [...this.props._children];
    _children.splice(i, 1);
    this.props.onRemove({
      name: this.props.name,
      type: this.props.type,
      _children: _children
    });
    this.props.onChange({
      name: this.props.name,
      type: this.props.type,
      _children: _children
    });
  }

  updateChild = (i, action='change') => (value) => {
    let _children = [...this.props._children];
    _children[i] = value;
    if (action === 'remove')
      this.props.onRemove({
        name: this.props.name,
        type: this.props.type,
        _children: _children
      });
    else
      this.props.onChange({
        name: this.props.name,
        type: this.props.type,
        _children: _children
      });
  }

  render = () => (
    <div className={this.props.name}
         style={{userSelect: 'none', justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
      {
        this.type in this.typeToComponent && this.typeToComponent[this.type]({
          _children: this.props._children,
          title: this.props.title,
          name: this.props.name,
          type: this.type,
          removeCurrent: this.props.removeCurrent,
          removeChild: this.removeChild,
          onChange: this.props.onChange,
          updateChild: this.updateChild,
          onRemove: this.props.onRemove,
          enableGraphics: this.props.enableGraphics
        })
      }
    </div>
  )
}

export default Element;
