import React from 'react';
import {Button, ButtonGroup,} from "@material-ui/core";
import {TightBlock} from "./component_styles";
import DropDown from "../../FormControl/DropDown";
import ABTestSetup from "./valueTypes/ABTestSetup";
import PercentageInput from "../../FormControl/PercentageInput";
import PriceInput from "../../FormControl/PriceInput";
import JSONInput from "../../FormControl/JSONInput";
import {DEFAULT_VALUES} from "./const";


const Action = React.memo(
  ({config, fieldTypes, actions, onChange, label=null}) => {
    return <TightBlock>
      <ButtonGroup variant="contained" color="primary" style={{paddingTop: 5}}>
        {label && <Button>{label}</Button>}
        <DropDown
          value={config.type}
          onChange={
            (type) => {
              if (type !== config.type) {
                let value = config.value;
                if (actions[type].valueType !== actions[config.type].valueType)
                  if (actions[type].options)
                    value = actions[type].options[0];
                  else
                    value = DEFAULT_VALUES[actions[type].valueType];
                onChange({...config, type, value})
              }
            }
          }
          options={Object.keys(actions)}
        />
        {
          actions[config.type].valueType === 'price' && (
            <PriceInput
              value={config.value}
              onChange={(price) => onChange({...config, value: price})}
            />
          )
        }
        {
          actions[config.type].valueType === 'percent' && (
            <PercentageInput
              value={config.value}
              onChange={(value) => onChange({...config, value})}
              format="decimal"
            />
          )
        }
        {
          actions[config.type].valueType === 'string' && (
            <DropDown
              value={config.value}
              onChange={(value) => onChange({...config, value})}
              options={actions[config.type].options}
            />
          )
        }
      </ButtonGroup>
      {
        actions[config.type].valueType === 'ab_test' && (
          <ABTestSetup
            value={config.value}
            onChange={(value) => onChange({...config, value})}
            fieldTypes={fieldTypes}
            actions={actions}
          />
        )
      }
      {
        actions[config.type].valueType === 'json' && (
          <JSONInput
            value={config.value}
            onChange={(value) => onChange({...config, value})}
          />
        )
      }
    </TightBlock>
  }
);

export default Action;
