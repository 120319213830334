import React, {useState} from 'react';
import {SubmitButton} from "../common/form_styles";
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography
} from "@material-ui/core";
import 'react-diff-view/style/index.css';
import DiffChecker from "../DiffChecker";
import {Check, FiberManualRecord, NoteAdd} from "@material-ui/icons";
import {useMutation} from "@apollo/client";
import {GET_CONFIG_HISTORY, ADD_MERGE_REQUEST_VERDICT} from "../../graphql/queries/config";
import BiddingConfigModal from "../Bidding/BiddingConfigModal";
import PaperModal from "../common/PaperModal";
import ConfigHistory from "./ConfigHistory";
import ProductionTestForm from "../ProductionTesting/ProductionTestForm";


const ConfigReview = ({
   configType,
   baseConfig,
   newConfig,
   comments: initialComments,
   reviews: initialReviews,
   reviewId,
   configId,
   onClose,
 }) => {
  const [comments, setComments] = useState(initialComments);
  const [reviews, setReviews] = useState(initialReviews);
  const [verdict, setVerdict] = useState("approve");
  const [showCreateProductionTest, setShowCreateProductionTest] = useState(false);
  const [updateMergeRequest, mergeRequestResponse] = useMutation(
    ADD_MERGE_REQUEST_VERDICT,
    {
      variables: {id: reviewId, comments: JSON.stringify(comments), verdict},
      refetchQueries: [
        {query: GET_CONFIG_HISTORY, variables: {configId}},
      ]
    }
  );
  const isFetching = mergeRequestResponse.loading;

  let user = localStorage.getItem('current_user');
  user = JSON.parse(user);

  const addComment = line => text => {
    setComments({
      ...comments,
      [line]: [
        ...(comments[line] || []),
        {
          author: user.profileObj.name,
          photo: user.profileObj.imageUrl,
          message: text,
          pending: true
        }
      ]
    });
  }

  return (
    <>
      <PaperModal open={showCreateProductionTest} onClose={() => {setShowCreateProductionTest(false)}}>
        <ProductionTestForm
          configOverrides={{[configType]: reviewId}}
        />
      </PaperModal>

      <Typography variant="h1" style={{textAlign: 'center', margin: '2rem 0'}}>Review Configuration</Typography>
      <div style={{display: 'flex'}}>
        <div style={{maxHeight: 400, overflow: 'scroll'}}>
          <DiffChecker
            oldValue={baseConfig}
            newValue={newConfig}
            comments={comments}
            addComment={addComment}
          />
        </div>
        <div style={{whiteSpace: 'nowrap'}}>
          <h3>Reviews:</h3>
          {
            reviews.map(
              (item) => <div>
                {item.status === "approve" && <Check htmlColor="green" />}
                {item.status === "pending" && <FiberManualRecord htmlColor="orange" />}
                {item.status === "comment" && <FiberManualRecord htmlColor="orange" />}
                {item.status === "request changes" && <NoteAdd htmlColor="red" />}
                {item.email}
              </div>
            )
          }
        </div>
      </div>

      <FormControl component="fieldset">
        <FormLabel component="legend">Review verdict</FormLabel>
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={verdict}
          onChange={(event) => {setVerdict(event.target.value);}}
        >
          <FormControlLabel value="approve" control={<Radio />} label="Approve" />
          <FormControlLabel value="request changes" control={<Radio />} label="Request changes" />
          <FormControlLabel value="comment" control={<Radio />} label="Comment" />
        </RadioGroup>
      </FormControl>

      <div style={{textAlign: 'center'}}>
        <SubmitButton
          variant="contained"
          color="primary"
          onClick={() => {
            setShowCreateProductionTest(true);
          }}
        >Test Using Production Data</SubmitButton>
        <SubmitButton
          variant="contained"
          color="primary"
          onClick={() => {
            updateMergeRequest().then(r => {
              if (r.data && r.data.addMergeRequestVerdict && r.data.addMergeRequestVerdict.ok) {
                setComments(JSON.parse(r.data.addMergeRequestVerdict.mergeRequest.comments));
                setReviews(JSON.parse(r.data.addMergeRequestVerdict.mergeRequest.reviews));
                onClose();
              }
            });
          }}
          disabled={!!isFetching}
        >Submit review and comments</SubmitButton>
        <div style={{marginTop: 10}}>
          {isFetching && <><CircularProgress size="1em" /> Saving...</> }
        </div>
      </div>
    </>
  )
}

export default ConfigReview;
