import React from 'react';
import {uniqueId} from 'lodash';
import sha from 'sha1';
import '../index.css'
import {parseDiff} from "react-diff-view";
import {diffLines, formatLines} from "unidiff";
import DiffView from "../DiffView";
import stringify from "json-stringify-pretty-compact";

const fakeIndex = () => sha(uniqueId()).slice(0, 9);

const DiffChecker = React.memo((props) => {
  const {oldValue, newValue, comments, addComment} = props;
  const oldValueStr = stringify(oldValue);
  const newValueStr = stringify(newValue);
  const diff = formatLines(diffLines(oldValueStr, newValueStr), {context: 3});
  if (!diff) {
    return null;
  }

  const segments = [
    'diff --git a/a b/b',
    `index ${fakeIndex()}..${fakeIndex()} 100644`,
    diff,
  ];
  const [file] = parseDiff(segments.join('\n'), {nearbySequences: 'zip'});

  return (
    <div>
      {
        file && (
          <>
            <DiffView
              key={sha(diff) + (oldValueStr ? sha(oldValueStr) : '')}
              type={file.type}
              hunks={file.hunks}
              oldSource={oldValueStr}
              comments={comments}
              addComment={addComment}
            />
          </>
        )
      }
    </div>
  );
});

export default DiffChecker;
