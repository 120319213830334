import React, { useState } from "react";

import JSONInput from "react-json-editor-ajrm/es";
import locale from "react-json-editor-ajrm/locale/en";
import "jsoneditor-react/es/editor.min.css";

import {
  AddCampaignFormPaper,
  GreenSwitch,
  CancelIcon,
  ModalCirclularProgress,
  SubmitButton,
  FormControlLabel,
  TextField,
  CancelButton,
} from "../common/form_styles";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

function CampaignForm({
   campaignData, createMode, channels, tags, config_files, lead_providers, brands, onSubmit, onClose=null, isFetching=false
}) {
  const [enableSwitch, setEnableSwitch] =               useState(true);
  const [campaignId, setCampaignId] =                   useState(!createMode ? campaignData.id : "");
  const [name, setName] =                               useState(!createMode ? campaignData.name : "");
  const [topPricePoint, setTopPricePoint] =             useState(!createMode ? campaignData.topPricePoint : 125);
  const [cappedBidAmount, setCappedBidAmount] =         useState(!createMode ? campaignData.cappedBidAmount : 125);
  const [channel, setChannel] =                         useState(!createMode ? campaignData.channel : "leads");
  const [tag, setTag] =                                 useState(!createMode ? JSON.parse(campaignData.tags) : []);
  const [configFile, setConfigFile] =                   useState(!createMode ? campaignData.configFile : "leads");
  const [lpID, setLpID] =                               useState(!createMode ? campaignData.leadProviderId : 15);
  const [config, setConfig] =                           useState(!createMode ? campaignData.config : {});
  const [shortFormConfigFile, setShortFormConfigFile] = useState(!createMode ? campaignData.shortFormConfigFile : "short_form");
  const [brandId, setBrandId] =                         useState(!createMode ? campaignData.brandId : null);


  const submitHandler = () => {
    let data = {}
    if (!createMode){
      if (name !== campaignData.name) data["name"] = name
      if (config !== campaignData.config) data["config"] = config
      if (lpID !== campaignData.leadProviderId) data["lp_id"] = lpID
      if (JSON.stringify(tag) !== campaignData.tags) data["tags"] = tag
      if (channel !== campaignData.channel) data["channel"] = channel
      if (configFile !== campaignData.configFile) data["config_file"] = configFile
      if (shortFormConfigFile !== campaignData.shortFormConfigFile) data["short_form_config_file"] = shortFormConfigFile
      if (topPricePoint !== campaignData.topPricePoint) data["top_price_point"] = topPricePoint
      if (cappedBidAmount !== campaignData.cappedBidAmount) data["capped_bid_amount"] = cappedBidAmount
      if (brandId !== campaignData.brandId) data["brand_id"] = brandId
    }else{
      data = {
        name: name,
        campaign_id: campaignId,
        config: config,
        lp_id: lpID,
        channel: channel,
        tags: tag,
        config_file: configFile,
        short_form_config_file: shortFormConfigFile,
        top_price_point: topPricePoint,
        capped_bid_amount: cappedBidAmount,
        is_active: enableSwitch,
        brand_id: brandId
      };
    }

    onSubmit(data);
  };

  const handleChannelSelection = (option, actionMeta) => {
    switch (actionMeta.action) {
      case 'select-option':
        setChannel(option.value)
        return;
      case 'create-option':
        setChannel(option.value)
        return;
      default:
        return;
    }
  }

  const handleConfigFileSelection = (option, actionMeta) => {
    switch (actionMeta.action) {
      case 'select-option':
        setConfigFile(option.value)
        return;
      case 'create-option':
        setConfigFile(option.value)
        return;
      default:
        return;
    }
  }

  const handleShortFormConfigFileSelection = (option, actionMeta) => {
    switch (actionMeta.action) {
      case 'select-option':
        setShortFormConfigFile(option.value)
        return;
      case 'create-option':
        setShortFormConfigFile(option.value)
        return;
      default:
        return;
    }
  }

  const handleTagsSelection = (option, actionMeta) => {
    switch (actionMeta.action) {
      case 'select-option':
        option = option.map((o) => o.value)
        setTag(option)
        return;
      case 'create-option':
        option = option.map((o) => o.value)
        setTag(option)
        return;
      case 'remove-value':
        if (option === null)
          option = []
        else
          option = option.map((o) => o.value)
        setTag(option)
        return;
      default:
        return;
    }
  }

  return (
    <>
      <AddCampaignFormPaper mt={3}>
        <form noValidate autoComplete="off">
          {
            createMode &&
              <CancelIcon
                onClick={() => onClose(true)}
              />
          }
          {
            createMode &&
              <FormControlLabel
                control={
                  <GreenSwitch
                    checked={enableSwitch}
                    onChange={(event) => setEnableSwitch(event.target.checked)}
                  />
                }
                label="Enable/Disable"
              />
          }
          <div>
            <TextField
              required
              id="name"
              m={2}
              placeholder="Campaign Name"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </div>
          {
            createMode && channels && (
              <div>
                <TextField
                  required
                  m={2}
                  placeholder="Campaign ID"
                  type="number"
                  value={campaignId}
                  onChange={(event) => setCampaignId(event.target.value)}
                />
              </div>
            )
          }
          {
            channels && (
              <div style={{ marginTop: "30px", width: "206px" }}>
                <label>
                  Channel:
                  <CreatableSelect
                    onChange={handleChannelSelection}
                    onInputChange={handleChannelSelection}
                    options={Object.values(channels).map((c) => ({
                      value: c,
                      label: c,
                    }))}
                    value={{ value: channel, label: channel }}
                  />
                </label>
              </div>
            )
          }
          {
            lead_providers && (
              <div style={{ marginTop: "30px", width: "206px" }}>
                <label>
                  Lead Provider:
                  <Select
                    onChange={(option) => setLpID(option.value)}
                    options={Object.keys(lead_providers).map((id) => ({
                      value: id,
                      label: lead_providers[id],
                    }))}
                    value={{ value: lpID, label: lead_providers[lpID] }}
                  />
                </label>
              </div>
            )
          }
          {
            config_files && (
              <div style={{ marginTop: "30px", width: "206px" }}>
                <label>
                  Config File:
                  <CreatableSelect
                    onInputChange={handleConfigFileSelection}
                    onChange={handleConfigFileSelection}
                    options={Object.values(config_files).map((c) => ({
                      value: c,
                      label: c ? c : "--empty--",
                    }))}
                    value={{ value: configFile, label: configFile ? configFile : "--empty--" }}
                  />
                </label>
              </div>
            )
          }
          {
            config_files && (
              <div style={{ marginTop: "30px", width: "206px" }}>
                <label>
                  Short Form Config File:
                  <CreatableSelect
                    onInputChange={handleShortFormConfigFileSelection}
                    onChange={handleShortFormConfigFileSelection}
                    options={Object.values(config_files).map((c) => ({
                      value: c,
                      label: c ? c : "--empty--",
                    }))}
                    value={{ value: shortFormConfigFile, label:  shortFormConfigFile ? shortFormConfigFile : "--empty--" }}
                  />
                </label>
              </div>
            )
          }
          {
            brands && shortFormConfigFile !== null && (
              <div style={{ marginTop: "30px", width: "206px" }}>
                <label>
                  <b>Short form campaign must have a brand</b><br/>
                  Brands:
                  <Select
                    onChange={(option) => setBrandId(option.value)}
                    options={Object.keys(brands).map((id) => ({
                      value: id,
                      label: brands[id],
                    }))}
                    value={{ value: brandId, label: brands[brandId] }}
                  />
                </label>
              </div>
            )
          }
          <div style={{ marginTop: "30px", width: "206px" }}>
            <label>
              Tags:
              <CreatableSelect
                isMulti
                onChange={handleTagsSelection}
                onInputChange={handleTagsSelection}
                options={tags.map((t) => ({
                  value: t,
                  label: t,
                }))}
                value={ tag.map((t) => ({
                  value: t,
                  label: t,
                }))}
              />
            </label>
          </div>
          <div style={{ marginTop: "30px", width: "206px" }}>
            <label>
              Top Price Point:
              <TextField
                required
                placeholder="Top Price Point"
                type="number"
                value={topPricePoint}
                onChange={(event) => setTopPricePoint(event.target.value)}
              />
            </label>
          </div>
          <div style={{ marginTop: "30px", width: "206px" }}>
            <label>
              Capped Bid Amount:
              <TextField
                required
                placeholder="Capped Bid Amount"
                type="number"
                value={cappedBidAmount}
                onChange={(event) => setCappedBidAmount(event.target.value)}
              />
            </label>
          </div>
          <div
            style={{
              border: "solid 1px grey",
              display: "table",
              marginTop: "30px",
            }}
          >
            <JSONInput
              id="a_unique_id"
              label="Steps"
              locale={locale}
              height="350px"
              onChange={(event) => setConfig(event.jsObject)}
              theme="light_mitsuketa_tribute"
            />
          </div>
        </form>
        <SubmitButton
          variant="contained"
          color="primary"
          onClick={submitHandler}
        >
          Submit
        </SubmitButton>
        {
          createMode &&
            <CancelButton
              variant="contained"
              color="primary"
              onClick={() => onClose(true)}
            >
              Close
            </CancelButton>
        }
        {isFetching && <ModalCirclularProgress />}
      </AddCampaignFormPaper>
    </>
  );
}

export default CampaignForm;
