import React, {useState, useRef} from "react";
import {useQuery} from "@apollo/client";
import AddBox from "@material-ui/icons/AddBox";
import MaterialTable from "material-table";
import {customFilterWrapper, tableIcons} from "../Utilities"
import {GET_RULES_TO_PROMOTIONAL_OFFERS} from "../../graphql/queries/promotionalOffers";
import AddRulesToPromoOfferModal from "./AddRulesToPromoOffersModal";
import UpdateRulesToPromoOffersSection from "./UpdateRulesToPromoOffersSection";


function RulesToPromotionalOffers() {
  const [showAddRulesToPromoOffersModal, setShowAddRulesToPromoOffersModal] = useState(false);
  const tableRef = useRef()

  const {
    loading: rulesToPromoOffersLoading,
    error: rulesToPromoOffersError,
    data: rulesToPromoOffersData,
    refetch: rulesToPromoOffersRefetch,
  } = useQuery(GET_RULES_TO_PROMOTIONAL_OFFERS);

  let dataForTable = [];
  let allRulesToPromoOffers = {};

  if (rulesToPromoOffersData) {
    let sortedData = [...rulesToPromoOffersData.rulesToPromotionalOffers.edges].sort((a, b) =>
      a.node.promotionalOfferId - b.node.promotionalOfferId
    )

    sortedData.forEach(item => {
      allRulesToPromoOffers[item.node.id] = item.node;

      dataForTable.push({
        id: item.node.id,
        campaignId: item.node.campaignId,
        state: item.node.state,
        promotionalOfferId: item.node.promotionalOfferId,
      });
    });
  }


  return (
    <>
      <AddRulesToPromoOfferModal
        open={showAddRulesToPromoOffersModal}
        setOpen={setShowAddRulesToPromoOffersModal}
        refetch={rulesToPromoOffersRefetch}
      />
      <div className="alignCenter">
        <h2>Please read through this confluence page (
          <a href="https://creditninja.atlassian.net/wiki/spaces/EN/pages/3293052957/LeadsUI+Promotional+Offer+Definitions+Rules+To+Promotional+Offers+pages"
             rel="noopener noreferrer"
             target="_blank">
            LeadsUI Rules To Promotional offers page
          </a>
          )  before you take any actions.
        </h2>
      </div>
      <MaterialTable
        tableRef={tableRef}
        title="Rules To Promotional Offers"
        icons={tableIcons}
        columns={[
          { title: "Campaign Id", field: "campaignId", customFilterAndSearch: customFilterWrapper("campaignId") },
          { title: "State", field: "state", customFilterAndSearch: customFilterWrapper("state") },
          { title: "Promotional Offer Def Id", field: "promotionalOfferId", customFilterAndSearch: customFilterWrapper("promotionalOfferId") },
        ]}
        data={dataForTable}
        options={{
          filtering: true,
          paging: false,
        }}
        detailPanel={ rowData => {
          return(
            <UpdateRulesToPromoOffersSection
              refetch={rulesToPromoOffersRefetch}
              ruleToPromoOfferData={allRulesToPromoOffers[rowData.id]}
              closeUpdateSection={() =>
                tableRef.current.onToggleDetailPanel(
                  [rowData.tableData.id],
                  tableRef.current.props.detailPanel
                )
              }
            />
          )
        }}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        actions={[
          {
            tooltip: 'Add a new rule to promotional offer',
            icon: AddBox,
            isFreeAction: true,
            onClick: () => (
              setShowAddRulesToPromoOffersModal(true)
            )
          }
        ]}
      />
    </>
  );
}

export default RulesToPromotionalOffers;
