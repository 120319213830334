import React, {useEffect, useState} from 'react';
import 'brace/mode/json';
import 'brace/theme/github';
import {Button, ButtonGroup, Card, FormControl, IconButton, TextField} from "@material-ui/core";
import JsonLogicExpression from "../../JsonLogicUI/JsonLogicExpression";
import "../main.css"
import {ReactSortable} from "react-sortablejs";
import {CodeOutlined, DragIndicator, ExpandLess, ExpandMore, RemoveCircle, ViewQuilt} from "@material-ui/icons";
import {JsonEditor as Editor} from "jsoneditor-react";
import ace from "brace";
import 'brace/mode/json';
import 'brace/theme/github';
import {CardHeader} from "../Nodes/component_styles";
import DragIcon from "../../common/DragIcon";


function SwitchCaseInput({inputs, setup: initialSetup, changeSetup}) {
  let [setup, setSetup] = useState(initialSetup);
  let [mode, setMode] = useState("visual");

  let fieldTypes = {};
  Object.keys(inputs).forEach(
    (input_name) => {
      if (input_name !== "bidding_tier")
        fieldTypes[input_name] = "string"
    }
  )

  return (
    <Card className="card fit-content centered">
      <CardHeader
        title="Bidding Tier"
        action={
          <>
            {
              mode === 'json' && (
                <IconButton aria-label="settings" onClick={() => {setMode('visual')}}>
                  <ViewQuilt />
                </IconButton>
              )
            }
            {
              mode === 'visual' && (
                <IconButton aria-label="settings" onClick={() => {setMode('json')}}>
                  <CodeOutlined />
                </IconButton>
              )
            }
          </>
        }
      />
      <p>The value of the first conditional block that will have its requirement satisfied will be used.</p>
      {
        mode === 'visual' && (
          <>
            <ReactSortable
              list={setup}
              animation={200}
              delayOnTouchStart={true}
              className="fit-content"
              setList={
                (newState) => {
                  const data = newState.map((item) => ({requirement: item.requirement, value: item.value}));
                  if (JSON.stringify(data) !== JSON.stringify(setup)) {
                    setSetup(data);
                    changeSetup(data);
                  }
                }
              }
            >
              {
                setup.map((item, i) => (
                  <div key={item}>
                    <Button
                      className="full-width"
                      onClick={() => {
                        let newSetup = [...setup.slice(0, i), {requirement: true, value: ""}, ...setup.slice(i)];
                        setSetup(newSetup);
                        changeSetup(newSetup);
                      }}
                    >+ Add Conditional Block</Button>
                    <Card className="margin-around full-width relative overflow-inherit card-transparent">
                      <RemoveCircle
                        className="remove-button"
                        onClick={() => {
                          let newSetup = [...setup.slice(0, i), ...setup.slice(i + 1)];
                          setSetup(newSetup);
                          changeSetup(newSetup);
                        }}
                      />
                      <DragIcon/>
                      <ButtonGroup variant="contained" color="primary" className="button-group">
                        <Button className="button-fit-content">IF</Button>
                        <JsonLogicExpression
                          key={JSON.stringify(item.requirement) + i.toString()}
                          expression={item.requirement}
                          fieldTypes={fieldTypes}
                          onExpressionChange={(event) => {
                            let newSetup = [...setup];
                            newSetup[i].requirement = event;
                            changeSetup(newSetup);
                          }}
                        />
                      </ButtonGroup>
                      <div>
                        <ButtonGroup variant="contained" color="primary" className="button-group">
                          <Button>THEN</Button>
                          <FormControl>
                            <TextField
                              variant="outlined"
                              onChange={(event) => {
                                let newSetup = [...setup];
                                newSetup[i].value = event.target.value;
                                setSetup(newSetup);
                              }}
                              onBlur={() => {changeSetup(setup)}}
                              value={item.value}
                            />
                          </FormControl>
                        </ButtonGroup>
                      </div>
                    </Card>
                  </div>
                ))
              }
            </ReactSortable>
            <Button
              className="full-width"
              onClick={() => {
                let newSetup = [...setup, {requirement: true, value: ""}];
                setSetup(newSetup);
                changeSetup(newSetup);
              }}
            >+ Add Conditional Block</Button>
          </>
        )
      }
      {
        mode === 'json' && (
          <div className="fit-content json-block">
            <Editor
              value={setup}
              onChange={setSetup}
              mode="code"
              htmlElementProps={{style: {height: 500}}}
              ace={ace}
              theme="ace/theme/github"
            />
          </div>
        )
      }
    </Card>
  )
}

export default React.memo(SwitchCaseInput);
