import React, {useState} from 'react';
import {CampaignFormErrorText, GreenSwitch, SubmitButton} from "../common/form_styles";
import {
  CircularProgress,
  FilledInput,
  FormControl,
  FormControlLabel,
  InputLabel,
  TextField,
  Typography
} from "@material-ui/core";
import 'react-diff-view/style/index.css';
import DiffChecker from "../DiffChecker";
import _ from 'lodash';
import stringify from "json-stringify-pretty-compact";


const ReviewChanges = React.memo(
  ({
     baseConfig,
     isFetching,
     additionalInfo,
     config,
     validationErrors,
     submitHandler,
     submitCaption
   }) => {
    const [description, setDescription] = useState(additionalInfo.description || '');
    const baseConfigStr = stringify(baseConfig);
    const newConfigStr = stringify(config);

    return (
      <div style={{padding: "0 10%", minWidth: 800}}>
        <Typography variant="h1" style={{textAlign: 'center', margin: '2rem 0'}}>Confirm Configuration</Typography>
        <div style={{maxHeight: 400, overflow: 'scroll'}}>
          <DiffChecker
            oldValue={baseConfig}
            newValue={config}
          />
        </div>

        {
          !(
            _.isEmpty(validationErrors.bidding_inputs) &&
            _.isEmpty(validationErrors.bidding_nodes) &&
            _.isEmpty(validationErrors.bidding_schema)
          ) && (
            <CampaignFormErrorText>
              {
                Object.keys(validationErrors).map((key) => (
                  <div>
                    <p>There are some errors in {key}:</p>
                    <ul>
                      {
                        Object.values(validationErrors[key]).map((val) => (
                          <li>{val}</li>
                        ))
                      }
                    </ul>
                  </div>
                ))
              }
            </CampaignFormErrorText>
          )
        }

        <p>
          Please leave a short description of a change you are trying to make. Link to the ticket or other source
          will be also useful for others to refer to.
        </p>
        <FormControl fullWidth variant="filled">
          <InputLabel htmlFor="filled-adornment-amount">Description</InputLabel>
          <FilledInput
            value={description}
            onChange={(event) => {
              setDescription(event.target.value);
            }}
          />
        </FormControl>

        <div style={{textAlign: 'center'}}>
          <SubmitButton
            variant="contained"
            color="primary"
            onClick={() => {submitHandler(additionalInfo, config, description)}}
            disabled={!!isFetching || baseConfigStr === newConfigStr}
          >{submitCaption}</SubmitButton>
          <div style={{marginTop: 10}}>
            {isFetching && <><CircularProgress size="1em" /> Saving...</> }
            {baseConfigStr === newConfigStr && <>There are no changes detected. Please turn back and change something to proceed.</>}
          </div>
        </div>
      </div>
    )
  }
)

export default ReviewChanges;
