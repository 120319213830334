import React from 'react';
import DropDown from "../../../FormControl/DropDown";
import TextInput from "../../../FormControl/TextInput";
import PercentageInput from "../../../FormControl/PercentageInput";
import Action from "../Action";
import {ReactSortable} from "react-sortablejs";
import {Button, Card} from "@material-ui/core";
import {RemoveCircle} from "@material-ui/icons";
import DragIcon from "../../../common/DragIcon";


const ABTestSetup = React.memo(({onChange, value, fieldTypes, actions}) => {
  const updateVariants = (newState) => {
    const newVariants = newState.map((item) => ({action: item.action, name: item.name, weight: item.weight}));
    if (JSON.stringify(newVariants) !== JSON.stringify(value.variants))
      onChange({...value, variants: newVariants});
  }

  return <>
    <div>
      <TextInput
        label="A/B test name:"
        value={value.ab_test_name}
        onChange={(name) => onChange({...value, ab_test_name: name})}
      />
    </div>
    Variants:
    <div className="ab-test-variant">
      <ReactSortable
        list={value.variants}
        animation={200}
        delayOnTouchStart={true}
        className="fit-content"
        setList={updateVariants}
      >
        {
          value.variants.map(
            (item, i) => <div className="ab-test-variant">
              <DragIcon/>
              {
                value.variants.length > 1 && <RemoveCircle
                  className="remove-button"
                  onClick={() => {
                    const newVariants = [
                      ...value.variants.slice(0, i),
                      ...value.variants.slice(i + 1)
                    ];
                    onChange({
                      ...value,
                      variants: newVariants,
                      default: value.default === item.name ? newVariants[0].name : value.default
                    })
                  }}
                />
              }
              <TextInput
                label="Variant name (will be displayed in testing logs):"
                value={item.name}
                onChange={(name) => onChange({
                  ...value,
                  variants: [
                    ...value.variants.slice(0, i),
                    {...value.variants[i], name},
                    ...value.variants.slice(i + 1)
                  ],
                  default: value.default === value.variants[i].name ? name : value.default
                })}
              />
              <PercentageInput
                label="Weight:"
                value={item.weight}
                onChange={(weight) => onChange({
                  ...value,
                  variants: [
                    ...value.variants.slice(0, i),
                    {...value.variants[i], weight},
                    ...value.variants.slice(i + 1)
                  ]
                })}
                format="plain"
              />
              <Action
                label="Action"
                config={item.action}
                fieldTypes={fieldTypes}
                actions={{...actions, ab_test_node: null}}
                onChange={(action) => onChange({
                  ...value,
                  variants: [
                    ...value.variants.slice(0, i),
                    {...value.variants[i], action},
                    ...value.variants.slice(i + 1)
                  ]
                })}
              />
            </div>
          )
        }
      </ReactSortable>
      <Button
        className="full-width"
        onClick={() => updateVariants([
          ...value.variants,
          {action: {type: "price_cap", value: 0.01}, name: "variant" + value.variants.length.toString(), weight: 0}
        ])}
      >+ Add Variant</Button>
    </div>

    <DropDown
      label="Default variant (is will be used for leads that are not taking part in testing or in cases of errors):"
      value={value.default}
      onChange={(variant) => onChange({...value, default: variant})}
      options={value.variants.map((item) => item.name)}
    />
  </>
});

export default ABTestSetup;
