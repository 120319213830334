import React, {useState} from "react";
import "jsoneditor-react/es/editor.min.css";
import {
  AddCampaignFormPaper,
  CancelButton,
  CancelIcon,
  ModalCirclularProgress,
  SubmitButton,
} from "../common/form_styles";
import Select from "react-select";
import {useQuery} from "@apollo/client";
import {GET_STATES} from "../../graphql/queries/config";
import {GET_PROMOTIONAL_OFFER_DEFINITIONS} from "../../graphql/queries/promotionalOffers";
import {GET_CAMPAIGNS} from "../../graphql/queries/campaigns";


function RulesToPromoOffersForm({ruleToPromoOfferData, createMode, onSubmit, onClose=null, isFetching=false}) {
  const [campaignId, setCampaignId] =           useState(!createMode ? ruleToPromoOfferData.campaignId : null);
  const [state, setState] =                     useState(!createMode ? ruleToPromoOfferData.state : []);
  const [promotionalOfferId, setPromotionalOfferId] = useState(!createMode ? ruleToPromoOfferData.promotionalOfferId : null);

  const {
    loading: stateLoading,
    error: stateError,
    data: stateData
  } = useQuery(GET_STATES)

  const {
    loading: promoOfferDefsLoading,
    error: promoOfferDefsError,
    data: promoOfferDefsData,
  } = useQuery(GET_PROMOTIONAL_OFFER_DEFINITIONS);


  const {
    loading: campaignsLoading,
    error: campaignsError,
    data: campaignsData,
  } = useQuery(GET_CAMPAIGNS);


  const submitHandler = () => {
    let data = {}
    if (!createMode){
      if (campaignId !== ruleToPromoOfferData.campaignId) data["campaign_id"] = parseInt(campaignId)
      if (state !== ruleToPromoOfferData.state) data["state"] = state
      if (promotionalOfferId !== ruleToPromoOfferData.promotionalOfferId) data["promotional_offer_id"] = parseInt(promotionalOfferId)
    }else{
      data = {
        campaign_id: campaignId,
        state: state,
        promotional_offer_id: promotionalOfferId,
      };
    }
    if (data !== {})
      onSubmit(data);
  };

  const handleFactory = (setFunction) => {
    return (option, actionMeta) => {
      switch (actionMeta.action) {
        case 'select-option':
          setFunction(option.value)
          return;
        case 'create-option':
          return;
        default:
          return;
      }
    }
  }

  const handleStateSelection = (option, actionMeta) => {
    switch (actionMeta.action) {
      case 'select-option':
        option = option.map((o) => o.value)
        setState(option)
        return;
      case 'create-option':
        return;
      case 'remove-value':
        if (option === null)
          option = []
        else
          option = option.map((o) => o.value)
        setState(option)
        return;
      default:
        return;
    }
  }

  if (stateLoading || campaignsLoading || promoOfferDefsLoading)
    return <p>Loading data...</p>

  const states = JSON.parse(stateData.states)["STATES"]
  let campaignIds = []
  campaignsData.campaigns.edges.map(item => {
    if (parseInt(item.node.id) >= 1000)
      campaignIds.push(item.node.id)
  })
  const promoOfferDefIds = promoOfferDefsData.promotionalOfferDefinitions.edges.map(item => {
    return item.node.id
  })

  return (
    <>
      <AddCampaignFormPaper mt={3}>
        <form noValidate autoComplete="off">
          {
            createMode &&
            <CancelIcon
              onClick={() => onClose(true)}
            />
          }
          {
            createMode &&
            <div style={{ marginTop: "30px", width: "206px"}}>
              <label>
                State:
                <Select
                  isMulti
                  onChange={handleStateSelection}
                  options={states.map((s) => ({
                    value: s,
                    label: s,
                  }))}
                  value={ state.map((s) => ({
                    value: s,
                    label: s,
                  }))}
                />
              </label>
            </div>
          }
          {
            !createMode &&
            <div style={{ marginTop: "30px", width: "206px"}}>
              <label>
                State:
                <Select
                  onChange={handleFactory(setState)}
                  options={states.map((s) => ({
                    value: s,
                    label: s,
                  }))}
                  value={{ value: state, label: state}}
                />
              </label>
            </div>
          }
          <div style={{ marginTop: "30px", width: "206px"}}>
            <label>
              Campaign Id:
              <Select
                onChange={handleFactory(setCampaignId)}
                options={Object.values(campaignIds).map(c => ({
                  value: c,
                  label: c,
                }))}
                value={{ value: campaignId, label: campaignId }}
              />
            </label>
          </div>
          <div style={{ marginTop: "30px", width: "206px"}}>
            <label>
              Promotional Offer Definition Id:
              <Select
                onChange={handleFactory(setPromotionalOfferId)}
                options={Object.values(promoOfferDefIds).map(p => ({
                  value: p,
                  label: p,
                }))}
                value={{ value: promotionalOfferId, label: promotionalOfferId }}
              />
            </label>
          </div>
        </form>
        <SubmitButton
          variant="contained"
          color="primary"
          onClick={submitHandler}
        >
          Submit
        </SubmitButton>
        {
          createMode &&
          <CancelButton
            variant="contained"
            color="primary"
            onClick={() => onClose(true)}
          >
            Close
          </CancelButton>
        }
        {isFetching && <ModalCirclularProgress />}
      </AddCampaignFormPaper>
    </>
  );
}

export default RulesToPromoOffersForm;
