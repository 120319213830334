import React from 'react';
import {FormControl} from "../JsonLogicUI/component_styles";
import Select from "react-select";


const DropDown = React.memo(({onChange, options, value, label=""}) => {
  const changeSelectValue = (option, actionMeta) => {
    switch (actionMeta.action) {
      case 'select-option': {
        onChange(option.value);
        return;
      }
      default:
        return;
    }
  }

  return <>
    <FormControl>
      {label}
      <Select
        onChange={changeSelectValue}
        onInputChange={changeSelectValue}
        options={options.map((c) => ({ value: c, label: c }))}
        value={{ value: value, label: value }}
      />
    </FormControl>
  </>
})

export default DropDown;
