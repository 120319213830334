import React, {useState, setState, useEffect} from "react";
import {useMutation} from "@apollo/client";
import { connect } from 'react-redux';
import CancelConfirmationModal from '../common/warning-modal';
import BlacklistForm from "./BlacklistForm";
import {Modal, Paper, Typography} from "@material-ui/core";
import {
  CREATE_CONFIG, VALIDATE_CONFIG
} from "../../graphql/queries/config";
import {cloneDeep} from "lodash";
import {CancelIcon, ModalPaper} from "../common/form_styles";
import {defaultConfig} from "./index";

function AddBlacklistModal(
  {configType, baseConfig, initialConfig, additionalInfo, onClose, open, onSubmit, isFetching, actionCaption, validationErrors}) {
  if (!baseConfig)
    baseConfig = defaultConfig;

  const [showCancelConfirmationModal, setShowCancelConfirmationModal] = useState(false);
  const [config, setConfig] = useState(initialConfig);
  useEffect(() => {
    setConfig(initialConfig);
  }, [initialConfig]);

  const [validateConfig, validationResponse] = useMutation(VALIDATE_CONFIG);

  const isValidating = validationResponse.loading
  const errors = (validationResponse.data && JSON.parse(validationResponse.data.validateConfig.errors)) || {};

  Object.keys(errors).forEach(
    (path) => {
      let field = path.split('.')[0];
      validationErrors[field] = validationErrors[field] || [];
      validationErrors[field] = validationErrors[field].concat(errors[path]);
    }
  )

  if (!config)
    return <>No config supplied</>

  return (
    <>
      <CancelConfirmationModal
        headerText="Are you sure you want to cancel?"
        contentText="All progress will be lost"
        showModal={showCancelConfirmationModal}
        hideModalHandler={() => setShowCancelConfirmationModal(false)}
        cancelHandler={() => setShowCancelConfirmationModal(false)}
        submitHandler={() => {
          setShowCancelConfirmationModal(false);
          setConfig(cloneDeep(baseConfig));
          onClose();
        }}
      />

      <Modal
        open={open}
        onClose={() => {setShowCancelConfirmationModal(true)}}
      >
        <ModalPaper style={{height: '100%', overflow: 'scroll'}}>
          <CancelIcon onClick={() => {setShowCancelConfirmationModal(true)}}
          />
          {
            config && (
              <BlacklistForm
                onSubmit={onSubmit}
                blacklist={config}
                isFetching={isFetching}
                additionalInfo={additionalInfo}
                validationErrors={validationErrors}
              />
            )
          }
        </ModalPaper>
      </Modal>
    </>
  );
}

export default AddBlacklistModal;
