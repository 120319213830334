import React, {useState} from 'react';
import 'brace/mode/json';
import 'brace/theme/github';
import { Controlled as CodeMirror } from "react-codemirror2";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/sql/sql";
import "codemirror/addon/hint/show-hint";
import "codemirror/addon/hint/sql-hint";
import "codemirror/addon/hint/show-hint.css";
import "codemirror/keymap/sublime";
import "codemirror/addon/edit/closebrackets";
import "codemirror/addon/edit/closetag";
import "codemirror/addon/fold/foldcode";
import "codemirror/addon/fold/foldgutter";
import "codemirror/addon/fold/brace-fold";
import "codemirror/addon/fold/comment-fold";
import "codemirror/addon/fold/foldgutter.css";

import {CampaignFormErrorText, ModalPaper, SubmitButton} from "../common/form_styles";
import {Paper, CircularProgress, Typography} from "@material-ui/core";
import {useMutation} from "@apollo/client";
import {
  GET_PRODUCTION_TESTS,
  TRIGGER_BACKGROUND_JOB, TRIGGER_TESTING
} from "../../graphql/queries/productionTesting";
import ConfigForm from "../Config/ConfigForm";
import _ from "lodash";


function ProductionTestForm({configOverrides={}, image=null}) {
  const [targetGroupSQL, setTargetGroupSQL] = useState("SELECT l.id as lead_id, l.created_at FROM leads.leads l LEFT JOIN lead_results lr on l.id = lr.lead_id and lr.model_name = 'dup-cache' and lr.process_step = 'falci' and lr.created_at > now() - interval '2 day' where l.created_at > now() - interval '2 day' and lr.lead_id is null ORDER BY random() LIMIT 100");

  const imageDecoded = image && decodeURIComponent(image);
  const [createProductionTest, creationResult] = useMutation(
    TRIGGER_TESTING,
    {
      variables: {
        configReplacement: JSON.stringify(configOverrides),
        targetGroupSql: targetGroupSQL,
        ecrImageTag: imageDecoded
      },
      refetchQueries: [
        { query: GET_PRODUCTION_TESTS }
      ]
    }
  );
  const data = creationResult?.data?.triggerProductionTesting;
  const errors = data?.errors ? JSON.parse(data?.errors) : null;

  return (
    <>
      <Paper mt={3} className="form-paper">
        <Typography variant="h1">New Production Testing</Typography>
        <form noValidate autoComplete="off">

          <label>
            Leads system code version: {imageDecoded || "current code"}
          </label>
          <label>
            Target group SQL:
            <p><small>Make sure to limit results of the query.</small></p>
            <CodeMirror
              value={targetGroupSQL}
              options={{
                mode: "text/x-pgsql",
                lineWrapping: true,
                smartIndent: true,
                lineNumbers: true,
                foldGutter: true,
                gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
                autoCloseTags: true,
                keyMap: "sublime",
                matchBrackets: true,
                autoCloseBrackets: true
              }}
              onBeforeChange={(editor, data, value) => {
                setTargetGroupSQL(value);
              }}
            />
          </label>
        </form>
        {
          data?.info && (
            <CampaignFormErrorText>
              <p>{data.info}</p>
              <p>
                {
                  Object.keys(errors).map((key) => (
                    <div>
                      <p>There are some errors in {key}:</p>
                      <ul>
                        {
                          Object.values(errors[key]).map((val) => (
                            <li>{val}</li>
                          ))
                        }
                      </ul>
                    </div>
                  ))
                }
              </p>
            </CampaignFormErrorText>
          )
        }
        <SubmitButton
          variant="contained"
          color="primary"
          disabled={!!creationResult.loading}
          onClick={() => {createProductionTest().then(r => {
            if (r.data?.triggerProductionTesting?.ok)
              window.location = '/production_testing';
          });}}
        >Submit</SubmitButton>
        {creationResult.loading && <CircularProgress className="circular" /> }
      </Paper>
    </>
  );
}

export default ProductionTestForm;