import {OUTAGE_LOADING, UPDATE_OUTAGE} from "../actions/outage";
import {C} from "../constants";

const INITIAL_STATE = {
  enabled: false,
  loading: false
};


export default (state = INITIAL_STATE, { type = "", payload }) => {
  switch (type) {
  case C.OUTAGES: {
    return {
      ...state,
      enabled: payload.clarity ? payload.clarity : false,
    };
  }

  case OUTAGE_LOADING: {
    return {
      ...state,
      loading: payload,
    };
  }

  case UPDATE_OUTAGE:
    return {
      ...state,
      enabled: payload.enabled
    };

  default:
    return state;
  }
};
