import React, {useState} from "react";
import "jsoneditor-react/es/editor.min.css";

import {
  AddCampaignFormPaper,
  CancelButton,
  CancelIcon,
  ModalCirclularProgress,
  SubmitButton,
  TextField,
} from "../common/form_styles";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";
import {Controlled as CodeMirror} from "react-codemirror2";

const DEFAULT_OFFER_DESC = "This offer is conditioned on: (1) the submission of a complete loan application, including instant bank verification (IBV) with your depository financial institution; (2) the verification of your income and ability-to-repay; and (3) the absence of adverse changes to your credit history prior to final loan approval. Not all applicants will qualify for a loan or for a loan with the amount or term you requested.  If you do qualify for a loan, then--depending on your state and product--CreditNinja will either make or arrange your loan.  If you receive final loan approval, then your loan agreement will identify the lender, the cost of credit, and the terms of repayment."
const DEFAULT_LOAN_PROGRAM_NAME = "CreditNinja Promotional Offer"

const TERM_CONSTANT_VALUES = {
  1: "Month",
  2: "Year"
}

function PromoOfferDefForm({promoOfferDefData, createMode, onSubmit, onClose=null, isFetching=false}) {
  const [promoOfferDefId, setPromoOfferDefId] =         useState(!createMode ? promoOfferDefData.id : "");
  const [loanProgramName, setLoanProgramName] =         useState(!createMode ? promoOfferDefData.loanProgramName : DEFAULT_LOAN_PROGRAM_NAME);
  const [interestRate, setInterestRate] =               useState(!createMode ? promoOfferDefData.interestRate : 0);
  const [term, setTerm] =                               useState(!createMode ? promoOfferDefData.term : 0);
  const [termConstant, setTermConstant] =               useState(!createMode ? promoOfferDefData.termConstant : 1);
  const [apr, setApr] =                                 useState(!createMode ? promoOfferDefData.apr : 0);
  const [annualFee, setAnnualFee] =                     useState(!createMode ? promoOfferDefData.annualFee : 0);
  const [originationFee, setOriginationFee] =           useState(!createMode ? promoOfferDefData.originationFee : 0);
  const [offerDescription, setOfferDescription] =       useState(!createMode ? promoOfferDefData.offerDescription : DEFAULT_OFFER_DESC);
  const [customizedMessage, setCustomizedMessage] =     useState(!createMode ? promoOfferDefData.customizedMessage : "");
  const [cannedText, setCannedText] =                   useState(!createMode ? promoOfferDefData.cannedText : "");


  const submitHandler = () => {
    let data = {}
    if (!createMode){
      if (loanProgramName !== promoOfferDefData.loanProgramName) data["loan_program_name"] = loanProgramName
      if (interestRate !== promoOfferDefData.interestRate) data["interest_rate"] = parseFloat(interestRate)
      if (term !== promoOfferDefData.term) data["term"] = parseFloat(term)
      if (termConstant !== promoOfferDefData.termConstant) data["term_constant"] = parseFloat(termConstant)
      if (apr !== promoOfferDefData.apr) data["apr"] = parseFloat(apr)
      if (annualFee !== promoOfferDefData.annualFee) data["annual_fee"] = parseFloat(annualFee)
      if (originationFee !== promoOfferDefData.originationFee) data["origination_fee"] = parseFloat(originationFee)
      if (offerDescription !== promoOfferDefData.offerDescription) data["offer_description"] = offerDescription
      if (customizedMessage !== promoOfferDefData.customizedMessage) data["customized_message"] = customizedMessage
      if (cannedText !== promoOfferDefData.cannedText) data["canned_text"] = cannedText
    }else{
      data = {
        id: promoOfferDefId,
        loan_program_name: loanProgramName,
        interest_rate: interestRate,
        term: term,
        term_constant: termConstant,
        apr: apr,
        annual_fee: annualFee,
        origination_fee: originationFee,
        offer_description: offerDescription,
        customized_message: customizedMessage,
        canned_text: cannedText
      };
    }
    if (data !== {})
      onSubmit(data);
  };

  const handleFactory = (setFunction) => {
    return (option, actionMeta) => {
      switch (actionMeta.action) {
        case 'select-option':
          setFunction(option.value)
          return;
        case 'create-option':
          return;
        default:
          return;
      }
    }
  }

  return (
    <>
      <AddCampaignFormPaper mt={3}>
        <form noValidate autoComplete="off" style={{display: "flex"}}>
          {
            createMode &&
            <CancelIcon
              onClick={() => onClose(true)}
            />
          }
          <div style={{flex: "50%"}}>
            <div>
              <TextField
                required
                id="name"
                m={2}
                placeholder="Loan Program Name"
                value={loanProgramName}
                onChange={(event) => setLoanProgramName(event.target.value)}
              />
            </div>
            {
              createMode &&(
                <div>
                  <TextField
                    required
                    m={2}
                    placeholder="Promo Offer Def ID"
                    type="number"
                    value={promoOfferDefId}
                    onChange={(event) => setPromoOfferDefId(event.target.value)}
                  />
                </div>
              )
            }
            <div style={{ marginTop: "30px", width: "206px"}}>
              <label>
                Interest Rate:
                <TextField
                  required
                  m={2}
                  placeholder="Interest Rate"
                  type="number"
                  value={interestRate}
                  onChange={(event) => setInterestRate(event.target.value)}
                />
              </label>
            </div>
            <div style={{ marginTop: "30px", width: "206px"}}>
              <label>
                Term:
                <TextField
                  required
                  placeholder="Term"
                  type="number"
                  value={term}
                  onChange={(event) => setTerm(event.target.value)}
                />
              </label>
            </div>
            <div style={{ marginTop: "30px", width: "206px"}}>
              <label>
                Term constant:
                <CreatableSelect
                  onChange={handleFactory(setTermConstant)}
                  options={Object.entries(TERM_CONSTANT_VALUES).map(([key, value]) => ({
                    value: key,
                    label: value,
                  }))}
                  value={{ value: termConstant, label: TERM_CONSTANT_VALUES[termConstant] }}
                />
              </label>
            </div>
            <div style={{ marginTop: "30px", width: "206px"}}>
              <label>
                APR:
                <TextField
                  required
                  placeholder="APR"
                  type="number"
                  value={apr}
                  onChange={(event) => setApr(event.target.value)}
                />
              </label>
            </div>
            <div style={{ marginTop: "30px", width: "206px"}}>
              <label>
                Annual Fee:
                <TextField
                  required
                  placeholder="Annual Fee"
                  type="number"
                  value={annualFee}
                  onChange={(event) => setAnnualFee(event.target.value)}
                />
              </label>
            </div>
            <div style={{ marginTop: "30px", width: "206px" }}>
              <label>
                Origination Fee:
                <TextField
                  required
                  placeholder="Origination Fee"
                  type="number"
                  value={originationFee}
                  onChange={(event) => setOriginationFee(event.target.value)}
                />
              </label>
            </div>
          </div>
          <div style={{flex: "50%"}}>
            <div style={{ marginTop: "30px", width: "700px"}}>
              <label>
                Offer Description:
                <CodeMirror
                  value={offerDescription}
                  onBeforeChange={(editor, data, value) => setOfferDescription(value)}
                  onChange={(editor, data, value) => {}}
                  options={{
                    lineWrapping: true,
                    lineNumbers: true,
                  }}
                />
              </label>
            </div>
            <div style={{ marginTop: "30px", width: "700px", display: "flex" }}>
              <label>
                Customized Message:
                <CodeMirror
                  value={customizedMessage}
                  onBeforeChange={(editor, data, value) => setCustomizedMessage(value)}
                  onChange={(editor, data, value) => {}}
                  options={{
                    lineWrapping: true,
                    lineNumbers: true,
                  }}
                />
              </label>
            </div>
            <div style={{ marginTop: "30px", width: "700px", display: "flex" }}>
              <label>
                Canned Text:
                <CodeMirror
                  value={cannedText}
                  onBeforeChange={(editor, data, value) => setCannedText(value)}
                  onChange={(editor, data, value) => {}}
                  options={{
                    lineWrapping: true,
                    lineNumbers: true,
                  }}
                />
              </label>
            </div>
          </div>
        </form>
        <SubmitButton
          variant="contained"
          color="primary"
          onClick={submitHandler}
        >
          Submit
        </SubmitButton>
        {
          createMode &&
          <CancelButton
            variant="contained"
            color="primary"
            onClick={() => onClose(true)}
          >
            Close
          </CancelButton>
        }
        {isFetching && <ModalCirclularProgress />}
      </AddCampaignFormPaper>
    </>
  );
}

export default PromoOfferDefForm;
