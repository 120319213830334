import React, {Component} from 'react';
import CreatableSelect from "react-select/creatable";
import {TextField, FormControl, DashSign, OperatorBox, ActionsBox} from "./component_styles";
import Select from "react-select";
import {ALLOWED_COERCIONS, ALLOWED_OPERATORS, DEFAULT_VALUES, OPERATORS} from "./const";
import {RemoveCircle} from "@material-ui/icons";
import {Switch} from "@material-ui/core";
import {getSampleCondition, getSampleGroup} from "./utils";
import CustomizedMenu from "./Menu";

class OperatorBlock extends Component {

  constructor(props) {
    super(props);

    this.fieldTypes = props.fieldTypes;
    this.state = this.propsToState();
  }

  getValueType = (field, operator, value) => {
    if (OPERATORS[operator].rightArgumentTypes.length === 1) {
      return OPERATORS[operator].rightArgumentTypes[0]
    }
    if (Array.isArray(value)){
      return 'list'
    }
    if (value.hasOwnProperty('var')) {
      return 'field'
    }
    return typeof value;
  }

  getAvailableValueTypes = (operator, field) => {
    let types = [];
    OPERATORS[operator].rightArgumentTypes.forEach(
      (type, i) => {
        if (ALLOWED_COERCIONS[this.getFieldType(field)].includes(type) || type === 'list')
          types.push(type);
      }
    )
    return types;
  }

  getCompatibleFields = (operator, field) => {
    return Object.keys(this.fieldTypes).filter(
      v => OPERATORS[operator].rightArgumentTypes.includes(this.getFieldType(v)) || this.getFieldType(v) === "string"
    );
  }

  unpackValues = option => {
    if (typeof option === 'object') {
      if (Array.isArray(option)) {
        return option.map(item => item.value)
      }
      if (!option)
        return []
      return option.value;
    }
    return option
  }

  changeField = (field) => {
    this.callOnChange('==', field, DEFAULT_VALUES[this.getFieldType(field)]);
  }

  changeOperator = (operator) => {
    let availableTypes = this.getAvailableValueTypes(operator, this.state.field);
    let newValueType = this.state.valueType;
    let newValue = this.state.value;
    if (!availableTypes.includes(this.state.valueType)) {
      newValueType = availableTypes[0];
      if (availableTypes.includes(this.getFieldType(this.state.field)))
        newValueType = this.getFieldType(this.state.field);
    }
    if (this.state.valueType !== newValueType) {
      newValue = DEFAULT_VALUES[newValueType];
    }
    if (operator !== this.state.operator || newValue !== this.state.value)
      this.callOnChange(operator, this.state.field, newValue);
  }

  changeValueType = (type) => {
    if (this.state.valueType !== type) {
      let newValue = DEFAULT_VALUES[type];

      if (type === 'field') {
        newValue['var'] = this.state.availableFields[0];
      }
      this.callOnChange(this.state.operator, this.state.field, newValue);
    }
  }

  handleChangeField = (option, actionMeta) => {
    switch (actionMeta.action) {
      case 'select-option': {
        this.changeField(option.value);
        return;
      }
      default:
        return;
    }
  }

  handleChangeOperator = (option, actionMeta) => {
    switch (actionMeta.action) {
      case 'select-option': {
        this.changeOperator(option.value);
        return;
      }
      default:
        return;
    }
  }

  handleChangeValueType = (option, actionMeta) => {
    switch (actionMeta.action) {
      case 'select-option': {
        this.changeValueType(option.value)
        return;
      }
      default:
        return;
    }
  }

  changeListValues = name => (option, actionMeta) => {
    switch (actionMeta.action) {
      case 'select-option':
      case 'create-option':
      case 'remove-value':
      case 'clear': {
        this.callOnChange(this.state.operator, this.state.field, this.unpackValues(option));
        return;
      }
      default:
        return;
    }
  }

  changeValueField = (option, actionMeta) => {
    switch (actionMeta.action) {
      case 'select-option': {
        this.callOnChange(this.state.operator, this.state.field, {var: option.value});
        return;
      }
      default:
        return;
    }
  }

  changeTextValue = (event) => {
    if (this.state.valueType === 'number')
      this.setState({value: parseFloat(event.target.value)});
    else this.setState({value: event.target.value});
  }

  changeBooleanValue = name => (event) => {
    this.callOnChange(this.state.operator, this.state.field, event.target.checked);
  }

  onTextFieldFocusLost = (event) => {
    this.callOnChange(this.state.operator, this.state.field, this.state.value);
  }

  callOnChange = (operator, field, value) => {
    if (this.props.onExpressionChange)
      return this.props.onExpressionChange(this.toJsonLogic(operator, field, value));
  }

  toJsonLogic = (operator, field, value) => {
    let data = {}
    data[operator] = [
      {'var': field},
      value
    ];
    return data
  }

  convertToGroup = () => {
    return this.props.onExpressionChange({"and": [this.toJsonLogic(this.state.operator, this.state.field, this.state.value)]});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.expression !== this.props.expression) {
      this.setState(this.propsToState());
    }
  }
  
  propsToState = () => {
    let operator = Object.keys(this.props.expression)[0].toLowerCase();
    let value = this.props.expression[operator][1];
    let field = this.props.expression[operator][0]['var'];
    let valueType = this.getValueType(field, operator, value);

    return {
      operator: operator,
      field: field,
      value: value,
      valueFieldName: valueType === 'field' ? value['var'] : null,
      valueType: valueType,
      availableTypes: this.getAvailableValueTypes(operator, field),
      availableFields: this.getCompatibleFields(operator, field)
    };
  }

  getFieldType = (field) => {
    return this.fieldTypes[field] || "string";
  }

  render = () => (
    <>
      <DashSign/>
      <OperatorBox>
        <FormControl style={{minWidth: 200}}>
          <Select
            onChange={this.handleChangeField}
            onInputChange={this.handleChangeField}
            options={Object.keys(this.fieldTypes).map((f) => ({ value: f, label: f }))}
            value={{ value: this.state.field, label: this.state.field }}
          />
        </FormControl>
        <FormControl style={{minWidth: 70}}>
          <Select
            onChange={this.handleChangeOperator}
            onInputChange={this.handleChangeOperator}
            options={ALLOWED_OPERATORS[this.getFieldType(this.state.field)].map((op) => ({label: OPERATORS[op].label, value: op}))}
            value={{ value: this.state.operator, label: OPERATORS[this.state.operator].label }}
          />
        </FormControl>
        <FormControl style={{minWidth: 105}}>
          <Select
            onChange={this.handleChangeValueType}
            onInputChange={this.handleChangeValueType}
            options={this.state.availableTypes.map((type) => ({label: type, value: type}))}
            value={{value: this.state.valueType, label: this.state.valueType}}
          />
        </FormControl>
        {
          this.state.valueType === 'field' && (
            <FormControl>
              <Select
                onChange={this.changeValueField}
                onInputChange={this.changeValueField}
                options={this.state.availableFields.map((c) => ({ value: c, label: c }))}
                value={{ value: this.state.valueFieldName, label: this.state.valueFieldName }}
              />
            </FormControl>
          )
        }
        {
          ['string', 'number'].includes(this.state.valueType) && (
            <FormControl style={{minWidth: 100}}>
              <TextField
                variant="outlined"
                onChange={this.changeTextValue}
                onBlur={this.onTextFieldFocusLost}
                type={this.state.valueType}
                value={this.state.value}
              />
            </FormControl>
          )
        }
        {
          this.state.valueType === 'boolean' && (
            <FormControl style={{minWidth: 30}}>
              <Switch
                checked={this.state.value}
                onChange={this.changeBooleanValue('value')}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </FormControl>
          )
        }
        {
          this.state.valueType === 'list' && (
            <FormControl style={{minWidth: 120, maxWidth: 250}}>
              <CreatableSelect
                isMulti
                onChange={this.changeListValues('value')}
                onInputChange={this.changeListValues('value')}
                value={this.state.value.map(item => ({value: item, label: item}))}
                options={[]}
              />
            </FormControl>
          )
        }

        <ActionsBox>
          <div>
            <CustomizedMenu
              options={[
                {title: 'Convert to Group', action: this.convertToGroup},
              ]}
              icon="add-circle"
            />
            {
              this.props.removeCurrent && (
                <RemoveCircle
                  color="primary"
                  onClick={this.props.removeCurrent}
                />
              )
            }
          </div>
        </ActionsBox>
      </OperatorBox>
    </>
  )
}

export default OperatorBlock;
