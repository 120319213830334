import React from 'react';
import {JsonEditor as Editor} from "jsoneditor-react";
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/github';
import {TightBlock} from "../Bidding/Nodes/component_styles";


const TextInput = React.memo(({onChange, value}) => {
  return <TightBlock>
    <Editor
      value={value}
      mode="code"
      htmlElementProps={{style: {height: 300}}}
      ace={ace}
      theme="ace/theme/github"
      onChange={(val) => onChange(val)}
    />
  </TightBlock>
})

export default TextInput;
