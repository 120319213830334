import gql from "graphql-tag";

const GET_CAMPAIGNS = gql`
query getCampaigns {
  campaigns {
    edges {
      node {
        id
        name
        channel
        createdAt
        updatedAt
        isActive
        leadProviderId
        config
        configFile
        shortFormConfigFile
        topPricePoint
        cappedBidAmount
        tags
        brandId
      }
    }
  }
}
`;

const GET_LEAD_PROVIDERS = gql`
query getLeadProviders {
  leadProviders {
    edges {
      node {
        id
        name
        createdAt
        updatedAt
        token
      }
    }
  }
}
`;

const GET_BRANDS = gql`
query getBrands {
  brands {
    edges {
      node {
        id
        brandName
        brandPhone
        brandEmail
      }
    }
  }
}
`;

const UPDATE_CAMPAIGNS = gql`
mutation ($payload: JSONString, $campaignsId: [Int]) {
  updateCampaigns(payload: $payload, campaignsId: $campaignsId) {
    ok
    info
    errors
    campaigns
  }
}
`;

const CREATE_CAMPAIGN = gql`
mutation ($payload: JSONString) {
  createCampaign(payload: $payload) {
    ok
    info
    errors
    campaign {
      id
      name
      channel
      createdAt
      updatedAt
      isActive
      leadProviderId
      config
      configFile
      shortFormConfigFile
      topPricePoint
      cappedBidAmount
    }
  }
}
`;

export {
  GET_CAMPAIGNS,
  UPDATE_CAMPAIGNS,
  CREATE_CAMPAIGN,
  GET_LEAD_PROVIDERS,
  GET_BRANDS
};
