import ConjunctionBlock from "./ConjunctionBlock";
import ConstantBlock from "./ConstantBlock";
import OperationBlock from "./OperatorBlock";
import React from "react";

export const CONJUNCTIONS = ['and', 'or'];
export const OPERATORS = {
  '<': {
    'label': '<',
    'leftArgumentFieldTypes': ['string', 'number'],
    'rightArgumentTypes': ['number', 'field']
  },
  '>': {
    'label': '>',
    'leftArgumentFieldTypes': ['string', 'number'],
    'rightArgumentTypes': ['number', 'field']
  },
  '==': {
    'label': '==',
    'leftArgumentFieldTypes': ['number', 'string', 'boolean'],
    'rightArgumentTypes': ['number', 'string', 'boolean', 'field']
  },
  '<=': {
    'label': '<=',
    'leftArgumentFieldTypes': ['string', 'number'],
    'rightArgumentTypes': ['number', 'field']
  },
  '>=': {
    'label': '>=',
    'leftArgumentFieldTypes': ['string', 'number'],
    'rightArgumentTypes': ['number', 'field']
  },
  '!=': {
    'label': '!=',
    'leftArgumentFieldTypes': ['string', 'number', 'boolean'],
    'rightArgumentTypes': ['number', 'string', 'boolean', 'field']
  },
  'in': {
    'label': 'in',
    'leftArgumentFieldTypes': ['string'],
    'rightArgumentTypes': ['list', 'field']
  },
  '!in': {
    'label': 'not in',
    'leftArgumentFieldTypes': ['string'],
    'rightArgumentTypes': ['list', 'field']
  }
};

export let COMPONENT_MAPPING = {
  'const': (props) => <ConstantBlock {...props}/>
}

CONJUNCTIONS.forEach(
  (op, i) => {
    COMPONENT_MAPPING[op] = (props) => <ConjunctionBlock {...props}/>
  }
)

Object.keys(OPERATORS).forEach(
  (op, i) => {
    COMPONENT_MAPPING[op] = (props) => <OperationBlock {...props}/>
  }
)

export const DEFAULT_VALUES = {
  'number': 0,
  'string': '',
  'list': [],
  'boolean': true,
  'field': {'var': null}
}

export const ALLOWED_COERCIONS = {
  'number': ['number', 'string', 'field'],
  'string': ['number', 'string', 'boolean', 'field'],
  'list': ['list'],
  'boolean': ['boolean', 'field'],
  'field': ['field']
}

export const ALLOWED_OPERATORS = {}
Object.keys(OPERATORS).forEach(
  (op, i) => {
    OPERATORS[op].leftArgumentFieldTypes.forEach(
      (type, j) => {
        ALLOWED_OPERATORS[type] = ALLOWED_OPERATORS[type] || [];
        ALLOWED_OPERATORS[type].push(op);
      }
    )
  }
)
