import {withStyles} from '@material-ui/core/styles';
import React from "react";
import {PrimaryButtonLarge, Card, ModalPaper} from "../../common/form_styles";
import {Button, CardHeader as MuiCardHeader, Grid, Typography} from "@material-ui/core";
import {Edit, RemoveCircle} from "@material-ui/icons";
import styled from "styled-components";
import {FullHeightGrid} from "../Schema/component_styles";

export const MainContainer = styled.div`
  background-color: #f3f3f3;
  margin: 20px 40px;
  padding: 20px;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 800px;
`;

export const HorizontalSeparator = styled.div`
  background-image: linear-gradient(to right, gray 66%, rgba(255, 255, 255, 0) 0%);
  background-size: 20px 1px;
  background-position: center;
  background-repeat: repeat-x;
  height: 5px;
  padding: 10px 0;
`;

export const WhiteCard = withStyles(theme => ({
  root: {
    backgroundColor: props => props.backgroundColor ? props.backgroundColor : '#ffffff',
    padding: 20,
    borderColor: '#929292',
    borderStyle: 'solid',
    borderWidth: 1,
    minWidth: 500,
    overflow: 'visible',
    margin: 10,
    position: 'relative'
  }
}))(Card);

export const CardHeader = withStyles(theme => ({
  root: {
    fontSize: 14,
    padding: 0,
    margin: '0 0 10px 0',
    textAlign: 'left',
    color: '#117F9B',
    fontWeight: 'bold'
  }
}))(MuiCardHeader);

export const ContentCentered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
`;

export const TightBlock = styled.div`
  width: fit-content;
  min-width: 700px;
  & div.ace_content {
    z-index: 0;
  }
`;

export const EditableLabel = (props) => {
  let nameInput = React.createRef();
  return (
    <>
      <span
        contentEditable='true'
        onBlur={props.onChange}
        style={{width: "fit-content", padding: 5}}
        ref={nameInput}
        suppressContentEditableWarning={true}
      >
        {props.value}
      </span>
      <Edit
        onClick={() => {nameInput.current.focus(); document.execCommand('selectAll',false,null);}}
        style={{fontSize: 'medium', marginLeft: 5}}
      />
      {
        props.error && (<div style={{color: 'red', fontSize: '0.7rem'}}>{props.error}</div>)
      }
    </>
  )
};

export const HorizontalGrid = withStyles(theme => ({
  root: {
    flexDirection: "row",
    flexWrap: "wrap",
    display: "flex",
    justifyContent: "space-around"
  }
}))(FullHeightGrid);

export const HorizontalGridOrdered = withStyles(theme => ({
  root: {
    flexDirection: "row",
    flexWrap: "wrap",
    display: "flex",
    justifyContent: "space-around"
  }
}))(FullHeightGrid);