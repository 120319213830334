import React from 'react';
import _ from 'lodash';

import {JsonEditor as Editor} from "jsoneditor-react";

import {
  AddCampaignFormPaper,
  CancelIcon,
  TextField,
  ModalCirclularProgress
} from '../common/form_styles';
import {CancelButton, SubmitButton} from "../common/form_styles";
import {TextField as MuiTextField} from "@material-ui/core";


class ConfigForm extends React.Component {
  constructor(props) {
    super(props);
    this.defaultState = {
      name: '',
      type: props.configType,
      config: props.defaultConfig,
      nameError: false
    };

    this.state = {
      ...this.defaultState,
      ...props.configs
    };
  }

  handleChangeText = name => event => {
    this.setState({ [name]: event.target.type === 'number' ? parseInt(event.target.value) : event.target.value });
  };

  handleChangeJson = name => event => {
    this.setState({ [name]: event });
  };

  submitHandler = event => {
    const { name, config, type } = this.state;
    const { onSubmit } = this.props;
    const data = {
      name,
      config,
      type,
    };
    if (name !== '')
      onSubmit(data);
    else
      this.setState({nameError: true})
  }

  closeHandler = event => {
    const { onClose, defaultConfig } = this.props;
    const isEdited = !_.isEqual(defaultConfig, this.state);

    onClose(isEdited);
  }

  render() {
    const { config, name, nameError } = this.state;
    const { isFetching } = this.props;
    return (
      <>
        <AddCampaignFormPaper mt={3}>
          <form noValidate autoComplete="off">
            <CancelIcon onClick={this.closeHandler} />
            <div>
            </div>
              <MuiTextField
                error={nameError}
                label="Config Name"
                required
                id="name"
                m={2}
                value={name}
                onChange={this.handleChangeText('name')}
              />
            <Editor
                value={config}
                allowedModes={['tree', 'view', 'form', 'text']}
                onChange={this.handleChangeJson('config')}
                htmlElementProps={{style: {height: 500}}}
            />
          </form>
          <SubmitButton variant="contained" color="primary" onClick={this.submitHandler}>Submit</SubmitButton>
          <CancelButton variant="contained" color="primary" onClick={this.closeHandler}>Close</CancelButton>
          {isFetching && <ModalCirclularProgress /> }
        </AddCampaignFormPaper>
      </>
    );
  }
}

export default ConfigForm;
