/* eslint max-classes-per-file: 0 */

import React, { Component } from "react";
import { compose } from "redux";
import styled, { withTheme } from "styled-components";
import { connect } from "react-redux";
import { darken } from "polished";
import { Redirect, withRouter } from "react-router-dom";

import {
  Button,
  CircularProgress,
  InputBase,
  Menu, MenuItem,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Snackbar,
  Toolbar, Typography,
  Drawer, TextField as MuiTextField, FormControl,
} from "@material-ui/core";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";

import Sidebar from "./Sidebar";

import * as Icon from "@material-ui/icons";

import {
  Bell,
  MessageSquare,
  Search as SearchIcon,
  Power
} from "react-feather";

import { C } from "../redux/constants";
import actions from "../redux/actions";
import WarningModal from "./common/warning-modal";
import {toggleOutage} from "../redux/actions/outage";


const AppBar = styled(MuiAppBar)`
  background: ${props => props.theme.header.background};
  color: ${props => props.theme.header.color};
  box-shadow: ${props => props.theme.shadows[1]};
  padding: 10px;
  img{
    width: 200px;
    padding-right: 20px;
  }
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${props => props.theme.header.search.color};
    padding-top: ${props => props.theme.spacing(2.5)}px;
    padding-right: ${props => props.theme.spacing(2.5)}px;
    padding-bottom: ${props => props.theme.spacing(2.5)}px;
    padding-left: ${props => props.theme.spacing(12)}px;
    width: 160px;
  }
`;

class UserMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorMenu: null
    };
  }

  toggleMenu = event => {
    this.setState({ anchorMenu: event.currentTarget });
  };

  closeMenu = () => {
    this.setState({ anchorMenu: null });
  };

  render() {
    const { anchorMenu } = this.state;
    const open = Boolean(anchorMenu);

    return (
      <>
        <IconButton
          aria-owns={open ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={this.toggleMenu}
          color="inherit"
        >
          <Power />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorMenu}
          open={open}
          onClose={this.closeMenu}
        >
          <MenuItem
            onClick={() => {
              this.closeMenu();
            }}
          >
            Profile
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.closeMenu();
            }}
          >
            Sign out
          </MenuItem>
        </Menu>
      </>
    );
  }
}

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current_user: props.current_user,
      showOutageModal: false,
      snackbar: {},
      outages: {},
      outageDescription: "We experience a full Clarity vendor outage for reports that are required for decisioning.",
      sideBarOpen: false
    };
    const { dispatch } = this.props;
    actions.getOutages()(props.dispatch, () => this.state);
  }

  static getDerivedStateFromProps(props, state) {
    return { 
      current_user: props.current_user,
      snackbar: props.snackbar, 
      lead_providers: props.lead_providers,
      outages: props.outages
    };
  }

  toggleOutageModal = () => {
    const { showOutageModal } = this.state;
    this.setState({ showOutageModal: !showOutageModal });
  }

  toggleOutage = () => {
    this.setState({ showOutageModal: false });
    const { outages: { enabled }, outageDescription } = this.state;
    const { dispatch } = this.props;
    dispatch(toggleOutage({ enable: !enabled, description: outageDescription }, this.state));
  }

  toggleDrawer = (isOpen) => event => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }    
    this.setState({ sideBarOpen: isOpen });
  }

  render() {
    const { dispatch, routes } = this.props;
    const { 
      showOutageModal, snackbar, sideBarOpen, outages, outageDescription
    } = this.state;

    return (
      <>
        <WarningModal
          headerText={ outages.enabled ? "Disable clarity outage mode?" : "Enable clarity outage mode?"}
          contentText={
            outages.enabled ?
              "Are you sure you want to disable the outage mode? This will not affect logic, but will put a record into outage log." :
              <>
                <div>Are you sure you want to start the outage mode? This will cause organic leads to be sent to manual
                review until this mode is deactivated.</div>
                <FormControl fullWidth style={{padding: "0 40px"}}>
                  <MuiTextField
                    label="This text will appear in Slack notification"
                    required
                    id="description"
                    multiline
                    rows={2}
                    value={outageDescription}
                    onChange={(event) => {this.setState({outageDescription: event.target.value})}}
                  />
                </FormControl>
              </>
          }
          showModal={showOutageModal}
          hideModalHandler={this.toggleOutageModal}
          cancelHandler={this.toggleOutageModal}
          submitHandler={this.toggleOutage}
        />

        <AppBar position="sticky" elevation={0}>
          <Toolbar>
            <IconButton onClick={this.toggleDrawer(true)}><MenuRoundedIcon /></IconButton>
            <Drawer open={sideBarOpen} onClose={this.toggleDrawer(false)}>
              <Sidebar
                routes={routes}
                PaperProps={{ style: { width: 250 } }}
                onClose={this.handleDrawerToggle}
              />
            </Drawer>
            <img src={'/logo.svg'} alt="logo" />
          </Toolbar>
        </AppBar>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={snackbar.isOpen}
          ContentProps={{ "aria-describedby": "message-id" }}
          message={<span id="message-id">{snackbar.message}</span>}
          action={[
            <IconButton 
              key="close" 
              aria-label="Close" 
              color="inherit" 
              onClick={() => dispatch({ type: C.SNACKBAR_CLOSE })}
            >
              <Icon.Close />
            </IconButton>
          ]}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  current_user: state.current_user,
  lead_providers: state.lead_providers,
  outages: state.outages,
  snackbar: state.snackbar,
});


export default compose(
  connect(mapStateToProps),
  withTheme,
  withRouter
)(Header);
