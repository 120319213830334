import React from 'react';
import {FormControl} from "../JsonLogicUI/component_styles";
import CreatableSelect from "react-select/creatable";


const CreatableMultiDropDown = React.memo(({onChange, options, values, label=""}) => {
  const changeSelectValue = (option, actionMeta) => {
    switch (actionMeta.action) {
      case 'select-option':
      case 'create-option':
      case 'remove-value':
      case 'clear':
        onChange(option ? option.map(item => item.value) : []);
        return;
      default:
        return;
    }
  }

  return <>
    <FormControl>
      {label}
      <CreatableSelect
        isMulti
        onChange={changeSelectValue}
        onInputChange={changeSelectValue}
        options={options.map((c) => ({ value: c, label: c }))}
        value={values ? values.map(value => ({ value: value, label: value })) : []}
      />
    </FormControl>
  </>
})

export default CreatableMultiDropDown;
