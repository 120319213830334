import gql from "graphql-tag";

const GET_BLACKLIST_CONTENT = gql`
query getBlacklistContent($configId: Float, $removedAt: DateTime) {
  blacklistContents(filters:{configId: $configId, removedAt: $removedAt }) {
    edges {
      node {
        id
        data
        abTestTier
        createdAt
        createdBy
        creationReason
      }
    }
  }
}
`;

const REMOVE_BLACKLIST_CONTENTS = gql`
mutation removeBlacklistContents($blacklistId: ID, $items: String, $reason: String) {
  removeBlacklistContents(blacklistId: $blacklistId, items: $items, reason: $reason) {
    ok
    info
    errors
  }
}
`;

const ADD_BLACKLIST_CONTENTS = gql`
mutation addBlacklistContents($blacklistId: ID, $item: String, $reason: String, $abTestTier: String) {
  addBlacklistContents(blacklistId: $blacklistId, item: $item, reason: $reason, abTestTier: $abTestTier) {
    ok
    info
    errors
  }
}
`;

export {
  GET_BLACKLIST_CONTENT,
  REMOVE_BLACKLIST_CONTENTS,
  ADD_BLACKLIST_CONTENTS
};
