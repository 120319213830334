import React from 'react';
import { Card, Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import './OptionsContainer.css';

const OptionsContainer = ({
  granularity,
  setGranularity,
  typeOfChart,
  setTypeOfChart,
}) => {
  const TYPE_OF_CHART = ['Bar', 'Line'];
  const GRANULARITY = ['Month', 'Week', 'Day', 'Hour', 'Min'];

  return (
    <>
      <Grid container>
        <Card className="optionsContainer">
          <Typography variant="h2" className="optionsContainerTitle">
            Options
          </Typography>
          <Typography variant="h5" className="optionsContainerSubtitle">
            Granularity
          </Typography>

          <div className="optionButtonContainer">
            {GRANULARITY.map((currentGranularity, i) => {
              if (currentGranularity === granularity) {
                return (
                  <Button
                    key={i}
                    className="optionButtonSelected"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setGranularity(currentGranularity);
                    }}
                  >
                    {currentGranularity}
                  </Button>
                );
              }
              return (
                <Button
                  key={i}
                  className="optionButtonNotSelected" 
                  variant="contained"
                  onClick={() => {
                    setGranularity(currentGranularity);
                  }}
                >
                  {currentGranularity}
                </Button>
              );
            })}
          </div>
          <Typography variant="h5" className="optionsContainerSubtitle">
            Type Of Chart
          </Typography>
          {TYPE_OF_CHART.map((currentType, i) => {
            if (currentType === typeOfChart) {
              return (
                <Button
                  key={i}
                  className="optionButtonSelected"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setTypeOfChart(currentType);
                  }}
                >
                  {currentType}
                </Button>
              );
            }
            return (
              <Button
                key={i}
                className="optionButtonNotSelected" 
                variant="contained"
                onClick={() => {
                  setTypeOfChart(currentType);
                }}
              >
                {currentType}
              </Button>
            );
          })}
        </Card>
      </Grid>
    </>
  );
};

export default OptionsContainer;
