

import R_ from '../../routes';
import {setOutageLoading, TOGGLE_OUTAGE, updateOutageState} from "../actions/outage";

const { R } = R_;

const toggleOutage = (store, { type, payload, state }) => {
  if (type === TOGGLE_OUTAGE) {
    store.dispatch(setOutageLoading(true));
    fetch(`${api_base}${R.outage}`, {
      method: 'POST',
      body: JSON.stringify({
        enable: payload.enable,
        vendor_name: "clarity",
        description: payload.description,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Auth-Token': state.current_user.tokenId,
      },
    }).then(r => r.json())
      .then((body) => {
        store.dispatch(updateOutageState({ enabled: payload.enable }));
        store.dispatch(setOutageLoading(false));
      })
      .catch(() => {
        store.dispatch(setOutageLoading(false));
      });
  }
};


export default [
  toggleOutage
];
