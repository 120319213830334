import React, {Component} from 'react';
import {ReactSortable} from "react-sortablejs";
import Element from "./Element";
import {DragIndicator, FilterList, HdrWeak, RemoveCircle} from "@material-ui/icons";
import {GrayCard, CardHeader, RemoveControl} from "./component_styles";


class Parallel extends Component {
  constructor(props) {
    super(props);
  }

  render = () => (
    <div style={{width: 'fit-content', position: 'relative'}}>
      {
        this.props.removeCurrent && (
          <RemoveControl
            onClick={this.props.removeCurrent}
          />
        )
      }
      <GrayCard id={this.props.name}>
        <CardHeader>
          <DragIndicator style={{fontSize: 'medium'}}/>
          Parallel
        </CardHeader>
        <ReactSortable
          list={this.props._children}
          group="shared"
          animation={200}
          delayOnTouchStart={true}
          delay={2}
          setList={(newState) =>
            this.props.onChange({
              name: this.props.name,
              type: this.props.type,
              _children: newState
            })
          }
          style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', minHeight: 150}}
        >
          {
            this.props._children.map(
              (item, i) => (
                <Element
                  key={item.name}
                  removeCurrent={this.props.removeChild(i)}
                  onChange={this.props.updateChild(i)}
                  onRemove={this.props.updateChild(i, 'remove')}
                  enableGraphics={true}
                  {...item}
                />
              )
            )
          }
        </ReactSortable>
        min
      </GrayCard>
    </div>
  )
}

export default Parallel;
