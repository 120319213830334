/**
 * src / redux / actions / index.jsx
 */

import { C } from "../constants";
import R_ from "../../routes";

const { R } = R_;

const createLeadProvider = (attrs) => (dispatch, getState) => {
  const state = getState();
  fetch(`${api_base}${R.lead_providers}`, {
    method: "POST",
    body: JSON.stringify(attrs),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Auth-Token": state.current_user.tokenId,
    },
  }).then(r => r.json())
    .then(results => dispatch({
      type: C.LEAD_PROVIDERS,
      lead_providers: results,
    }));
};


const getLeadProviders = () => (dispatch, getState) => {
  const state = getState();
  fetch(`${api_base}${R.lead_providers}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Auth-Token": state.current_user.tokenId,
    },
  }).then(r => r.json())
    .then(results => {
      if (results.message === "unauthorized") {
        return dispatch({ type: C.UNAUTHORIZED });
      }
      return dispatch({
        type: C.LEAD_PROVIDERS,
        lead_providers: results
      });
    });
};


const getBlacklists = () => (dispatch, getState) => {
  const state = getState();
  fetch(`${api_base}${R.blacklists}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Auth-Token": state.current_user.tokenId,
    },
  }).then(r => r.json())
    .then(results => {
      if (results.message === "unauthorized") {
        return dispatch({ type: C.UNAUTHORIZED });
      }
      return dispatch({
        type: C.BLACKLISTS,
        payload: results
      });
    });
};


const getOutages = () => (dispatch, getState) => {
  const state = getState();
  fetch(`${api_base}${R.outage}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Auth-Token": state.current_user.tokenId,
    },
  }).then(r => r.json())
    .then(results => {
      if (results.message === "unauthorized") {
        return dispatch({ type: C.UNAUTHORIZED });
      }
      return dispatch({
        type: C.OUTAGES,
        payload: results.data
      });
    });
};

export default {
  createLeadProvider,
  getLeadProviders,

  getBlacklists,

  getOutages,
};
