export const TOGGLE_OUTAGE = 'TOGGLE_OUTAGE';
export const OUTAGE_LOADING = 'OUTAGE_LOADING';
export const UPDATE_OUTAGE = 'UPDATE_OUTAGE';


export const toggleOutage = (payload, state) => ({
  type: TOGGLE_OUTAGE,
  payload,
  state
});

export const setOutageLoading = (payload, state) => ({
  type: OUTAGE_LOADING,
  payload,
  state
});

export const updateOutageState = (payload, state) => ({
  type: UPDATE_OUTAGE,
  payload,
  state
});
