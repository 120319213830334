import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import CancelConfirmationModal from "../common/warning-modal";
import {
  AddCampaignModalStyle as Modal,
  CampaignFormErrorText,
  ModalPaper,
} from "../common/form_styles";
import PromoOfferDefForm from "./PromoOfferDefForm";
import {CREATE_PROMOTIONAL_OFFER_DEFINITION} from "../../graphql/queries/promotionalOffers";

function AddPromoOfferDefModal({open, setOpen, refetch}) {
  const [showCancelConfirmationModal, setShowCancelConfirmationModal] = useState(false);
  const [createPromoOfferDef] = useMutation(CREATE_PROMOTIONAL_OFFER_DEFINITION);
  const [error, setError] = useState(false)

  const submitHandler = (data) => {
    createPromoOfferDef(
      {
        variables: {
          payload: JSON.stringify(data)
        }
      }).then(r => {
      if (r.data.createPromotionalOfferDefinition.ok === false)
        setError(r.data.createPromotionalOfferDefinition.info)
      else {
        refetch()
        setError(false)
        setOpen()
      }
    });
  };

  const closeHandler = () => {
    setShowCancelConfirmationModal(true);
  };

  return (
    <>
      <CancelConfirmationModal
        headerText="Are you sure you want to cancel?"
        contentText="All progress will be lost"
        showModal={showCancelConfirmationModal}
        hideModalHandler={() => setShowCancelConfirmationModal(false)}
        cancelHandler={() => setShowCancelConfirmationModal(false)}
        submitHandler={() => {
          setError(false)
          setShowCancelConfirmationModal(false)
          setOpen(false);
        }}
      />
      <Modal open={open} onClose={closeHandler}>
        <ModalPaper>
          <PromoOfferDefForm
            onSubmit={submitHandler}
            onClose={closeHandler}
            createMode={true}
          />
          {
            error &&
            <CampaignFormErrorText>
              {error}
            </CampaignFormErrorText>
          }
        </ModalPaper>
      </Modal>
    </>
  );
}

export default AddPromoOfferDefModal;
