import React, {Component} from 'react';
import {Button, ButtonGroup} from "@material-ui/core";
import JsonLogicExpression from "./JsonLogicExpression";
import {BlockWithLineOnLeft, DashSign, FormControl} from "./component_styles";
import {Add, AddCircle, AddCircleOutline, AddToPhotos, PostAdd, RemoveCircle} from "@material-ui/icons";
import CustomizedMenu from "./Menu";
import {getSampleCondition, getSampleGroup} from "./utils";

class ConstantBlock extends Component {
  constructor(props) {
    super(props);

    this.possibleValues = [true, false];
    this.state = {
      value: this.props.expression || true
    };
  }

  render = () => (
    <ButtonGroup variant="contained" color="primary" style={{paddingTop: 5}}>
      <DashSign/>
      {
        this.possibleValues.map(
          (val, i) => {
            if (val === this.state.value)
              return <Button key={i} onClick={this.switchValue(val)}>{val.toString().toUpperCase()}</Button>;
            return <Button variant="outlined" key={i} onClick={this.switchValue(val)}>{val.toString().toUpperCase()}</Button>;
          }
        )
      }
      <div style={{paddingTop: 5, paddingLeft: 10}}>
        <CustomizedMenu
          options={[
            {title: 'Convert to Condition', action: this.convertToCondition},
            {title: 'Convert to Group', action: this.convertToGroup},
          ]}
          icon="add-circle"
        />
        {
          this.props.removeCurrent && (
            <RemoveCircle
              color="primary"
              onClick={this.props.removeCurrent}
            />
          )
        }
      </div>
    </ButtonGroup>
  )

  switchValue = (val) => {
    return () => {
      this.setState(
        {value: val},
        () => {this.callOnChange();}
        );
    }
  }

  callOnChange = () => {
    if (this.props.onExpressionChange)
      return this.props.onExpressionChange(this.state.value);
  }

  convertToGroup = () => {
    return this.props.onExpressionChange(getSampleGroup(this.props.fieldTypes));
  }

  convertToCondition = () => {
    return this.props.onExpressionChange(getSampleCondition(this.props.fieldTypes));
  }
}

export default ConstantBlock;
