import React from "react";

import {
} from "@material-ui/core";

import AuthLayout from "./Auth";

const Home = ({ name }) => (
  <AuthLayout>
    <h1>this is Home of {name}.</h1>
  </AuthLayout>
);

export default Home;
