import React, {useState, useRef} from "react";
import {useQuery} from "@apollo/client";
import AddBox from "@material-ui/icons/AddBox";
import MaterialTable from "material-table";
import {customFilterWrapper, tableIcons} from "../Utilities"
import {
  GET_PROMOTIONAL_OFFER_DEFINITIONS,
} from "../../graphql/queries/promotionalOffers";
import AddPromoOfferDefModal from "./AddPromoOfferDefModal";
import UpdatePromoOfferDefSection from "./UpdatePromoOfferDefSection";


function PromotionalOfferDefinitions() {
  const [showAddPromoOfferDefModal, setShowAddPromoOfferDefModal] = useState(false);
  const tableRef = useRef()

  const {
    loading: promoOfferDefsLoading,
    error: promoOfferDefsError,
    data: promoOfferDefsData,
    refetch: promoOfferDefsRefetch,
  } = useQuery(GET_PROMOTIONAL_OFFER_DEFINITIONS);

  let dataForTable = [];
  let allPromoOfferDefs = []

  if (promoOfferDefsData) {
    promoOfferDefsData.promotionalOfferDefinitions.edges.forEach((item, i) => {
      allPromoOfferDefs[item.node.id] = item.node;
    });
  }


  promoOfferDefsData &&
  Object.keys(promoOfferDefsData.promotionalOfferDefinitions.edges).map((key, i) => {
    dataForTable.push({
      loanProgramName: promoOfferDefsData.promotionalOfferDefinitions.edges[key].node.loanProgramName,
      id: promoOfferDefsData.promotionalOfferDefinitions.edges[key].node.id,
      term: promoOfferDefsData.promotionalOfferDefinitions.edges[key].node.term,
      termConstant: promoOfferDefsData.promotionalOfferDefinitions.edges[key].node.termConstant,
      interestRate: promoOfferDefsData.promotionalOfferDefinitions.edges[key].node.interestRate + "%",
      apr: promoOfferDefsData.promotionalOfferDefinitions.edges[key].node.apr + "%",
      annualFee: promoOfferDefsData.promotionalOfferDefinitions.edges[key].node.annualFee + "%",
      originationFee: promoOfferDefsData.promotionalOfferDefinitions.edges[key].node.originationFee + "%",
      // offerDescription: promoOfferDefsData.promotionalOfferDefinitions.edges[key].node.offerDescription,
      offerDescription: "TEXT",
      customizedMessage: promoOfferDefsData.promotionalOfferDefinitions.edges[key].node.customizedMessage,
      cannedText: promoOfferDefsData.promotionalOfferDefinitions.edges[key].node.cannedText,
    });
  });

  return (
    <>
      <AddPromoOfferDefModal
        open={showAddPromoOfferDefModal}
        setOpen={setShowAddPromoOfferDefModal}
        refetch={promoOfferDefsRefetch}
      />
      <div className="alignCenter">
        <h2>Please read through this confluence page (
          <a href="https://creditninja.atlassian.net/wiki/spaces/EN/pages/3293052957/LeadsUI+Promotional+Offer+Definitions+Rules+To+Promotional+Offers+pages"
             rel="noopener noreferrer"
             target="_blank">
            LeadsUI Promotional offer definitions page
          </a>
          )  before you take any actions.
        </h2>
      </div>
      <MaterialTable
        tableRef={tableRef}
        title="Promotional Offer Definitions"
        icons={tableIcons}
        columns={[
          { title: "Loan Program Name", field: "loanProgramName", customFilterAndSearch: customFilterWrapper("loanProgramName") },
          { title: "ID", field: "id", customFilterAndSearch: customFilterWrapper("id") },
          { title: "Term", field: "term", customFilterAndSearch: customFilterWrapper("term") },
          { title: "termConstant", field: "termConstant", customFilterAndSearch: customFilterWrapper("termConstant") },
          { title: "interestRate", field: "interestRate", customFilterAndSearch: customFilterWrapper("interestRate") },
          { title: "apr", field: "apr", customFilterAndSearch: customFilterWrapper("apr") },
          { title: "Annual Fee", field: "annualFee", customFilterAndSearch: customFilterWrapper("annualFee") },
          { title: "Origination Fee", field: "originationFee", customFilterAndSearch: customFilterWrapper("originationFee") },
          { title: "Offer Description", field: "offerDescription", customFilterAndSearch: customFilterWrapper("offerDescription") },
          { title: "Customized Message", field: "customizedMessage", customFilterAndSearch: customFilterWrapper("customizedMessage") },
          { title: "Canned Text", field: "cannedText",  customFilterAndSearch: customFilterWrapper("cannedText")}
        ]}
        data={dataForTable}
        options={{
          filtering: true,
          paging: false,
        }}
        detailPanel={ rowData => {
          return(
            <UpdatePromoOfferDefSection
              refetch={promoOfferDefsRefetch}
              promoOfferDefData={allPromoOfferDefs[rowData.id]}
              closeUpdateSection={() =>
                tableRef.current.onToggleDetailPanel(
                  [rowData.tableData.id],
                  tableRef.current.props.detailPanel
                )
              }
            />
          )
        }}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        actions={[
          {
            tooltip: 'Add a new promotional offer Definition',
            icon: AddBox,
            isFreeAction: true,
            onClick: () => (
              setShowAddPromoOfferDefModal(true)
            )
          }
        ]}
      />
    </>
  );
}

export default PromotionalOfferDefinitions;
