import React, {useEffect, useState} from 'react';
import {FormControl} from "../JsonLogicUI/component_styles";
import Select from "react-select";
import {TextField} from "@material-ui/core";


const TextInput = React.memo(({onChange, value: initialValue, label=""}) => {
  const [value, setValue] = useState(initialValue);
  useEffect(() => {setValue(initialValue);}, [initialValue])

  return <>
    <FormControl>
      {label}
      <TextField
        variant="outlined"
        onChange={(event) => {
          setValue(event.target.value);
        }}
        onBlur={() => onChange(value)}
        value={value}
      />
    </FormControl>
  </>
})

export default TextInput;
