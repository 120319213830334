import React from 'react';
import {Route, Switch} from 'react-router-dom';

import Dashboard2Layout from '../pages/Dashboard2';
import AuthLayout from '../pages/Auth';
import Home from '../pages/Home';

import { Auth2, Logout, } from '../components/Auth';
import Campaigns from '../components/Campaigns';
import LeadProviders from '../components/LeadProviders';
import Bidding from '../components/Bidding';
import Blacklists from "../components/Blacklists";
import Parameters from '../components/Parameters'
import {ApolloProvider} from "@apollo/client";
import client from "../graphql/client";
import ProductionTesting from "../components/ProductionTesting";
import ProductionTestForm from "../components/ProductionTesting/ProductionTestForm";
import UnderwritingDashboard from '../components/UnderwritingDashboard';
import PromotionalOfferDefinitions from "../components/PromotionalOfferDefinitons";
import RulesToPromotionalOffers from "../components/RulesToPromoionalOffers";

const isAuthenticated = () => {
  const currentUser = JSON.parse(localStorage.getItem('current_user'));
  if (currentUser) {
    return true;
  }
  return false;
};


const Routes = () => (
  <Switch>
    <Route
      key="underwriting_dashboard"
      path="/underwriting_dashboard"
      exact
      render={() => {
        if (isAuthenticated()) {
          return <Dashboard2Layout><UnderwritingDashboard /></Dashboard2Layout>;
        }
        window.location = '/users/login';
      }}
    />
    <Route
      key="campaigns"
      path="/campaigns"
      exact
      render={() => {
        if (isAuthenticated()) {
          return <Dashboard2Layout><Campaigns /></Dashboard2Layout>;
        }
        window.location = '/users/login';
      }}
    />
    <Route
      key="lead_providers"
      path="/lead_providers/index"
      exact
      render={() => {
        if (isAuthenticated()) {
          return (<Dashboard2Layout><LeadProviders /></Dashboard2Layout>);
        }
        window.location = '/users/login';
      }}
    />
    <Route
      key="bidding"
      path="/bidding"
      exact
      render={() => {
        if (isAuthenticated()) {
          return (<Dashboard2Layout><Bidding /></Dashboard2Layout>);
        }
        window.location = '/users/login';
      }}
    />
    <Route
      key="blacklists"
      path="/blacklists"
      exact
      render={() => {
        if (isAuthenticated()) {
          return (<Dashboard2Layout><Blacklists /></Dashboard2Layout>);
        }
        window.location = '/users/login';
      }}
    />
    <Route
      key="parameters"
      path="/parameters"
      exact
      render={() => {
        if (isAuthenticated()) {
          return (<Dashboard2Layout><Parameters /></Dashboard2Layout>);
        }
        window.location = '/users/login';
      }}
    />
    <Route
      key="promotional_offer_definitions"
      path="/promotional_offer_definitions"
      exact
      render={() => {
        if (isAuthenticated()) {
          return (<Dashboard2Layout><PromotionalOfferDefinitions /></Dashboard2Layout>);
        }
        window.location = '/users/login';
      }}
    />
    <Route
      key="rules_to_promotional_offers"
      path="/rules_to_promotional_offers"
      exact
      render={() => {
        if (isAuthenticated()) {
          return (<Dashboard2Layout><RulesToPromotionalOffers /></Dashboard2Layout>);
        }
        window.location = '/users/login';
      }}
    />
    <Route
      key="production_testing"
      path="/production_testing"
      exact
      render={() => {
        if (isAuthenticated()) {
          return (<Dashboard2Layout><ProductionTesting /></Dashboard2Layout>);
        }
        window.location = '/users/login';
      }}
    />
    <Route
      key="new_production_testing"
      path="/production_testing/new/:image"
      exact
      render={({match:{params:{image}}}) => {
        if (isAuthenticated()) {
          return (<Dashboard2Layout><ProductionTestForm image={image} /></Dashboard2Layout>);
        }
        window.location = '/users/login';
      }}
    />
    childRoutesUnauthed(AuthLayout, unauthedRoutes)
    <Route
      key="login"
      path="/users/login"
      exact
      render={() => (<AuthLayout><Auth2 /></AuthLayout>)}
    />
    <Route
      key="logout"
      path="/users/logout"
      exact
      render={() => <Logout />}
    />
    <Route
      key="home"
      path="/"
      exact
      render={() => {
        if (isAuthenticated()) {
          return (<Dashboard2Layout><Home name="authed" /></Dashboard2Layout>);
        }
        window.location = '/users/login';
      }}
    />
  </Switch>
);

export const dashboard = [];

export default Routes;
