import { min } from 'date-fns';
import { OPTIONS_FOR_CHARTS } from './ChartOptions';

/**
 * Splits given date range into array of dates with given granularity
 * @param {string} granularity set in OptionsContainer
 * @param {Date} startDateTime set in FilterContainer
 * @param {Date} endDateTime set in FilterContainer
 * @return {Array} Array of dates
 */
export function getSplittedDates(granularity, startDateTime, endDateTime) {
  const tempDate = new Date(startDateTime);
  const dates = [startDateTime];

  while (tempDate < endDateTime) {
    switch (granularity) {
      case 'Month':
        const nextMonthDate = min([
          tempDate.setMonth(tempDate.getMonth() + 1),
          endDateTime,
        ]);
        dates.push(nextMonthDate);
        break;
      case 'Week':
        const nextWeekDate = min([
          tempDate.setDate(tempDate.getDate() + 7),
          endDateTime,
        ]);
        dates.push(nextWeekDate);
        break;
      case 'Day':
        const nextDayDate = min([
          tempDate.setDate(tempDate.getDate() + 1),
          endDateTime,
        ]);
        dates.push(nextDayDate);
        break;
      case 'Hour':
        const nextHourDate = min([
          tempDate.setHours(tempDate.getHours() + 1),
          endDateTime,
        ]);
        dates.push(nextHourDate);
        break;
      case 'Min':
        const nextMinDate = min([
          tempDate.setMinutes(tempDate.getMinutes() + 1),
          endDateTime,
        ]);
        dates.push(nextMinDate);
        break;
    }
  }
  return dates;
}

/**
 * Processes dates and given data and return dataset for CustomChart component
 * @param {Array} dates Splitted dates calculated in {@link getSplittedDates}
 * @param {Array} allAmount Array of all data given from FiltersContainer
 * @return {*[]} Array of dates and count of data before each date
 */
export function calculateCounts(dates, allAmount) {
  if (allAmount.length === 0) return [];
  const dataset = [];
  let index = 0,
    count = 0;
  dates.map((date) => {
    if (index == allAmount.length) {
      return;
    }

    let tempDate = new Date(allAmount[index].created_at);
    while (tempDate <= date && index < allAmount.length) {
      count++;
      index++;

      if (index == allAmount.length) {
        break;
      }
      tempDate = new Date(allAmount[index].created_at);
    }
    dataset.push({ x: date.getTime(), y: count });

    count = 0;
  });
  return dataset;
}

let timer;

/**
 * Needed for changing view of chart to reflect zooming. Calls {@link startFetch} function which allows
 * to display limited count of points to avoid lags
 * @param {Chart} chart - transmitted automatically
 */
export function startFetch({ chart }) {
  const { min, max } = chart.scales.x;
  clearTimeout(timer);
  timer = setTimeout(() => {
    chart.data.datasets[0].data = fetchData(
      min,
      max,
      chart.myValue.dataset,
      chart.myValue.granularity
    );
    chart.stop();
    chart.update('none');
  }, 500);
}

/**
 * This is the main function to avoid lags on large datasets. It allows not to display all points,
 * but only a limited number. All missing points are added to the next displayed one to not miss some spikes of data
 * @param {Number} startTimeInterval - start of time interval in milliseconds
 * @param {Number} endTimeInterval - end of time interval in milliseconds
 * @param {Array} dataset - dataset of ALL DATA given at mounting of chart
 * @param {string} granularity - depends on value in OptionsContainer
 * @return {*[]} - dataset which displays on charts
 */
export function fetchData(startTimeInterval, endTimeInterval, dataset, granularity) {
  let step;
  if (dataset.length <= 200) step = 1;
  else
    step = Math.max(
      1,
      Math.round((endTimeInterval - startTimeInterval) / OPTIONS_FOR_CHARTS[granularity]['step'])
    );
  let outputData = [];
  let i = 0,
    sum = 0;
  while (i < dataset.length && dataset[i].x < startTimeInterval) i++;
  if (step === 1) {
    while (i < dataset.length && dataset[i].x <= endTimeInterval) {
      outputData.push(dataset[i]);
      i++;
    }
  } else {
    outputData.push(dataset[i]);
    i++;
    while (i < dataset.length && dataset[i].x <= endTimeInterval) {
      for (let j = 0; j < step && i + j < dataset.length; j++)
        sum += dataset[i + j].y;
      i += step;
      outputData.push({
        x: dataset[Math.min(i, dataset.length - 1)].x,
        y: sum,
      });
      sum = 0;
    }
  }
  return outputData;
}
